import React from "react"

/**
 *
 * @param {{
 * type: string,
 * action: function,
 * name: string,
 * children: string,
 * }} param0
 *
 * @author Lauwers Yann
 */

export default function FullyRoundedImgBtn({ type, action, name, children }) {
  return (
    <button
      type={type}
      className="focus:outline-none bg-ternary cursor-btn rounded-roundedBtn border-btn border-ternary"
      onClick={action}
      name={name}
    >
      {children}
    </button>
  )
}
