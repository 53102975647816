import { useState, useEffect } from "react"
import { ContainerMain, PageContainer } from "../../components/Container"
import { Header1WithBackButton, Header2 } from "../../components/DefineTagClass/Header"
import {
  AboutUs,
  AvailableDeliveries,
  AboutUsAndOrderLinkAlignment,
  PharmacyCardWithDetails,
  PageAlignment,
  CGV,
} from "../../components/PharmacyDetails/PharmacyDetailsComponents"
import { ButtonSm } from "../../components/DefineTagClass/Buttons"
import { PharmacyAPI } from "../../Backend/pharmacy"

export default function PharmacyDetails({ prevStep, nextStep, pharmacyChoice }) {
  const [temporaryPharmacyDetails, setTemporaryPharmacyDetails] = useState({})

  useEffect(() => {
    loadPharmacyDetails()
  }, [])

  const loadPharmacyDetails = async () => {
    const pharma = await PharmacyAPI.getPharmacy(pharmacyChoice.id)
    setTemporaryPharmacyDetails(pharma)
  }

  return (
    <>
      <div>
        <Header1WithBackButton prevStep={prevStep}>
          Pharmacie {pharmacyChoice.name}
        </Header1WithBackButton>
        <Header2>Bienvenue dans votre pharmacie de quartier!</Header2>
      </div>
      <PageContainer>
        <ContainerMain>
          <PageAlignment>
            <AboutUsAndOrderLinkAlignment>
              <AboutUs>
                <span className="text-ternary font-extra-bold">
                  {temporaryPharmacyDetails.name}
                </span>{" "}
                {temporaryPharmacyDetails.description}
              </AboutUs>
              <button
                type="button"
                name="callMe"
                onClick={nextStep}
                className="w-full max-w-mobile-buttonLg tablet:max-w-tablet-buttonLg laptop:max-w-laptop-buttonLg h-roundedBtnLg focus:outline-none rounded-roundedBtn cursor-btn filter drop-shadow-roundedBtn shadow-roundedBtn text-secondary bg-cinquo
            text-mobile-button-fontsizeLg leading-mobile-buttonLg-leading
            font-bold font-nunito flex justify-center items-center tablet:col-start-1 tablet:col-end-2 laptop:col-start-2 laptop:col-end-3"
              >
                <ButtonSm>Commander ici</ButtonSm>
              </button>
            </AboutUsAndOrderLinkAlignment>
            <PharmacyCardWithDetails pharmacyDetails={temporaryPharmacyDetails} />
            <AvailableDeliveries deliveryMethods={temporaryPharmacyDetails?.deliveryMethods} />
            {temporaryPharmacyDetails?.cgv && <CGV cgv={temporaryPharmacyDetails?.cgv} />}
          </PageAlignment>
        </ContainerMain>
      </PageContainer>
    </>
  )
}
