export function Container({ children }) {
  return <div className="w-mobileW tablet:w-tabletW laptop:w-laptopW mx-auto">{children}</div>
}

export function ContainerWithButtonFixedBottom({ children }) {
  return (
    <div className="w-mobileW tablet:w-tabletW laptop:w-laptopW mx-auto mb-10 pb-cartBtnHeight">
      {children}
    </div>
  )
}

export function ContainerMain({ children }) {
  return (
    <div className="pt-mobile-header-to-main pb-mobile-main-to-footer mx-mobile-container-margin tablet:mx-tablet-container-margin tablet:pt-tablet-header-to-main tablet:pb-tablet-main-to-footer laptop:pt-laptop-header-to-main laptop:w-max laptop:mx-auto laptop:pb-laptop-main-to-footer">
      {children}
    </div>
  )
}

export function ContainerLogin({ children }) {
  return <div className="w-mobile-loginW tablet:w-tablet-laptop-loginW mx-auto">{children}</div>
}

export function NavContainer({ children }) {
  return (
    <div className="w-mobileNavW tablet:w-tabletNavW laptop:w-laptopNavW mx-auto">{children}</div>
  )
}

export function PageContainer({ color = "secondary", children }) {
  return (
    <div className={`w-full flex justify-center flex-auto flex-shrink-0 bg-${color}`}>
      <div>{children}</div>
    </div>
  )
}
