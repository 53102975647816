import React from "react"
import { NavContainer } from "../Container"

import H2 from "../DefineTagClass/H2"
import Cross from "../Images/Cross"

/**
 *
 * @param {{
 * hideCart: function,
 * }} param0
 *
 * @author Lauwers Yann
 */

export default function OrderSummaryNavbar({ hideCart }) {
  return (
    <NavContainer>
      <div className="flex justify-between items-center bg-secondary h-navbarHeight w-full">
        <H2 noMargin noPadding>
          Votre panier :
        </H2>
        <button onClick={hideCart} className="focus:outline-none">
          <Cross />
        </button>
      </div>
    </NavContainer>
  )
}
