import React, { useState, useEffect } from "react"

// back-end
import { PharmacyAPI } from "../../Backend/pharmacy"

// services
import {
  formatAddressStreetLine,
  formatAddressCityLine,
  formatAddress,
  computeDistance,
} from "../../Services/AddressService"

// Components
import PharmacyCard1 from "../../components/Card/PharmacyCard1"
import PrimaryText from "../../components/DefineTagClass/PrimaryText"
import FullyRoundedBackBtn from "../../components/Btns/FullyRoundedBackBtn"

// assets
import LoadingSpinner from "../../components/Loading"
import Iframe from "../../components/iframe"
import { colorMode } from "../../Config/colorMode"
import { Container, PageContainer } from "../../components/Container"
import H2 from "../../components/DefineTagClass/H2"
import H1 from "../../components/DefineTagClass/H1"
import Label from "../../components/DefineTagClass/Label"




/**
 *
 * @param {{
 * prevStep: function,
 * nextStep: function,
 * pharmacyChoice: string,
 * setPharmacyChoice: function,
 * deliveryAddress: {},
 * setNav: function,
 * }} param0
 *
 * @author Lauwers Yann
 */

export default function PharmacySelection({
  prevStep,
  nextStep,
  pharmacyChoice,
  setPharmacyChoice,
  deliveryAddress,
  setNav,
}) {
  const [state, setState] = useState(1)
  const [pharmaciesReachable, setPharmaciesReachable] = useState([])
  const [hide, setHide] = useState(false)
  const status = {
    loading: 1,
    reachablePharmacies: 2,
    pharmacyNotReachable: 3,
    noReachablePharmacies: 4,
  }

  useEffect(() => {
    setNav(colorMode.blueBg)
    loadReachablePharmacies()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function IFrameAirtable ( )  {
    useEffect ( ( )  =>  {
      const  timeout  =  setTimeout ( ( )  =>  {
        window.location.href = ("https://airtable.com/shrBaiM6U9Q1ofLX1") ;
      } ,  3000 );

      return  ( )  =>  clearTimeout ( timeout ) ;
    } ,  [ ] );

    return  <p> Vous serez redirigé dans quelques secondes vers le formulaire.</p> ;
  }

  function arrayRemove(arr, value) {
    return arr.filter(function (pharm) {
      return pharm.contract !== value || pharm.id === pharmacyChoice.id
    })
  }

/**
 * Compare the distance the delivery adress and 2 pharmacies
 * @param {*} pharmacy1 The first pharmacy
 * @param {*} pharmacy2 The second pharmacy
 * @returns -1 if the first pharmacy is closer to the delivery adress
 *           1 if the second pharmacy is closer to the delivery adress
 */
  const compareByDistance = (pharmacy1, pharmacy2) => {
    const DeliveryAddressToAddress1 = computeDistance(deliveryAddress, pharmacy1?.address)
    const DeliveryAddressToAddress2 = computeDistance(deliveryAddress, pharmacy2?.address)

    if (DeliveryAddressToAddress1 < DeliveryAddressToAddress2) {
      return -1
    }
    if (DeliveryAddressToAddress1 > DeliveryAddressToAddress2) {
      return 1
    }
    return 0
  }
/**
 * Load the pharmacies reachable by the user, sort them by distance and remove the ones that have a "Basic" subscription
 */
  const loadReachablePharmacies = async () => {
    setState(status.loading)
    let address = formatAddress(deliveryAddress)
    try {
      const pharmacies = await PharmacyAPI.getReachable(address)
      const pharmaciesSortedByDistance = pharmacies?.filter((p) => computeDistance(deliveryAddress, p.address) < 5000).sort(compareByDistance)
      const noBasicPharmacies = arrayRemove(pharmaciesSortedByDistance, "basic")
      setPharmaciesReachable(noBasicPharmacies)
      handleStatus(noBasicPharmacies)
    } catch (error) {
      console.error(error)
    }
  }

  const handleStatus = (pharmacies = pharmaciesReachable) => {
    if (hide) {
      setHide(false)
    }
    if (pharmacyChoice.name) {
      let result = pharmacies.filter(pharmacy => pharmacy.id === pharmacyChoice.id)
      let indexResult = pharmacies.indexOf(result[0])
      if (!pharmacies.length) {
        // If the pharmacy chosen is member but doesn't deliver your address and no other pharmacy doesn't either
        // try it with this address : rue des hayettes
        setState(status.noReachablePharmacies)
      } else if (pharmacies.length >= 1) {
        // If there is only one pharmacy who delivers your address
        // try it with this address : Abbaye de la Cambre, 1050 Ixelles, Belgique
        if (result.length) {
          // The pharmacy(ies) who delivers your address is the one you picked on step 1
          pharmacyChoice.address = result[0].address
          pharmacies.splice(indexResult, 1)
          setState(status.reachablePharmacies)
        } else {
          // The pharmacy(ies) who delivers your address is not the one you picked on step 1
          setHide(true)
          setState(status.myPharmacyNotReachable)
        }
      }
    } else {
      if (pharmacies.length) {
        setState(status.reachablePharmacies)
      } else {
        setState(status.noReachablePharmacies)
      }
    }
  }

  // If he choose an other pharmacy than he picked on the first step, this function will be executed
  const actualizePharmacyChoice = pharmacy => {
    const format = {
      name: pharmacy.name,
      id: pharmacy.id,
      address: pharmacy.address,
      member: pharmacy.member,
    }
    setPharmacyChoice(format)
    nextStep()
  }
/**
 * Sort the pharmacies from the closer to the delivery adress to the further
 * @param {*} pharmacies some pharmacies
 * @param {*} deliveryAddress the adress of the user
 * @returns the same pharmacies but sorted
 */

  const sortPharmaciesByDistance = (pharmacies, deliveryAddress) => {
    return pharmacies.sort(function (a, b) {
      const distA = computeDistance(deliveryAddress, a.address)
      const distB = computeDistance(deliveryAddress, b.address)

      if (b.status === 'Active' && a.status !== 'Active') {
        return 1;
      }

      if (distA < distB) {
        return -1;
      }
      if (distA > distB) {
        return 1
      }
      return 0
    })
  }

 /**
  * Shows the reachable pharmacies
  * @returns the pharmacies reachable by the user
  */
  const ReachablePharmacies = () => {
    return (
      <div>
        <PharmacyChoiceDisplay />

        <ul>
          {sortPharmaciesByDistance(pharmaciesReachable, deliveryAddress).map((pharmacy, i) => {
            return (
              <li
                key={i}
                className={`${
                  pharmaciesReachable.length > 1 ? " mb-cartItemsMargin last:mb-0" : null
                }`}
              >
               <button
                 className={`${
                   pharmacy.status === 'Active' ? " focus:outline-none w-full group" : 'focus:outline-none w-full group cursor-not-allowed opacity-30'
                 }`}
                  onClick={() => pharmacy.status === "Active" && actualizePharmacyChoice(pharmacy)}
                  disabled={pharmacy.status !== "Active"}
                >
                  <PharmacyCard1
                    pharmacyName={pharmacy.name}
                    distance={computeDistance(deliveryAddress, pharmacy.address)}
                    streetLine={formatAddressStreetLine(pharmacy.address)}
                    cityLine={formatAddressCityLine(pharmacy.address)}
                  />
                </button>

              </li>
            )
          })}
        </ul>
      </div>
    )
  }
/**
 * Shows a message to notify the user that the pharmacy he choose is to far from him
 * @returns the message
 */
  const MyPharmacyNotReachable = () => {
    return (
      <div>
        <PrimaryText>
          Malheureusement, la pharmacie {pharmacyChoice.name} ne peut pas vous livrer car vous êtes
          trop éloigné.
        </PrimaryText>
        <br></br>
        <PrimaryText>Cependant, d'autres pharmacies se feront une joie de vous servir.</PrimaryText>
        <br></br>
        <ReachablePharmacies />
      </div>
    )
  }
/**
 * Shows a message to notify the user that there is no pharmacy near his house
 * @returns the message
 */
  const NoReachablePharmacies = () => {

    return (
      <div>
        <PrimaryText>
          Malheureusement, il n'y a pas encore de pharmacie faisant partie du réseau Smile qui
          dessert votre adresse.
        </PrimaryText>
        <br></br>
        <PrimaryText>
          Vous pouvez remplir le formulaire si vous désirez être tenu au courant de l'arrivée d'une
          pharmacie dans votre quartier!
        </PrimaryText>
        <br></br>
        <IFrameAirtable />
      </div>
    )
  }
/**
 * Shows the pharmacy chosent by the user on the first step
 * @returns  the card oh this pharmacy
 */
  const PharmacyChoiceDisplay = () => {
    return pharmacyChoice.id && !hide ? (
      <div className={`${pharmaciesReachable.length === 0 ? "" : " mb-cartItemsMargin"}`}>
        <button className="focus:outline-none w-full group" onClick={nextStep}>
          <PharmacyCard1
            nextStep={nextStep}
            pharmacyName={pharmacyChoice}
            distance={computeDistance(deliveryAddress, pharmacyChoice.address)}
            streetLine={
              pharmacyChoice.address ? formatAddressStreetLine(pharmacyChoice.address) : null
            }
            cityLine={pharmacyChoice.address ? formatAddressCityLine(pharmacyChoice.address) : null}
          />
        </button>
      </div>
    ) : (
      <div></div>
    )
  }

  const ShowReachablePharmacies = () => {
    switch (state) {
      case status.loading:
        return <LoadingSpinner isBlue />
      case status.reachablePharmacies:
        return <ReachablePharmacies />
      case status.myPharmacyNotReachable:
        return <MyPharmacyNotReachable />
      case status.noReachablePharmacies:
        return <NoReachablePharmacies />
      default:
        return <h1>defaut</h1>
    }
  }
/**
 * Redirect to the first step WhereToDeliver
 */
  const backToStepOne = () => {
    setNav(colorMode.whiteBg)
    prevStep()
  }

  return (
    <>
      <H1>Nouvelle commande</H1>
      <PageContainer>
        <div className="pb-mobile-main-to-footer">
          <Container>
            <H2 green>Choisissez votre pharmacie :</H2>



            <div className="mb-inputToSubmit w-full flex flex-col mx-auto max-w-mobileBtnMaxWidth">
              <ShowReachablePharmacies />
            </div>
            <div className="flex items-center justify-center">
              <FullyRoundedBackBtn action={backToStepOne} name="retour" type="button" size={48} />
            </div>
          </Container>
        </div>
      </PageContainer>
    </>
  )
}
