import { PharmacyDTO } from "./PharmacyDTO"
/**
 * Class used to Transfer Mandataire datas safely
 */
export class MandataireDTO {
  email: String
  idNumber: String
  lastname: String
  name: String
  phone: String
  pharmacy: PharmacyDTO
  status: String
  address: AddressDTO
  streetLine : String
  cityLine : String
  distance : String
  image : String
}
