import React, { useState } from "react"

// Components
import H3 from "../DefineTagClass/H3"
import PrimaryText from "../DefineTagClass/PrimaryText"
import SecondaryText from "../DefineTagClass/SecondaryText"
import Arrow from "../Images/Arrow"

/**
 *
 * @param {{
 * order: object,
 * }} param0
 *
 * @author Lauwers Yann
 */

export function DeliveryDetails({ order, isOpenClass = false, divRef, scrollToDiv }) {
  const [isSectionOpen, setIsSectionOpen] = useState(false)

  let sectionClass = "border-b-formSelect border-primary "
  sectionClass += isOpenClass ? "bg quatro" : "bg-secondary"

  const handleClick = () => {
    setIsSectionOpen(!isSectionOpen)
    const state = isSectionOpen
    if (state === false) {
      scrollToDiv()
    }
  }

  const formatDeliverySchedul = () => {
    let date = new Date(order.deliverySchedul)
    let formatedDate = new Intl.DateTimeFormat("fr-BE", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    }).format(date)
    return formatedDate
  }

  return (
    <div className={sectionClass}>
      <div ref={divRef}>
        <Section
          action={handleClick}
          text="Informations de livraison"
          isSelectedSectionOpen={isSectionOpen}
        />
      </div>
      <div
        className={
          isSectionOpen ? "bg-quatro px-blockInsidePadding pb-blockOpenPaddingB" : "hidden"
        }
      >
        <div className="mb-detailToDetail">
          <PrimaryText>Date de livraison</PrimaryText>
          <SecondaryText isResumeContent>
            {order.deliverySchedul.error === "#ERROR"
              ? "En cours de traitement"
              : formatDeliverySchedul()}
          </SecondaryText>
        </div>
        <div className="mb-detailToDetail">
          <PrimaryText>Destinataire</PrimaryText>
          <SecondaryText
            isResumeContent
          >{`${order.user.lastname} ${order.user.name}`}</SecondaryText>
          <SecondaryText isResumeContent>{`${order.address.street}${
            order.address.streetNumber ? ` ${order.address.streetNumber}` : ""
          },`}</SecondaryText>
          <SecondaryText isResumeContent>{`${
            order.address.postalCode ? order.address.postalCode : ""
          } ${order.address.city}`}</SecondaryText>
        </div>
        <div>
          <PrimaryText>Pharmacie</PrimaryText>
          <SecondaryText isResumeContent>{order.pharmacy.name}</SecondaryText>
          <SecondaryText isResumeContent>{`${order.address.street}${
            order.pharmacy.address.streetNumber ? ` ${order.pharmacy.address.streetNumber}` : ""
          },`}</SecondaryText>
          <SecondaryText isResumeContent>{`${
            order.pharmacy.address.postalCode ? order.pharmacy.address.postalCode : ""
          } ${order.pharmacy.address.city}`}</SecondaryText>
        </div>
      </div>
    </div>
  )
}

export const OrderDetails = ({ order, isOpenClass = false, divRef, scrollToDiv }) => {
  const [isSectionOpen, setIsSectionOpen] = useState(false)

  let sectionClass = "border-b-formSelect border-primary "
  sectionClass += isOpenClass ? "bg quatro" : "bg-secondary"

  const handleClick = () => {
    setIsSectionOpen(!isSectionOpen)
    const state = isSectionOpen
    if (state === false) {
      scrollToDiv()
    }
  }

  const listItems = () => {
    if (order.payment.items) {
      return order.payment.items.map((item, i) => {
        return (
          <div key={i} className="flex items-center justify-between mb-detailToDetail last:mb-0">
            <PrimaryText>{item.label}</PrimaryText>
            <PrimaryText>{`${item.amount.toFixed(2)} €`}</PrimaryText>
          </div>
        )
      })
    } else {
      return <SecondaryText>Nous avons aucune information concernant vos produits</SecondaryText>
    }
  }

  const orderTotalAmout = () => {
    let total = 0
    if (order.payment.items) {
      order.payment.items.map(item => {
        return (total += item.amount)
      })
    }
    return `${total.toFixed(2)} €`
  }

  return (
    <div className={sectionClass}>
      <div ref={divRef}>
        <Section
          action={handleClick}
          text="Détails de la commande"
          isSelectedSectionOpen={isSectionOpen}
        />
      </div>
      <div
        className={
          isSectionOpen ? "bg-quatro px-blockInsidePadding pb-blockOpenPaddingB" : "hidden"
        }
      >
        <div className="pb-blockInsidePadding">{listItems()}</div>
        <div className="flex items-center justify-between pt-blockInsidePadding border-t-resumeCartTotal border-primary border-opacity-50">
          <PrimaryText>Total</PrimaryText>
          <PrimaryText>{orderTotalAmout()}</PrimaryText>
        </div>
      </div>
    </div>
  )
}

export const Posologie = ({ order, isOpenClass = false, divRef, scrollToDiv }) => {
  const [isSectionOpen, setIsSectionOpen] = useState(false)

  let sectionClass = ""
  sectionClass += isOpenClass ? " bg quatro" : " bg-secondary"

  const handleClick = () => {
    setIsSectionOpen(!isSectionOpen)
    const state = isSectionOpen
    if (state === false) {
      scrollToDiv()
    }
  }

  return (
    <div className={sectionClass}>
      <Section
        action={handleClick}
        text="Posologie du pharmacien"
        isSelectedSectionOpen={isSectionOpen}
      />
      <div
        className={
          isSectionOpen ? "bg-quatro px-blockInsidePadding pb-blockOpenPaddingB" : "hidden"
        }
      >
        <div className="pb-detailToDetail">
          {!order.posology ? (
            <SecondaryText>Le médecin n'a indiqué aucune posologie.</SecondaryText>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: order.posology }}></div>
          )}
        </div>
      </div>
    </div>
  )
}

const Section = ({ text, action, isSelectedSectionOpen = false }) => {
  return (
    <button
      type="button"
      onClick={action}
      className={`focus:outline-none min-h-blockHeight flex items-center justify-between w-full h-full p-blockInsidePadding ${
        isSelectedSectionOpen ? " bg-quatro" : " bg-secondary"
      }`}
    >
      <H3 heavy>{text}</H3>
      <Arrow state={isSelectedSectionOpen} />
    </button>
  )
}
