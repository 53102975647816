import React, { useEffect, useState } from "react"

// Libraries
import { formatPhoneNumberIntl } from "react-phone-number-input"

// Services
import { formatName } from "../../Services/Name"

// Config
import { colorMode } from "../../Config/colorMode"

import RoundedBtn from "../../components/Btns/RoundedBtn"
import RoundedBtnEmptyBg from "../../components/Btns/RoundedBtnEmptyBg"
import { PageContainer } from "../../components/Container"
import H3 from "../../components/DefineTagClass/H3"
import PrimaryText from "../../components/DefineTagClass/PrimaryText"
import LoadingSpinner from "../../components/Loading"
import { getHours, getMinutes, intlFormat } from "date-fns"
import { Header1 } from "../../components/DefineTagClass/Header"
const PRICE_DELIVERY = 5
/**
 *
 * @param {{
 * title: string,
 * children: string
 * even: boolean
 * }} param0
 * @returns
 *
 * @author Yann Lauwers
 */

function TableLine({ title, children, even = false }) {
  let containerDiv =
    "flex items-center justify-between py-cartItemInsidePaddingY px-cartItemInsidePaddingX"

  containerDiv += even ? " bg-quatro" : ""
  return (
    <div className={containerDiv}>
      <div className="w-2/5">
        <H3 heavy>{title}</H3>
      </div>
      <div className="overflow-x-auto w-3/5">
        <PrimaryText>{children}</PrimaryText>
      </div>
    </div>
  )
}

/**
 *
 * @param {{
 * order: {},
 * }} param0
 * @returns
 *
 * @author Yann Lauwers
 */

export function FakeTableTwoCols({ order }) {
  const regex = /(<([^>]+)>)/gi
  const body = order.notice || ""
  const noticeNotEmpty = body.replace(regex, "").length > 0
  return (
    <div className="flex flex-col overflow-x-auto">
      <TableLine title="Nom du patient :" even>
        {formatName(order.user.firstName, order.user.lastName)}
      </TableLine>
      <TableLine title="Adresse Email :">{order.user.email.toLowerCase()}</TableLine>
      <TableLine title="Numéro de téléphone :" even>
        {formatPhoneNumberIntl(order.user.phone)}
      </TableLine>
      <TableLine title="Adresse de livraison :">{order.address}</TableLine>
      {/*<TableLine title="Indications spécifiques :" even>
        {order.deliveryInfo ? order.deliveryInfo : "-"}
      </TableLine>*/}

      <TableLine title="Posologie spécifiques :" even>
        {noticeNotEmpty ? <div dangerouslySetInnerHTML={{ __html: order.notice }}></div> : "-"}
      </TableLine>
    </div>
  )
}

/**
 *
 * @param {{
 * setNav: function,
 * currentStep: number,
 * next: function,
 * prev: function,
 * order: {},
 * state: number,
 * status: {},
 * }} param0
 * @returns
 *
 * @author Yann Lauwers
 */

export default function OrderSummary({ setNav, currentStep, next, prev, order }) {
  const [showLoading, setShowLoading] = useState(false)
  useEffect(() => {
    setNav(colorMode.blueBg)
  }, [])

  const orderPrice = () => {
    let finalPrice = 0
    finalPrice += order.payment.value
    if (!order.pharmacienPaysForDeliveryFee) finalPrice += PRICE_DELIVERY
    return order.payment.status === "paid" ? finalPrice : finalPrice
  }

  if (currentStep !== 2) {
    return null
  }

  const handleSubmit = async e => {
    setShowLoading(true)
    await next(e)
    setShowLoading(false)
  }

  return (
    <>
      <Header1>Récapitulatif de la commande</Header1>
      <PageContainer>
        <div className="flex items-center flex-col mb-20">
          <div className="mb-inputToSubmit w-full flex flex-col">
            <div className="my-mobile-ContainerYT tablet:my-tablet-Container2YT laptop:my-laptop-Container2YT">
              <FakeTableTwoCols order={order} />
            </div>
            <div className="mb-mobile-Container2YT">
              <PrimaryText>
                La commande sera prête à {getHours(new Date(order.deliverySchedul))}h
                {(getMinutes(new Date(order.deliverySchedul)) < 10 ? "0" : "") +
                  getMinutes(new Date(order.deliverySchedul))}{" "}
                le {intlFormat(new Date(order.deliverySchedul))}
              </PrimaryText>
            </div>
            {/*<div className="mb-mobile-Container2YT">
              <PrimaryText>
                {order.pharmacienPaysForDeliveryFee ? "Le pharmacien" : "Le patient"} prend en
                charge les frais de livraison de {PRICE_DELIVERY}€.
              </PrimaryText>
            </div>*/}
            {/*{order.payment.status === "paid" ? (
              <div className="mb-mobile-Container2YT">
                <PrimaryText>La commade a déjà été réglée.</PrimaryText>
              </div>
            ) : (
              <PrimaryText>
                Le patient devra encore payer un montant de <strong>{orderPrice()}€</strong> pour sa
                commande.
              </PrimaryText>
            )}*/}
          </div>
          <div className="flex items-center">
            <div className="mr-spaceBetweenBackAndContinue">
              <RoundedBtnEmptyBg action={prev} name="Retour" type="submit">
                Retour
              </RoundedBtnEmptyBg>
            </div>

            {showLoading ? (
              <LoadingSpinner isGreen />
            ) : (
              <RoundedBtn action={e => handleSubmit(e)} name="envoyer" type="submit">
                Envoyer
              </RoundedBtn>
            )}
          </div>
        </div>
      </PageContainer>
    </>
  )
}
