import PrimaryText from "../DefineTagClass/PrimaryText"
import Info from "../Images/Info"

export default function Information({ children }) {
  return (
    <div className="bg-quatro tablet:rounded-2xl tablet:w-full tablet:relative">
      <div className="w-mobileInfoW tablet:w-full mx-auto relative tablet:static py-5 tablet:px-5">
        <div className="absolute top-3 tablet:-top-3 right-full tablet:-left-10">
          <div className="tablet:hidden">
            <Info size={22} />
          </div>
          <div className="hidden tablet:contents">
            <Info size={36} />
          </div>
        </div>
        <PrimaryText textPosition="text-center tablet:text-left">{children}</PrimaryText>
      </div>
    </div>
  )
}
