import React, { useEffect } from "react"
import ClassicBtn from "../../components/Btns/ClassicBtn"
import RoundedBtn from "../../components/Btns/RoundedBtn"
import { Container, PageContainer } from "../../components/Container"
import H1 from "../../components/DefineTagClass/H1"
import H2 from "../../components/DefineTagClass/H2"
import PrimaryText from "../../components/DefineTagClass/PrimaryText"

import { colorMode } from "../../Config/colorMode"

/**
 *
 * @param {{
 * nextStep: function,
 * backToPharmcyChoice: function,
 * setPharmacyChoice: function,
 * setNav: function,
 * }} param0
 *
 * @author Lauwers Yann
 */

export default function PharmacyNotEncodedYet({
  nextStep,
  backToPharmacyChoice,
  setPharmacyChoice,
  setNav,
}) {
  useEffect(() => {
    setNav(colorMode.blueBg)
  })

  const handlePharmacyChoice = () => {
    setPharmacyChoice("")
    backToPharmacyChoice()
  }
  return (
    <>
      <H1>Nouvelle commande</H1>
      <PageContainer>
        <Container>
          <H2 green>Votre pharmacie de référence n’a pas encore rejoint le réseau Smile.</H2>
          <div className="mb-inputToSubmit">
            <PrimaryText>
              Suite à votre demande, nous allons la solliciter pour lui proposer nos services.
            </PrimaryText>
            <br></br>
            <PrimaryText>En attendant, que préférez-vous faire ?</PrimaryText>
          </div>
          <div className="outline-none flex items-center flex-col pb-20">
            <div className="mb-btnToBtn w-full max-w-mobileBtnMaxWidth">
              <RoundedBtn
                action={handlePharmacyChoice}
                name="back to pharmacy choice"
                type="button"
                canHover
              >
                Je commande dans une autre pharmacie
              </RoundedBtn>
            </div>
            <div className="w-full max-w-mobileBtnMaxWidth text-center">
              <ClassicBtn
                action={nextStep}
                name="do not order with Smile"
                type="button"
                noHighlight
              >
                Ne pas commander via le service Smile
              </ClassicBtn>
            </div>
          </div>
        </Container>
      </PageContainer>
    </>
  )
}
