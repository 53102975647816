const fetch = require("node-fetch")

const backend =
  window.location.hostname === "app.joinsmile.org"
    ? "https://api.joinsmile.org/"
    : process?.env.REACT_APP_API_URL // (process.env.NODE_ENV === 'production') ? 'https://api.joinsmile.org/' : process.env.REACT_APP_API_URL

/**
 * This function fetch JSON data from the backend.
 *
 * @param {String} method type of the request method.
 * @param {String} path the path after the addresse of the server.
 * @param {Object} body Object to send.
 */
const request = async (method, path, body) => {
  const data = {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    cache: "default",
    mode: "cors",
  }

  if (body) {
    data.body = JSON.stringify(body)
  }
  const response = {}
  const urlApi = backend + path
  try {
    const res = await fetch(urlApi, data)
    return res
  } catch (err) {
    response.error = {
      code: -1,
      message: err,
    }
  }
  return response
}

const requestFile = async (method, path, body) => {
  const data = {
    method: method,
    credentials: "include",
    redirect: "follow",
  }

  function buildFormData(formData, data, parentKey) {
    if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
      if (Array.isArray(data)) {
        const myKey = parentKey.slice(-2) === "[]" ? parentKey : parentKey + "[]"
        for (var key in data) {
          formdata.append(myKey, data[key])
        }
      } else {
        Object.keys(data).forEach(key => {
          buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
        })
      }
    } else {
      const value = data == null ? "" : data

      formData.append(parentKey, value)
    }
  }

  if (body) {
    var formdata = new window.FormData()
    /* Object.keys(body).forEach(key => {
      if (Array.isArray(body[key])) {
        for (var elmt of body[key]) {
          formdata.append(key, elmt)
        }
      } else {

      }
      formdata.append(key, body[key])
    }) */
    buildFormData(formdata, body)
    data.body = formdata
  }

  const response = {}
  const urlApi = backend + path
  try {
    const res = await fetch(urlApi, data)
    return res

  } catch (err) {
    response.error = {
      code: -1,
      message: err,
    }
  }
  return response
}

/**
 *
 * @param {string} url
 */
export const getUrlFile = url => {
  if (url.charAt(0) === ".") url = url.slice(1)
  return `${backend}${url}`
}

/**
 * This function fetch JSON data from the backend with a POST method.
 *
 * @param {String} path the path after the addresse of the server.
 * @param {Object} body Object to send.
 */
export const POST = async (path, body) => {
  return await request("POST", path, body)
}
export const POSTFile = async (path, body) => {
  return await requestFile("POST", path, body)
}
/**
 * This function fetch JSON data from the backend with a GET method.
 *
 * @param {String} path the path after the addresse of the server.
 * @param {Object} body Object to send.
 */
export const GET = async path => {
  return await request("GET", path, undefined)
}

/**
 * This function fetch JSON data from the backend with a PUT method.
 *
 * @param {String} path the path after the addresse of the server.
 * @param {Object} body Object to send.
 */
export const PUT = async (path, body) => {
  return await request("PUT", path, body)
}
export const PUTFile = async (path, body) => {
  return await requestFile("PUT", path, body)
}

/**
 * This function fetch JSON data from the backend with a DELETE method.
 *
 * @param {String} path the path after the addresse of the server.
 * @param {Object} body Object to send.
 */
export const DELETE = async (path, body) => {
  return await request("DELETE", path, body)
}
