import React from "react"

export default function LocalisationFill() {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 551.84 551.84"
    >
      <circle className="fill-current text-ternary" cx="275.92" cy="275.92" r="275.92" />
      <path
        className="fill-current text-secondary"
        d="M400.13,211.74c39.18.41,73.67,13.13,102.48,39.93A150.4,150.4,0,0,1,549.25,337a147.09,147.09,0,0,1-8.73,83.23,114.38,114.38,0,0,1-19.34,31Q464.66,517.65,408.11,584a10.74,10.74,0,0,1-5.36,3.84,9.37,9.37,0,0,1-9.85-2.75c-1.59-1.68-3-3.51-4.54-5.27Q333.69,515.65,279,451.49c-14-16.37-22.77-35.16-27.27-56.09a145.27,145.27,0,0,1-3.23-38.83c2-35.91,14.88-67.46,38.67-94.42,21.8-24.71,49-40.34,81.27-47.23A159.59,159.59,0,0,1,400.13,211.74ZM400,563.65l2.52-2.93L441,515.62l65-76.32c7-8.21,12.95-17.08,17-27.13a128,128,0,0,0,7.24-72c-5.31-28.92-18.56-53.72-40.09-73.68-32.19-29.83-70.49-40.34-113.51-33.36C348.22,237.76,324.08,251,304.4,272c-21.61,23-33.76,50.38-36.37,81.85a127.07,127.07,0,0,0,2.27,36c3.79,18.61,11.49,35.23,23.93,49.75q50.4,58.85,100.52,117.94Z"
        transform="translate(-124.08 -124.08)"
      />
      <path
        className="fill-current text-secondary"
        d="M400,291.47c38.66-.27,71.46,31.41,71.47,71.3a71.48,71.48,0,0,1-71.8,71.51c-39.13,0-71.19-32.21-71.13-71.48C328.61,322.44,361.73,291.19,400,291.47ZM452.18,363a52.17,52.17,0,0,0-104.33-.35c-.15,28.56,23.23,52,51.87,52.27A52.29,52.29,0,0,0,452.18,363Z"
        transform="translate(-124.08 -124.08)"
      />
    </svg>
  )
}
