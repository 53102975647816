import React, { useEffect, useState } from "react"
import { Router } from "react-router-dom"
import { isLoggedIn } from "./Backend/user"
import { Footer } from "./components/footer"
import Navbar from "./components/navbar/Navbar"
import { colorMode } from "./Config/colorMode"
import browserHistory from "./Config/history"
import { UserDTO } from "./Models/UserDTO"
import { MandataireDTO } from "./Models/MandataireDTO"
import Routes from "./routes/Routes"
import { UserContext } from "./Services/UserContext"

export default function App() {
  const [nav, setNav] = useState(colorMode.whiteBg)
  const [user, setUser] = useState(
    localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null
  )
  const isUserLoggedIn = user?.pharmacy?.id !== undefined

  useEffect(() => {
    if (!isUserLoggedIn) {
      getSession()
    }
  }, [isUserLoggedIn])

  if (!window.location.protocol.includes("s") && window.location.hostname !== "localhost") {
    window.location.protocol = "https:"
  }
  const urlString = window.location.search
  const searchParams = new URLSearchParams(urlString)

  const getSession = async () => {
    try {
      const data = await isLoggedIn()
      if (data) {
        const currentUser = new UserDTO()
        currentUser.email = data.email
        currentUser.id = data.id
        currentUser.lastname = data.lastname
        currentUser.name = data.name
        currentUser.pharmacy = {
          id: data.pharma.apbId,
          name: data.pharma.name,
          address: {
            streetNumber: data.pharma.address.streetNumber,
            street: data.pharma.address.street,
            city: data.pharma.address.city,
            postalCode: data.pharma.address.postalCode,
            latitude: data.pharma.address.latitude,
            longitude: data.pharma.address.longitude,
          },
          companyName: data.pharma.companyName,
          companyVAT: data.pharma.companyVAT,
          perimeter: data.pharma.perimeter,
        }
        currentUser.Mandataire ={
          name: data.mandataire.name,
          lastname: data.mandataire.lastname,
          email: data.mandataire.email,
          phone: data.mandataire.phone,
          address: {
            streetNumber: data.mandataire.address.streetNumber,
            street: data.mandataire.address.street,
            city: data.mandataire.address.city,
            postalCode: data.mandataire.address.postalCode,
            latitude: data.mandataire.address.latitude,
            longitude: data.mandataire.address.longitude,
          },
        }
        currentUser.status = data.status
        localStorage.setItem("user", JSON.stringify(currentUser))
        return setUser(currentUser)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className="App h-screen flex flex-col">
      <Router history={browserHistory}>
        <UserContext.Provider value={{ user, isLoggedIn: isUserLoggedIn }}>
          {searchParams.get("navbar") === "false" ? null : <Navbar color="primary" />}
          <Routes nav={nav} setNav={() => {}} />

          {/* I commented  the footer because it was in the middle of the page - and I created the footer in WordPress */}

          {/* {searchParams.get("navbar") === "false" ? null : <Footer />} */}
        </UserContext.Provider>
      </Router>
    </div>
  )
}
