import React from "react"

import DrugCard from "./DrugCard"

/**
 *
 * @param {{
 * step: number,
 * items: [],
 * removeItem: function,
 * invert: boolean,
 * canEdit: boolean,
 * edit: boolean,
 * setItemToEdit: function,
 * isIdCardPrescription: boolean
 * }} param0
 *
 * @author Lauwers Yann
 */

export const CardNoPrescription = ({
  items,
  removeItem,
  invert,
  canEdit,
  noBorderOnOrderSum,
  edit,
  setItemToEdit,
}) => {
  return (
    <DrugCard
      removeItem={removeItem}
      editItem={edit}
      items={items}
      invert={invert}
      canEdit={canEdit}
      setItemToEdit={setItemToEdit}
      noBorderOnOrderSum={noBorderOnOrderSum}
    />
  )
}

export const CardPrescriptionIdCard = ({
  items,
  removeItem,
  isPrescriptionIdCard,
  invert,
  canEdit,
  noBorderOnOrderSum,
  edit,
  setItemToEdit,
  //isIdCardPrescription
}) => {
  return (
    <DrugCard
      titre =  "Prescription via carte d'identité"
      removeItem={removeItem}
      editItem={edit}
      items={items}
      invert={invert}
      canEdit={false}
      setItemToEdit={setItemToEdit}
      noBorderOnOrderSum={noBorderOnOrderSum}
      isPrescriptionIdCard = {isPrescriptionIdCard}
    />
  )
}

export const CardElectronicPrescriptionDoctor = ({
  items,
  removeItem,
  invert,
  canEdit,
  noBorderOnOrderSum,
  isPrescriptionMedecin,
  edit,
  setItemToEdit,
  //isIdCardPrescription
}) => {
  return (
    <DrugCard
      titre =  "prescription Envoyée par le médecin"
      removeItem={removeItem}
      editItem={edit}
      items={items}
      invert={invert}
      canEdit={false}
      setItemToEdit={setItemToEdit}
      noBorderOnOrderSum={noBorderOnOrderSum}
      isPrescriptionMedecin = {isPrescriptionMedecin}
    />
  )
}



export const CardElectronicPrescription = ({
  items,
  removeItem,
  invert,
  canEdit,
  noBorderOnOrderSum,
  edit,
  setItemToEdit,
  //isIdCardPrescription
}) => {
  return (
    <DrugCard
      titre =  "prescription Par code RID "
      removeItem={removeItem}
      editItem={edit}
      items={items}
      invert={invert}
      canEdit={canEdit}
      setItemToEdit={setItemToEdit}
      noBorderOnOrderSum={noBorderOnOrderSum}
    />
  )
}

export const CardPaperPrescription = ({
  items,
  removeItem,
  invert,
  canEdit,
  noBorderOnOrderSum,
  paperPrescriptions,
}) => {
  return (
    <DrugCard
      titre="Prescription papier"
      removeItem={removeItem}
      items={items}
      invert={invert}
      canEdit={canEdit}
      noBorderOnOrderSum={noBorderOnOrderSum}
      paperPrescriptions={paperPrescriptions}
    />
  )
}
