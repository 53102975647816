import React from "react"

/**
 *
 * @param {{
 *  children: string,
 * }} param0
 *
 * @author Lauwers Yann
 */

export default function ClassicButtonText({ children }) {
  return (
    <p className="text-center font-textFontFamily font-text-FontWeight text-text-FontSize tablet:text-tablet-text-FontSize laptop:text-laptop-text-FontSize leading-text tablet:leading-tablet-text laptop:leading-laptop-text">
      {children}
    </p>
  )
}
