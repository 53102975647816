import StarsFeedback from "./StarsFeedback"

function FeedBackNote({ value, endRange }) {
  return (
    <div className={`${value ? "visible" : "invisible"} w-9 filter drop-shadow-md flex`}>
      <p
        className={`font-titleFontFamily text-header2-FontSize font-bold leading-header3 text-primary font-header2-FontWeight`}
      >
        {value}
      </p>
      <p
        className={`font-titleFontFamily text-header3-FontSize font-bold leading-header3 text-primary font-header3-FontWeight`}
      >
        /{endRange}
      </p>
    </div>
  )
}

export default function StarsFeedbackWithResult({ feedbackValue, handleChange, endRange }) {
  return (
    <div className="flex items-center">
      <FeedBackNote value={feedbackValue} endRange={endRange} />
      <StarsFeedback
        handleChange={handleChange}
        selectedFeedbackValue={feedbackValue}
        endRange={endRange}
      />
    </div>
  )
}
