import React, { useEffect } from "react";
import ImageCard from "../Card/ImageCard";
import { Container } from "../Container";
import H2 from "../DefineTagClass/H2";
import Label from "../DefineTagClass/Label";
import RoundedButtonText from "../DefineTagClass/RoundedButtonText";
import RoundedBtn from "../Btns/RoundedBtn";
import BenevoleSummaryNavbar from "../navbar/BenevoleSummaryNavbar";
import Information from "../Information/Information";
import { colorMode } from "../../Config/colorMode";

export default function BenevoleSummary({
  hideCart,
  mandataireChoice,
  backWithMandataire,
  children,
  distance,
  action,
}) {

  return (
    <>
      <div

        className="bg-secondary font-titleFontFamily border border-gray-200"
        style={{
          maxWidth: 750,
          maxHeight: 800,
          height: 600,
          marginLeft: 400,
          marginTop: 80,
          borderColor: {blue : "rgba(27, 80, 86, 0.1)"},
        }}
        // children={mandataireChoice.Nom}
      >
        <BenevoleSummaryNavbar
          hideCart={hideCart}
          children={mandataireChoice.lastname}
          distance={distance}
        />

        {/* <Container className="font-titleFontFamily"> */}
        {/* <div style={{marginLeft: 10 , marginTop: -80, maxWidth:300}}>  */}
        <div className="flex flex-wrap items-center ml-10 mb-20">
          <div className="ml-10">
            {<img src={mandataireChoice.image.url} width={300}  />}
          </div>
          <div className="ml-10" style={{width: 200 , height:150}}>
            {mandataireChoice.status}
          </div>
        </div>

        {/* </div> */}
        <ul>
          {/* <strong>{mandataireChoice.addresse}</strong> */}
        </ul>
        <ul>
          {/* <strong>{mandataireChoice.phone}</strong> */}
        </ul>
        {/* </Container> */}
        <form
          onSubmit={backWithMandataire}
          className="flex items-center flex-col"
        >
          <RoundedBtn
            action={backWithMandataire}
            children={children}
            name="continuer"
            type="submit"
          >
            choisir {mandataireChoice.lastname}
          </RoundedBtn>
        </form>
      </div>
    </>
  );
}
