import React from "react"

/**
 *
 * @param {{
 *  children: string,
 * }} param0
 *
 * @author Lauwers Yann
 */

export function CheckboxesText({ children }) {
  return (
    <div className="font-textFontFamily font-checkBoxLabel-FontWeight text-checkBoxLabel-FontSize tablet:text-tablet-checkBoxLabel-FontSize laptop:text-laptop-checkBoxLabel-FontSize tablet:tablet-leading-checkBoxLabel laptop:laptop-leading-checkBoxLabel">
      {children}
    </div>
  )
}
