import React, { Children } from "react";
import { NavContainer } from "../Container";

import H2 from "../DefineTagClass/H2";
import Cross from "../Images/Cross";

/**
 *
 * @param {{
 * hideCart: function,
 * }} param0
 *
 * @author Lauwers Yann
 */

export default function BenevoleSummaryNavbar({ hideCart , children, distance}) {
  return (
    
    <NavContainer>
      <div
        className="flex justify-between items-center bg-secondary h-navbarHeight w-full"

      >
        <H2 noMargin noPadding>
          {children}
        </H2>
        <H2 className="absolute right-4 top-4 bg-ternary text-secondary rounded-blueBg py-9.5 px-2.5">
          <p className="font-titleFontFamily font-header3-FontWeight">
            {distance}
          </p>
        </H2>
        <button onClick={hideCart} className="focus:outline-none">
          <Cross />
        </button>
      </div>
    </NavContainer>
  );
}
