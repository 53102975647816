import React, { useEffect, useRef, useState } from "react"

// transitions
import TranslateUp from "../../components/Transitions/TranslateUp"

//Backend
import { getMedecines } from "../../Backend/medecines"

// Packages
import { createFilter } from "react-select"
// import AsyncSelect from "react-select/async"
import AsyncCreatableSelect from "react-select/async-creatable"

// Config
import { colorMode } from "../../Config/colorMode"

// Services
import { addError } from "../../Services/errors"

// Components
import ErrorMessage from "../../components/ErrorMessage"
import RoundedBtn from "../../components/Btns/RoundedBtn"
import DrugCard from "../../components/Card/DrugCard"
import H3 from "../../components/DefineTagClass/H3"
import BtnFixedBottom from "../../components/Btns/BtnFixedBottom"
import FullyRoundedBackBtn from "../../components/Btns/FullyRoundedBackBtn"
import H1 from "../../components/DefineTagClass/H1"
import { ContainerWithButtonFixedBottom, PageContainer } from "../../components/Container"
import H2 from "../../components/DefineTagClass/H2"
import {
  DropdownIndicator,
  Placeholder,
  NoOptionsMessage,
  IndicatorSeparator,
  customWhiteBgStyles,
} from "../../components/Selects/AsyncSelect"

/**
 *
 * @param {{
 * step: number,
 * noPrescriptions: [],
 * setNoPrescriptions: function,
 * removeItem: function,
 * backToPrescriptionChoice: function,
 * backToChooseYourCommandType: function,
 * cartValue: variable,
 * showCart: function,
 * orderSummary: component,
 * I dont know what to say for ordersummary: because it's a component..
 * displayCart: function,
 * setNav: function
 * }} param0
 * @author Lauwers Yann
 */

export default function NoPrescription({
  step,
  noPrescriptions,
  setNoPrescriptions,
  removeItem,
  backToPrescriptionChoice,
  backToChooseYourCommandType,
  cartValue,
  showCart,
  orderSummary,
  displayCart,
  setNav,
}) {
  const [drug, setDrug] = useState("")
  const [errors, setErrors] = useState([])
  const errorRef = useRef(null)
  const pharmacySelectRef = useRef(null)

  useEffect(() => {
    setNav(colorMode.blueBg)
  }, [])

  const loadMedicines = async query => {
    try {
      if (query.length >= 3) {
        const allMedicines = await getMedecines(query)
        // Need to create json object for react-select
        const options = allMedicines.map(med => ({
          med,
        }))
        options.push({ med: query })
        return options
      }
      return null
    } catch (error) {
      console.error(error)
    }
  }

  const errorDealing = () => {
    const array = []
    const drug_missing = "Le champ ne peut pas être vide"
    const drug_incorrect = "Le champ ne peut pas faire moins de trois caractères"
    if (!drug) {
      addError("drug_missing", drug_missing, array)
    }
    if (drug?.length < 3) {
      addError("drug_incorrect", drug_incorrect, array)
    }
    setErrors(array)
    return array.length
  }

  const handleDrugs = e => {
    errorDealing()
    if (!errorDealing()) {
      setNoPrescriptions([...noPrescriptions, e.med])
      return setDrug("")
    }
  }

  const handleInputChange = newValue => {
    const inputValue = newValue //.replace(/\W/g, "")
    setDrug(inputValue)
    return inputValue
  }

  const handleKeyDown = event => {
    if (drug.length >= 3) {
      return
    }
    // eslint-disable-next-line default-case
    switch (event.key) {
      case "Enter":
      case "Tab":
        event.preventDefault()
        errorDealing()
    }
  }

  return (
    <>
      <TranslateUp in={displayCart} renderedComponent={orderSummary} />
      <H1>Nouvelle commande</H1>
      <PageContainer>
        <ContainerWithButtonFixedBottom>
          <H2 green>Commande sans prescription</H2>
          <ErrorMessage errors={errors} ref={errorRef} />
          <form onSubmit={e => e.preventDefault()} className="flex items-center flex-col flex-grow">
            <div className="mb-inputToSubmit w-full flex flex-col">
              <div className={`${noPrescriptions.length > 0 ? "mb-inputToCartItem" : null}`}>
                <H3 hasMarginB>De quoi avez-vous besoin ?</H3>
                <div className="rounded-formSelect">
                  <AsyncCreatableSelect
                    autoFocus
                    inputId="async-select-id"
                    // onFocus={handleFocus}
                    ref={pharmacySelectRef}
                    className="select"
                    name="medicineSelection"
                    cacheOptions
                    value={drug}
                    getOptionLabel={e => e.med}
                    getOptionValue={e => e.med}
                    loadOptions={loadMedicines}
                    onInputChange={handleInputChange}
                    onChange={handleDrugs}
                    onKeyDown={handleKeyDown}
                    filterOption={createFilter({ ignoreAccents: false })}
                    placeholder=""
                    components={{
                      DropdownIndicator,
                      Placeholder,
                      NoOptionsMessage,
                      IndicatorSeparator,
                    }}
                    styles={customWhiteBgStyles}
                  />
                </div>
              </div>
              <div className="w-full border-primary mx-auto">
                <ul>
                  <DrugCard removeItem={removeItem} items={noPrescriptions} step={step} />
                </ul>
              </div>
            </div>
            <div className="flex items-center justify-center">
              <FullyRoundedBackBtn
                action={() => backToChooseYourCommandType()}
                name="retour"
                type="button"
                size={48}
              />
              <div
                className="ml-spaceBetweenBackAndContinue outline-none"
                style={noPrescriptions.length > 0 ? null : { display: "none" }}
              >
                <RoundedBtn
                  action={() => showCart()}
                  name="continuer"
                  type="button"
                  isGreen
                >
                  {cartValue}
                </RoundedBtn>
              </div>
            </div>
          </form>
        </ContainerWithButtonFixedBottom>
      </PageContainer>
      {/* <BtnFixedBottom action={() => showCart()}>{cartValue}</BtnFixedBottom> */}
    </>
  )
}
