import React from "react"
import ArrowBackOutline from "../Images/ArrowBackOutline"

/**
 *
 * @param {{
 * type: string,
 * action: function,
 * name: string,
 * }} param0
 *
 * @author Lauwers Yann
 */

export default function FullyRoundedBackBtn({ type, action, name, size }) {
  return (
    <button
      type={type}
      className="bg-transparent cursor-btn focus:outline-none rounded-roundedBtn"
      onClick={action}
      name={name}
    >
      <ArrowBackOutline size={size} /> 
    </button>
  )
}
