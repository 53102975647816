import React from "react";

// Components
import PharmacyCard2 from "../Card/PharmacyCard2";
import OrderSummaryNavbar from "../navbar/OrderSummaryNavbar";
import ClassicBtn from "../../components/Btns/ClassicBtn";
import Input from "../../components/Inputs/Input";
import H3 from "../../components/DefineTagClass/H3";
import RoundedButtonText from "../../components/DefineTagClass/RoundedButtonText";
import TranslateLeft from "../../components/Transitions/TranslateLeft";
import {
  CardElectronicPrescription,
  CardNoPrescription,
  CardPaperPrescription,
  CardPrescriptionIdCard,
  CardElectronicPrescriptionDoctor,
} from "../../components/Card/DrugCards";
import { Container } from "../Container";
import OrderBag from "../Images/OrderBag";
import Pharmacy from "../Images/Pharmacy";
import Localisation from "../Images/Localisation";

/**
 * Order Summary is a component that will show up with a translate up fct in the steps : 3, 21, 31 and 41
 *
 * @param {{
 * step: number,
 * nextStep: function,
 * deliveryAddress: {},
 * pharmacyChoice: string,
 * noPrescriptions: string[],
 * electronicPrescriptions: string[],
 * paperPrescriptions: string[],
 * idCardPrescription: string[],
 * removeCartItemFromNoPrescriptions: function,
 * removeCartItemFromElectronicPrescriptions: function,
 * removeCartItemFromIdCardPrescriptions: function,
 * removeCartItemFromPaperPrescriptions: function,
 * hideCart: function,
 * handleQuestionToPharmacien: function,
 * questionToPharmacien: string,
 * numberOfItemsInCart: number,
 * toPersonnalDetails: function,
 * showEdit: function,
 * editCartNoPresc: boolean,
 * editCartElectronicPresc: boolean,
 * editCartIdCardPresc: boolean,
 * editNoPrescriptionSummary: function,
 * editElectronicPrescriptionSummary: function,
 * }} param0
 *
 * @author Lauwers Yann
 */

export default function OrderSummary({
  deliveryAddress,
  pharmacyChoice,
  noPrescriptions,
  electronicPrescriptions,
  electronicPrescriptionsDoctor,
  paperPrescriptions,
  idCardPrescription,
  removeCartItemFromNoPrescriptions,
  removeCartItemFromElectronicPrescriptions,
  removeCartItemFromElectronicPrescriptionsDoctor,
  removeCartItemFromIdCardPrescriptions,
  removeCartItemFromPaperPrescriptions,
  hideCart,
  handleQuestionToPharmacien,
  questionToPharmacien,
  numberOfItemsInCart,
  toPersonnalDetails,
  showEdit,
  editCartNoPresc,
  editCartElectronicPresc,
  editCartElectronicPrescDoctor,
  editCartIdCardPresc,
  editNoPrescriptionSummary,
  editElectronicPrescriptionSummary,
  editElectronicPrescriptionDoctorSummary,
  editCartIdCardPrescriptionSummary,

}) {
  const [displayQuestion, setDisplayQuestion] = React.useState(false);

  const toggleButton = (e) => {
    e.preventDefault();
    setDisplayQuestion(!displayQuestion);
  };

  const doNotAllowOrder = (e) => {
    e.preventDefault();
  };

  return (
    <div className="bg-secondary w-full h-screen absolute top-0">
      <TranslateLeft
        in={editCartNoPresc}
        renderedComponent={editNoPrescriptionSummary}
      />
      <TranslateLeft
        in={editCartElectronicPresc}
        renderedComponent={editElectronicPrescriptionSummary}
      />
      <TranslateLeft
        in={editCartElectronicPrescDoctor}
        renderedComponent={editElectronicPrescriptionDoctorSummary}
      />
      <TranslateLeft
        in={editCartIdCardPresc}
        renderedComponent={editCartIdCardPrescriptionSummary}
      />
      <OrderSummaryNavbar hideCart={hideCart} />
      <div className="bg-secondary w-full h-full overflow-y-auto">
        <Container>
          <form
            onSubmit={(e) => e.preventDefault()}
            className="flex items-center flex-col"
          >
            <div className="w-full flex flex-col">
              <div className="mb-inputToInput">
                <div className="mb-cartItemsMargin">
                  <PharmacyCard2
                    img={<Localisation color="text-primary" size={22} />}
                    alt="delivery address icon"
                    label="Votre adresse"
                    isGreen={true}
                    isCart={true}
                    line1={
                      deliveryAddress.streetNumber || deliveryAddress.street
                        ? `${
                            deliveryAddress.streetNumber
                              ? deliveryAddress.streetNumber
                              : ""
                          } ${
                            deliveryAddress.street ? deliveryAddress.street : ""
                          },`
                        : ""
                    }
                    line2={`${
                      deliveryAddress.postalCode
                        ? deliveryAddress.postalCode
                        : ""
                    } ${deliveryAddress.city ? deliveryAddress.city : ""}`}
                  />
                </div>
                <PharmacyCard2
                  img={<Pharmacy />}
                  alt="delivery address icon"
                  label={pharmacyChoice.name}
                  isGreen={true}
                  isCart={true}
                  // The next two lines must be modified to get pharmacies address and not the delivery address
                  line1={pharmacyChoice.address.street}
                  line2={`${
                    pharmacyChoice.address.postalCode
                      ? pharmacyChoice.address.postalCode
                      : ""
                  } ${
                    pharmacyChoice.address.city
                      ? pharmacyChoice.address.city
                      : ""
                  }`}
                />
              </div>
              <div
                className={`border-primary flex justify-center align-center flex-col${
                  displayQuestion ? " mb-inputToInput" : " hidden"
                }`}
              >
                <H3 hasMarginB>Que voulez-vous demander au pharmacien ?</H3>
                <Input
                  type="text"
                  name="question to pharmacien"
                  defaultValueInput={questionToPharmacien}
                  action={handleQuestionToPharmacien}
                />
              </div>
              <div
                className={`mb-inputToInput border-primary mx-auto ${
                  displayQuestion ? " hidden" : ""
                }`}
              >
                <ClassicBtn
                  action={toggleButton}
                  name="question to pharmacien"
                  type="button"
                >
                  Vous avez des précisions à faire au pharmacien ?
                </ClassicBtn>
              </div>
            </div>
          </form>
        </Container>
        <div
          className={`px-mobileContainerX ${
            numberOfItemsInCart === 0
              ? " py-cartPaddingYEmpty "
              : " py-cartPaddingY "
          } bg-quatro mb-cartBtnHeight overflow-y-auto border-b-formSelect border-t-formSelect border-primary border-opacity-10`}
        >
          <div className="border-primary tablet:mx-auto tablet:w-tabletW laptop:mx-auto laptop:w-laptopW">
            <div className="w-full border-primary mx-auto">
              <div
                className={`flex justify-center items-center ${
                  numberOfItemsInCart === 0 ? "flex-col" : " hidden"
                }`}
              >
                <OrderBag strokeColor="primary" iconSize="60" />
                <H3 heavy>Votre panier est vide</H3>
              </div>
              <Container>
                <ul>
                  {noPrescriptions ? (
                    <CardNoPrescription
                      removeItem={removeCartItemFromNoPrescriptions}
                      edit={showEdit}
                      items={noPrescriptions}
                      invert
                      canEdit
                      noBorderOnOrderSum
                    />
                  ) : null}
                  <div
                    className={`${
                      electronicPrescriptions.length !== 0
                        ? "mt-cartItemsMargin"
                        : null
                    }`}
                  >
                    {electronicPrescriptions ? (
                      <CardElectronicPrescription
                        removeItem={removeCartItemFromElectronicPrescriptions}
                        edit={showEdit}
                        items={electronicPrescriptions}
                        invert
                        canEdit
                        noBorderOnOrderSum
                      />
                    ) : null}
                  </div>


                  <div
                    className={`${
                      electronicPrescriptionsDoctor.length !== 0
                        ? "mt-cartItemsMargin"
                        : null
                    }`}
                  >
                    {electronicPrescriptionsDoctor ? (
                      <CardElectronicPrescriptionDoctor

                        removeItem={removeCartItemFromElectronicPrescriptionsDoctor}
                        edit={showEdit}
                        items={electronicPrescriptionsDoctor}
                        invert
                        canEdit
                        noBorderOnOrderSum
                      />
                    ) : null}
                  </div>
                  <div
                    className={`${
                      idCardPrescription.length !== 0
                        ? "mt-cartItemsMargin"
                        : null
                    }`}
                  >
                    {idCardPrescription ? (
                      <CardPrescriptionIdCard

                        removeItem={removeCartItemFromIdCardPrescriptions}
                        edit={showEdit}
                        items={idCardPrescription}
                        invert
                        canEdit
                        noBorderOnOrderSum
                      />
                    ) : null}
                  </div>

                  <div
                    className={`${
                      paperPrescriptions.length !== 0
                        ? "mt-cartItemsMargin"
                        : null
                    }`}
                  >
                    {paperPrescriptions ? (
                      <CardPaperPrescription
                        titre="Prescription papier"
                        removeItem={removeCartItemFromPaperPrescriptions}
                        items={paperPrescriptions}
                        invert
                        paperPrescriptions={paperPrescriptions}
                        noBorderOnOrderSum
                      />
                    ) : null}
                  </div>
                </ul>
              </Container>
            </div>
          </div>
        </div>
      </div>
      <div className="h-cartBtnHeight sticky bottom-0 w-screen flex justify-center items-center bg-secondary shadow-fixedDivBottomOnOrder">
        <button
          className={`w-64 focus:outline-none px-roundedBtnPaddingX py-roundedBtnPaddingY rounded-roundedBtn shadow-btn cursor-btn ${
            numberOfItemsInCart === 0
              ? "bg-quatro text-opacity-50 text-primary"
              : "text-secondary bg-ternary"
          }`}
          onClick={() =>
            numberOfItemsInCart === 0 ? doNotAllowOrder() : toPersonnalDetails()
          }
        >
          <RoundedButtonText>Commander</RoundedButtonText>
        </button>
      </div>
    </div>
  );
}
