import React, { useEffect, useState } from "react";

// Models
import { AddressDTO } from "../Models/AddressDTO";
import { UserDTO } from "../Models/UserDTO";
import { MandataireDTO } from "../Models/MandataireDTO";

// Pages
import WhereToDeliver from "./OrderSteps/1WhereToDeliver";
import PharmacySelection from "./OrderSteps/2PharmacySelection";
import HasAPrescription from "./OrderSteps/5HasAPrescription";
import HasAPrescriptionA from "./OrderSteps/4HasAprescriptionA";
import HasPrescriptionInIdCard from "./OrderSteps/32HasPrescriptionInIdCard";
import PersonnalDetails from "./OrderSteps/6PersonnalDetails";
import Success from "./OrderSteps/7Succes";
import PharmacyNotEncodedYet from "./OrderSteps/11PharmacyNotEncodedYet";
import ContactNewPharmacy from "./OrderSteps/12ContactNewPharmacy";
import NoPrescription from "./OrderSteps/21HasNoPrescription";
import HasElectronicPrescription from "./OrderSteps/31HasElectronicPrescription";
import HasPaperPrescription from "./OrderSteps/41HasPaperPrescription";
import MandataireSelection from "./OrderSteps/51MandataireSelection";



// Components
import OrderSummary from "../components/Summary/OrderSummary";
import {
  EditNoPrescriptionItem,
  EditElectronicPrescriptionItem,
  EditIdCardPrescriptionItem
} from "../components/Edit/Edit";
import OrderWithItemsAmount from "../components/Summary/OrderWithItemsAmount";
import { useHistory, useLocation } from "react-router";
import PharmacyDetails from "../pages/OrderSteps/3PharmacyDetails";
import BenevoleSummary from "../components/Summary/BenevoleSummary";

const steps = {
  whereToDeliver: 1,
  pharmacySelection: 2,
  // pharmacyDetails: 3,
  hasAPrescriptionA: 3,
  hasAPrescription: 4,
  personnalDetails: 5,
  success: 6,
  pharmacyNotEncodedYet: 11,
  contactNewPharmacy: 12,
  hasNoPrescription: 21,
  hasElectronicPrescription: 31,
  HasPrescriptionInIdCard: 32,
  hasPaperPrescription: 41,
  toChooseMandataire: 51
};

/**
 *
 * @param {{
 * setNav: function
 * }} param0
 *
 * @author Yann Lauwers
 */

export default function ClientForm({ setNav }) {
  const history = useHistory();
  const location = useLocation();

  // About const. These are sorted in steps.
  const [step, setStep] = useState(steps.whereToDeliver);

  const [deliveryAddress, setDeliveryAddress] = useState(new AddressDTO());
  const [pharmacyChoice, setPharmacyChoice] = useState("");
  const [mandataireChoice, setMandataireChoice] = useState("");
  useEffect(() => {
    console.log("mandataire  choice useEffect clientForm" , mandataireChoice)
    console.log("pharmacyChoice  choice useEffect clientForm" , pharmacyChoice)
  }, [mandataireChoice])
  const [personnalLink, setPersonnalLink] = useState(false);

  // About cart Summary
  const [displayCart, setDisplayCart] = useState(false);
  const [displayCardBenev, setDisplayCardBenev] = useState(false);
  const [idCardPrescription, setIdCardPrescription] = useState([]);
  const [editCartNoPresc, setEditCartNoPresc] = useState(false);
  const [editCartElectronicPresc, setEditCartElectronicPresc] = useState(false);
  const [editCartIdCardPresc, setEditCartIdCardPresc] = useState(false);
  const [questionToPharmacien, setQuestionToPharmacien] = useState("");

  //Mandataire
  const [nameMandat, setNameMandat] = useState("");
  const [lastNameMandat, setLastNameMandat] = useState("");
  const [phoneMandat, setPhoneMandat] = useState("");
  const [emailMandat, setEmailMandat] = useState("");
  const [booleanForMandatSelection, setBooleanForMandatSelection] = useState(false);
  const [isPrescriptionMedecin, setIsPrescriptionMedecin] = useState(false);
  const [isPrescriptionIdCard, setIsPrescriptionIdCard] = useState(false);

  const [noPrescriptions, setNoPrescriptions] = useState([]);
  const [electronicPrescriptions, setElectronicPrescriptions] = useState([]);
  const [electronicPrescriptionsDoctor, setElectronicPrescriptionsDoctor] = useState([])
  const [paperPrescriptions, setPaperPrescriptions] = useState([]);
  const [itemToEdit, setItemToEdit] = useState("");
  const [deliveryInfo, setDeliveryInfo] = useState("");
  const [editMenu, setEditMenu] = useState(false);
  const [mandatValue, setMandatValue] = useState(false);

  const [user, setUser] = useState(new UserDTO());
  const [mandataire, setMandataire] = useState(new MandataireDTO());
  const [pharmacyCall, setPharmacyCall] = useState(false);
  const [readAndSign, setReadAndSign] = useState(false);
  //const [mandataire, setMandataire] = useState(false);
  const [orderHash, setOrderHash] = useState("");

  const numberOfItemsInCart =
    noPrescriptions.length +
    electronicPrescriptions.length +
    paperPrescriptions.length +
    electronicPrescriptionsDoctor.length+
    idCardPrescription.length;

  useEffect(() => {
    if (location?.state?.pharmacyChoice) {
      setPharmacyChoice(location.state.pharmacyChoice);
      setPersonnalLink(true);
    }else if(location?.state?.mandataireChoice){
      setMandataireChoice(location.state.mandataireChoice)
      setPersonnalLink(true);
    }
  }, []);

  useEffect(() => {
    console.log(mandataire)
  },[mandataire])

  useEffect(() => {
    if (editMenu) {
      if (noPrescriptions.includes(itemToEdit)) {
        setEditCartNoPresc(true);
      }
      if (electronicPrescriptions.includes(itemToEdit)) {
        setEditCartElectronicPresc(true);
      }
      if (idCardPrescription.includes(itemToEdit)) {
        setEditCartIdCardPresc(true);
      }
      console.log("mandataireChoice", mandataireChoice)
    } else {
      if (editCartNoPresc) {
        setEditCartNoPresc(false);
      }
      if (editCartElectronicPresc) {
        setEditCartElectronicPresc(false);
      }
      if(editCartIdCardPresc){
        setEditCartIdCardPresc(false)
      }
      console.log("mandataireChoice", mandataireChoice)
    }

  }, [editMenu]);

  // About Steps
  const nextStep = () => {
    setStep(step + 1);
  };
  const prevStep = () => {
    setStep(step - 1);

  };
  const prevStepM = () => {
    setStep(steps.personnalDetails);
    setBooleanForMandatSelection(true)
  };

  const prevStepToPersonnalDetails = () => {
    setStep(steps.personnalDetails);
    setBooleanForMandatSelection(false)
  };

  const toPharmacyNotEncodedYet = () => {
    setStep(steps.pharmacyNotEncodedYet);
  };

  const toOrderWithoutPrescription = () => {
    setStep(steps.hasNoPrescription);
  };

  const toInfoPageCommandWithIdcard = () => {
    setStep(steps.HasPrescriptionInIdCard);
    addIdCardPrescriptionIntoBracket()
    setIsPrescriptionIdCard(true)
  };

  const toInfoPageCommandByDoctor = () => {
    setStep(steps.HasPrescriptionInIdCard);
    addElectronicPrescriptionDoctorIntoBracket()
    setIsPrescriptionMedecin(true)

    //addPrescriptionIntoBracket();
  };



  const toOrderWithPrescription = () => {
    setStep(steps.hasAPrescription);
  };
  const toChooseYourCommandType = () => {
    setStep(steps.hasAPrescriptionA);
  };

  const toOrderWithElectronicPrescription = () => {
    setStep(steps.hasElectronicPrescription);
    addElectronicPrescriptionIntoBracket()
    //addPrescriptionIntoBracket()
    //addPrescriptionIntoBracket();
  };

  const toChooseMandataire = () =>{
    setStep(steps.toChooseMandataire);
  }

  const toOrderWithPaperPrescription = () => {
    setStep(steps.hasPaperPrescription);
  };

  const toPersonnalDetails = () => {
    setStep(steps.personnalDetails);
  };

  const toPrescriptionChoice = () => {
    setStep(steps.hasAPrescription);
  };

  const toPharmacyChoice = () => {
    setStep(steps.pharmacySelection);
  };

  const toBeginningOfOrder = () => {
    setStep(steps.whereToDeliver);
  };
  //

  const handlePharmacyChoice = (pharmacy) => {
    setPharmacyChoice(pharmacy);
  };

  const handleMandataireChoice = (mandataire) => {
    setMandataireChoice(mandataire);
    console.log("mandataire val" , mandataire)
    setMandatValue(true)
  };

  const handleDeliveryAddress = (address) => {
    setDeliveryAddress(address);
  };

  const handleQuestionToPharmacien = (question) => {
    setQuestionToPharmacien(question.target.value);
  };

  //mandataire


  // const handleLastNameMandataire = (lastName) => {
  //   setLastNameMandat(lastName.target.value);
  // };
  // const handlePhoneMandataire = (phoneNumber) => {
  //   setPhoneMandat(phoneNumber.target.value);
  // };
  // const handleEmailMandataire = (emailM) => {
  //   setEmailMandat(emailM.target.value);
  // };


  const needACall = (value) => {
    setPharmacyCall(value.value);
  };

  // About Cart summary
  const removeCartItemFromNoPrescriptions = (index, e) => {
    e.preventDefault();
    if (index > -1) {
      let arr = [...noPrescriptions];
      arr.splice(index, 1);
      setNoPrescriptions(arr);
    }
  };

  const removeCartItemFromElectronicPrescriptions = (index, e) => {
    e.preventDefault();
    if (index > -1) {
      let arr = [...electronicPrescriptions];
      arr.splice(index, 1);
      setElectronicPrescriptions(arr);
    }
  };
  const removeCartItemFromElectronicPrescriptionsDoctor = (index, e) => {
    e.preventDefault();
    if (index > -1) {
      let arr = [...electronicPrescriptionsDoctor];
      arr.splice(index, 1);
      setElectronicPrescriptionsDoctor(arr);
      setIsPrescriptionMedecin(false)
    }
  };

  const removeCartItemFromIdCardPrescriptions = (index, e) => {
    e.preventDefault();
    if (index > -1) {
      let arr = [...idCardPrescription];
      arr.splice(index, 1);
      setIdCardPrescription(arr);
      setIsPrescriptionIdCard(false)
    }
  };

  const addElectronicPrescriptionDoctorIntoBracket = (index, e) => {// here the place to fix the real prescription
    //e.preventDefault();                             // this version is just a test
    let arr = [...electronicPrescriptionsDoctor]
    // here the place to fix the real prescription
    //e.preventDefault();                             // this version is just a test
    arr.push(index);
    setElectronicPrescriptionsDoctor(arr);
  }

  const addPrescriptionIntoBracket = (index, e) => {
    // here the place to fix the real prescription
    //e.preventDefault();                             // this version is just a test
    let arr = [...paperPrescriptions];
    arr.push(index);
    setElectronicPrescriptionsDoctor(arr);
  }

  const addElectronicPrescriptionIntoBracket = (index, e) => {// here the place to fix the real prescription
    //e.preventDefault();                             // this version is just a test
    let arr = [...electronicPrescriptions]
   // arr.push(index);
    setElectronicPrescriptions(arr);
  };

  const addIdCardPrescriptionIntoBracket = (index, e) => {// here the place to fix the real prescription
    //e.preventDefault();                             // this version is just a test
    let arr = [...idCardPrescription]
    arr.push(index);
    setIdCardPrescription(arr);
  }

  // const addDoctorPrescriptionIntoBracket = (index, e) => {// here the place to fix the real prescription
  //   //e.preventDefault();                             // this version is just a test
  //   let arr = [...electronicPrescriptions]
  //   arr.push(index);
  //   setElectronicPrescriptions(arr);
  // }

  // const addIdCardPrescriptionIntoBracket = (index, e) => {// here the place to fix the real prescription
  //   //e.preventDefault();                             // this version is just a test
  //   let arr = [...electronicPrescriptions]
  //   arr.push(index);
  //   setElectronicPrescriptions(arr);
  // }

  const removeCartItemFromPaperPrescriptions = (index, e) => {
    e.preventDefault();
    if (index > -1) {
      let arr = [...paperPrescriptions];
      arr.splice(index, 1);
      setPaperPrescriptions(arr);
    }
  };

  const refresh = () => {
    return history.push("/");
  };

  // About drug edit
  const hideEdit = () => {
    setEditMenu(false);
    // if (editCartNoPresc) {
    //   setEditCartNoPresc(false)
    // }
    // if (editCartElectronicPresc) {
    //   setEditCartElectronicPresc(false)
    // }
  };

  const showEdit = (item) => {
    setItemToEdit(item);
    setEditMenu(true);
    // if (noPrescriptions.includes(itemToEdit)) {
    //   setEditCartNoPresc(true)
    // }
    // if (electronicPrescriptions.includes(itemToEdit)) {
    //   setEditCartElectronicPresc(true)
    // }
  };
/**
   * this next const allows us to use our benevole summary in only one sentence in the differents step we need it.
   */
const benevoleSummary =(
  <BenevoleSummary
    hideCart={() => setDisplayCardBenev(false)}
    mandataireChoice = {mandataireChoice}
    benevole = {mandataireChoice}
    action
    setMandataireChoice={handleMandataireChoice}
    backWithMandataire = {prevStepM}

  />

)


  /**
   * this next const allows us to use our order summary in only one sentence in the differents step we need it.
   */
  const orderSummary = (
    <OrderSummary
      nextStep={nextStep}
      deliveryAddress={deliveryAddress}
      pharmacyChoice={pharmacyChoice}
      noPrescriptions={noPrescriptions}
      idCardPrescription = {idCardPrescription}
      electronicPrescriptions={electronicPrescriptions}
      paperPrescriptions={paperPrescriptions}
      electronicPrescriptionsDoctor={electronicPrescriptionsDoctor}
      removeCartItemFromNoPrescriptions={removeCartItemFromNoPrescriptions}
      removeCartItemFromElectronicPrescriptions={
        removeCartItemFromElectronicPrescriptions
      }
      removeCartItemFromElectronicPrescriptionsDoctor={
        removeCartItemFromElectronicPrescriptionsDoctor
      }
      removeCartItemFromIdCardPrescriptions= {removeCartItemFromIdCardPrescriptions}
      removeCartItemFromPaperPrescriptions={
        removeCartItemFromPaperPrescriptions
      }
      hideCart={() => setDisplayCart(false)}
      questionToPharmacien={questionToPharmacien}
      handleQuestionToPharmacien={handleQuestionToPharmacien}

      numberOfItemsInCart={numberOfItemsInCart}
      toPersonnalDetails={toPersonnalDetails}
      editCartNoPresc={editCartNoPresc}
      editCartElectronicPresc={editCartElectronicPresc}
      editCartIdCardPresc = {editCartIdCardPresc}
      editNoPre scriptionSummary={
        <EditNoPrescriptionItem
          prevStep={hideEdit}
          itemToEdit={itemToEdit}
          setItemToEdit={setItemToEdit}
          noPrescriptions={noPrescriptions}
          setNoPrescriptions={setNoPrescriptions}
          hideEdit={hideEdit}
        />
      }
      editElectronicPrescriptionSummary={
        <EditElectronicPrescriptionItem
          prevStep={hideEdit}
          itemToEdit={itemToEdit}
          setItemToEdit={setItemToEdit}
          electronicPrescriptions={electronicPrescriptions}
          setElectronicPrescriptions={setElectronicPrescriptions}
          hideEdit={hideEdit}
        />
      }
      editCartIdCardPrescriptionSummary={
        <EditIdCardPrescriptionItem
          prevStep={hideEdit}
          itemToEdit={itemToEdit}
          setItemToEdit={setItemToEdit}
          isCardPrescription={idCardPrescription}
          setEditCartIdCardPresc={setEditCartIdCardPresc}
          hideEdit={hideEdit}
        />
      }
      showEdit={showEdit}
    />
  );



  switch (step) {
    case steps.whereToDeliver:
      return (
        <WhereToDeliver
          nextStep={nextStep}
          nextStepIfPersonnalLink={toPrescriptionChoice}
          toPrescriptionChoice={toPrescriptionChoice}
          setDeliveryAddress={handleDeliveryAddress}
          deliveryAddress={deliveryAddress}
          setPharmacyChoice={handlePharmacyChoice}
          pharmacyChoice={pharmacyChoice}
          backToPharmacyNotEncodedYet={toPharmacyNotEncodedYet}
          personnalLink={personnalLink}
          setPersonnalLink={setPersonnalLink}
          setNav={setNav}
          step={step}
        />
      );
    case steps.pharmacySelection:
      return (
        <PharmacySelection
          step={step}
          prevStep={prevStep}
          nextStep={nextStep}
          setPharmacyChoice={handlePharmacyChoice}
          pharmacyChoice={pharmacyChoice}
          deliveryAddress={deliveryAddress}
          setNav={setNav}
        />
      );
    case steps.pharmacyDetails:
      return (
        <PharmacyDetails
          pharmacyChoice={pharmacyChoice}
          prevStep={prevStep}
          nextStep={nextStep}
        />
      );
    case steps.hasAPrescriptionA:
      return (
        <HasAPrescriptionA
          prevStep={prevStep}
          toOrderWithoutPrescription={toOrderWithoutPrescription}
          toOrderWithPrescription={toOrderWithPrescription}
          toInfoPageCommandWithIdcard = { toInfoPageCommandWithIdcard}//toOrderWithoutPrescription={toOrderWithoutPrescription}///this
          toInfoPageCommandByDoctor = {toInfoPageCommandByDoctor}
          toOrderWithElectronicPrescription={toOrderWithElectronicPrescription}
          // toOrderWithPaperPrescription={toOrderWithPaperPrescription}
          showCart={() => setDisplayCart(true)}
          numberOfItemsInCart={numberOfItemsInCart}
          cartValue={<OrderWithItemsAmount amount={numberOfItemsInCart} />}
          displayCart={displayCart}
          orderSummary={orderSummary}
          personnalLink={personnalLink}
          backToBeginning={toBeginningOfOrder}
          setDeliveryAddress={setDeliveryAddress}
          setNav={setNav}
          pharmacyChoice={pharmacyChoice}
        />
      );
    // case steps.hasAPrescription:
    //   return (
    //     <HasAPrescription
    //       prevStep={prevStep}
    //       toInfoPageCommandWithIdcard = { toInfoPageCommandWithIdcard}//toOrderWithoutPrescription={toOrderWithoutPrescription}///this
    //       toInfoPageCommandByDoctor = {toInfoPageCommandByDoctor}
    //       toOrderWithElectronicPrescription= { toOrderWithElectronicPrescription }
    //       toOrderWithPaperPrescription={toOrderWithPaperPrescription}
    //       showCart={() => setDisplayCart(true)}
    //       numberOfItemsInCart={numberOfItemsInCart}
    //       cartValue={<OrderWithItemsAmount amount={numberOfItemsInCart} />}
    //       displayCart={displayCart}
    //       orderSummary={orderSummary}
    //       personnalLink={personnalLink}
    //       backToBeginning={toBeginningOfOrder}
    //       setDeliveryAddress={setDeliveryAddress}
    //       setNav={setNav}
    //       //addPrescriptionIntoBracket = {addPrescriptionIntoBracket}
    //     />
    //   );
    case steps.HasPrescriptionInIdCard:
      return (
        <HasPrescriptionInIdCard
          step={step}
          noPrescriptions={noPrescriptions}
          backToPrescriptionChoice={toChooseYourCommandType}
          backToChooseYourCommandType={toChooseYourCommandType}
          setNoPrescriptions={setNoPrescriptions}
          removeItem={removeCartItemFromNoPrescriptions}
          // Used to display order Summary and the button to open it in the actual component
          cartValue={<OrderWithItemsAmount amount={numberOfItemsInCart} />}
          showCart={() => setDisplayCart(true)}
          orderSummary={orderSummary}
          displayCart={displayCart}
          setNav={setNav}
        />
      );
    case steps.personnalDetails:
      return (
        <PersonnalDetails
          prevStep={toChooseYourCommandType}
          nextStep={nextStep}
          readAndSign={readAndSign}
          setReadAndSign={setReadAndSign}
          pharmacyCall={pharmacyCall}
          setPharmacyCall={needACall}
          setUser={setUser}
          user={user}
          deliveryInfo={deliveryInfo}
          setDeliveryInfo={setDeliveryInfo}
          deliveryAddress={deliveryAddress}
          pharmacyChoice={pharmacyChoice}
          questionToPharmacien={questionToPharmacien}
          noPrescriptions={noPrescriptions}
          electronicPrescriptions={electronicPrescriptions}
          paperPrescriptions={paperPrescriptions}
          setNav={setNav}
          setOrderHash={setOrderHash}

          //Mandataire
          mandataire={mandataire}
          setMandataire={setMandataire}
          MandataireObject={mandataire}
          mandataireChoice = {mandataireChoice}
          setMandataireObject={setMandataire}
          isPrescriptionIdCard = {isPrescriptionIdCard}
          isPrescriptionMedecin = {isPrescriptionMedecin}
          valMandataire = {mandatValue}
          booleanForMandatSelection = {booleanForMandatSelection}
          setBooleanForMandatSelection={setBooleanForMandatSelection}
          setMandataireChoice={handleMandataireChoice}
          //fin Mandataire
          toChooseMandataire = {toChooseMandataire}
        />
      );
      case steps.toChooseMandataire:
        return(
          <MandataireSelection
          prevStep={prevStepToPersonnalDetails}
          prevStepM = {prevStepM}
          setMandataireChoice={setMandataireChoice}
          mandataireChoice={mandataireChoice}
          deliveryAddress={deliveryAddress}
          mandataireAddress = {mandataireChoice.address}
          setNav={setNav}
          valueMandataire = {mandatValue}
          booleanForMandatSelection = {booleanForMandatSelection}
          showCardBenev={() => setDisplayCardBenev(true)}
          displayCardBenev={displayCardBenev}
          benevoleSummary={benevoleSummary}

          />
        )
    case steps.success:
      return (
        <Success backToMenu={refresh} setNav={setNav} orderHash={orderHash} />
      );
    case steps.pharmacyNotEncodedYet:
      return (
        <PharmacyNotEncodedYet
          nextStep={nextStep}
          backToPharmacyChoice={toPharmacyChoice}
          pharmacyChoice={pharmacyChoice}
          setPharmacyChoice={setPharmacyChoice}
          setNav={setNav}
        />
      );
    case steps.contactNewPharmacy:
      return (
        <ContactNewPharmacy
          backToMenu={refresh}
          setUser={setUser}
          user={user}
          // errorRef={errorRef}
          setNav={setNav}
        />
      );
    case steps.hasNoPrescription:
      return (
        <NoPrescription
          step={step}
          noPrescriptions={noPrescriptions}
          // backToPrescriptionChoice={toChooseYourCommandType}
          backToChooseYourCommandType={toChooseYourCommandType}
          setNoPrescriptions={setNoPrescriptions}
          removeItem={removeCartItemFromNoPrescriptions}
          // Used to display order Summary and the button to open it in the actual component
          cartValue={<OrderWithItemsAmount amount={numberOfItemsInCart} />}
          showCart={() => setDisplayCart(true)}
          orderSummary={orderSummary}
          displayCart={displayCart}
          setNav={setNav}
        />
      );
    case steps.hasElectronicPrescription:
      return (
        <HasElectronicPrescription
          step={step}
          electronicPrescriptions={electronicPrescriptions}
          setElectronicPrescriptions={setElectronicPrescriptions}
          removeItem={removeCartItemFromElectronicPrescriptions}
          backToChooseYourCommandType={toChooseYourCommandType}
          // Used to display order Summary and the button to open it in  the actual component
          cartValue={<OrderWithItemsAmount amount={numberOfItemsInCart} />}
          showCart={() => setDisplayCart(true)}
          orderSummary={orderSummary}
          displayCart={displayCart}
          setNav={setNav}
        />
      );
    case steps.hasPaperPrescription:
      return (
        <HasPaperPrescription
          step={step}
          paperPrescriptions={paperPrescriptions}
          setPaperPrescriptions={setPaperPrescriptions}
          removeItem={removeCartItemFromPaperPrescriptions}
          backToPrescriptionChoice={toChooseYourCommandType}
          // Used to display order Summary and the button to open it in the actual component
          cartValue={<OrderWithItemsAmount amount={numberOfItemsInCart} />}
          showCart={() => setDisplayCart(true)}
          orderSummary={orderSummary}
          displayCart={displayCart}
          setNav={setNav}
        />
      );
    // if enters in a wrong step, he is redirected to this h1
    default:
      return <h1>not found</h1>;
  }
}
