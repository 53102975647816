import React, { useContext } from "react"
import { Link, useHistory } from "react-router-dom"

import { Url } from "../../routes/Routes"

// import { HamburgerSqueeze } from 'react-animated-burgers'

//images
import LogoBlueBg from "../../assets/img/logo-blue-bg.png"
import LogoWhiteBg from "../../assets/img/logo-white-bg.png"

//scss
import "../../style/components/navbar.scss"

// Servic
import { UserContext } from "../../Services/UserContext"

import { logout } from "../../Backend/user"

// Components
import Badge from "../Badges/Badge"
import Logout from "../Images/Logout"

/**
 *
 * @param {{
 * nav: string
 * }} param0
 *
 * @author Yann Lauwers
 */

export default function Navbar({ color }) {
  let history = useHistory()
  const { user } = useContext(UserContext)

  const img = (
    <img
      src={color === "primary" ? LogoWhiteBg : LogoBlueBg}
      alt="Smile logo"
      className="h-logoSm"
    />
  )

  const logOut = async () => {
    await logout()
    return history.push(Url.signin)
  }

  // const [isActive, setIsActive] = React.useState(false)
  // const container = React.createRef()

  // const handleClickInside = () => {
  //   setIsActive(false)
  // }

  // const toggleButton = () => {
  //   setIsActive(!isActive)
  // }
  const pharmacyLoggedInInfo = (
    <div className="flex align-middle">
      <Link to={`/my-pharmacy/${user?.pharmacy?.id}`}>
        <Badge>{user?.pharmacy?.name}</Badge>
      </Link>
      <button
        className="ml-badgeToLogout focus:outline-none text-secondary"
        type="button"
        onClick={() => logOut()}
      >
        <Logout />
      </button>
    </div>
  )

  const navColor = color === "primary" ? " bg-secondary" : " bg-primary"

  return (
    // I removed the style navigation bar div className={``} 
    // flex flex-shrink-0 justify-center h-navbarHeight ${navColor}
    <div className={``}>
      <div className="relative flex items-center justify-between mx-auto w-mobileNavW tablet:w-tabletNavW tablet:min-w-tabletNavW laptop:w-laptopNavW laptop:min-w-laptopNavW">
       
        {user ? pharmacyLoggedInInfo : null}
        {/* <div className="inline-block" ref={container}>
          <button
            onClick={toggleButton}
            className="flex items-center p-navbarMenuPadding outline-none bg-secondary border-formSelect rounded-formSelect border-primary"
             ref={container}
          >
            <div className="pr-paddingRMenuBtn">
              <HamburgerSqueeze
                buttonWidth={16}
                className="override outline-white-important"
                isActive={isActive}
                buttonColor="white"
                barColor="#1B3256"
              />
            </div>
            <PrimaryText
              text="Menu"
            />
          </button>
          {isActive && (
          <div className="z-10 bg-primary w-screen min-h-FullMinusNavbar text-center absolute top-full right-0 min-w-max">
            <ul>
              <li className="text-secondary pt-3"><Link to={Url.root} onClick={handleClickInside} className="leading-navbarEl font-sans text-header1">Home</Link></li>
              <li className="text-secondary pt-3"><Link to={Url.signin} onClick={handleClickInside} className="leading-navbarEl font-sans text-header1">Se connecter</Link></li>
              <li className="text-secondary pt-3"><Link to={Url.signup} onClick={handleClickInside} className="leading-navbarEl font-sans text-header1">S'inscrire</Link></li>
            </ul>
          </div>
          )}
        </div> */}
      </div>
    </div>
  )
}
