import { createContext } from "react"
import { isLoggedIn, logout } from "../Backend/user"
import { Url } from "../routes/Routes"

export const UserContext = createContext(null)

const userLogout = async () => {
  await logout()
  return (window.location.href = Url.signin)
}

export const checkIsConnect = async () => {
  try {
    const user = await isLoggedIn()
    if (!user) userLogout()
  } catch (err) {
    userLogout()
  }
}
