import React from "react"
import OrderBag from "../Images/OrderBag"

/**
 *
 * @param {{
 * amount: Number
 * }} param0
 * @returns
 *
 * @author Yann Lauwers
 */

export default function OrderWithItemsAmount({ amount }) {
  return (
    <div className="flex items-center">
      <OrderBag strokeColor="secondary" iconSize="22" />
      <p className="font-textFontFamily font-roundedBtn-FontWeight ml-spaceBetweenCartAndText text-btn-FontSize text-secondary leading-roundedBtn">
        &nbsp;Mon panier ({amount})
      </p>
    </div>
  )
}
