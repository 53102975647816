/**
 * Compute distance (bird's flight) between 2 addresses
 * @author Malo Misson
 *
 * @param {AddressDTO} address1
 * @param {AddressDTO} address2
 * @returns {Number} distance exprimed in meters between the two addresses
 *
 * @throws {Error} coordinate_missing
 */
export const computeDistance = (address1, address2) => {
  if (!address1.latitude || !address1.longitude || !address2.latitude || !address2.longitude)
    throw new Error("coordinate_missing")

  const R = 6371e3 // metres
  const φ1 = (address1.latitude * Math.PI) / 180 // φ, λ in radians
  const φ2 = (address2.latitude * Math.PI) / 180
  const Δφ = ((address2.latitude - address1.latitude) * Math.PI) / 180
  const Δλ = ((address2.longitude - address1.longitude) * Math.PI) / 180

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  const d = R * c // in metres
  return parseInt(d)
}

export const computeDistanceMandataire = (address1, address2Lat, address2Long) => {
  if (!address1.latitude || !address1.longitude || !address2Lat || !address2Long)
    throw new Error("coordinate_missing")

  const R = 6371e3 // metres
  const φ1 = (address1.latitude * Math.PI) / 180 // φ, λ in radians
  const φ2 = (address2Lat * Math.PI) / 180
  const Δφ = ((address2Lat - address1.latitude) * Math.PI) / 180
  const Δλ = ((address2Long - address1.longitude) * Math.PI) / 180

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  const d = R * c // in metres
  return parseFloat(d/1000).toFixed(2)
}

/**
 * Format address street level
 * @author Malo Misson
 * @param {AddressDTO} address
 * @returns {string} eg: 31 Rue du commerce
 */
export const formatAddressStreetLine = address => {
  const num = address?.streetNumber ? `${address?.streetNumber} ` : ""
  return `${num}${address?.street}`
}

/**
 * Format address city level
 *
 * @param {AddressDTO} address
 * @returns {string} eg: 1000 Brussels
 *
 * @author Malo Misson
 */
export const formatAddressCityLine = address => {
  if (address?.postalCode) {
    return `${address?.postalCode} ${address?.city}`
  } else {
    return `${address?.city}`
  }
}

/**
 * Format address
 * @param {AddressDTO} address
 * @returns {string} eg: 31 Rue du commerce, 1000 Brussels
 *
 *
 * @author Malo Misson
 */
export const formatAddress = address => {
  if (address) {
    return `${formatAddressStreetLine(address)}, ${formatAddressCityLine(address)}`
  } else return "Loading"
}
