import React from "react"

import Label from "../DefineTagClass/Label"
import PrimaryText from "../DefineTagClass/PrimaryText"

/**
 *
 * @param {{
 * img: string,
 * alt: string,
 * label: string,
 * isGreen: boolean,
 * isCart: boolean,
 * line1: string,
 * line2: string,
 * }} param0
 */

// This card is used in order summary for the address and the pharmacy informations.
// It has no real name so here is card2

export default function PharmacyCard2({ img, label, isGreen, isCart, line1, line2 }) {
  return (
    <div className="flex items-start">
      {img}
      <div className="ml-cardTitleToText">
        <Label isGreen={isGreen} isCart={isCart}>
          {label}
        </Label>
        <PrimaryText>{line1}</PrimaryText>
        <PrimaryText>{line2}</PrimaryText>
      </div>
    </div>
  )
}
