import React from "react"

/**
 *
 * @param {{
 *  text: string,
 *  invert: boolean,
 * }} param0
 *
 * @author Lauwers Yann
 */

export default function HeavyText({ children, invert = false }) {
  let classes =
    "text-left font-textFontFamily font-inlineBtnHeavy-FontWeight text-inlineBtn-FontSize leading-inlineBtn"
  classes += invert ? " text-secondary" : " text-primary"

  return (
    <div>
      <div className={classes}>{children}</div>
    </div>
  )
}
