import React from "react"

/**
 *
 * @param {{
 * text: string,
 * invert: boolean,
 * isCart: boolean,
 * isGreen: boolean,
 * hidden: boolean,
 * htmlFor: string,
 * }} param0
 *
 * @author Lauwers Yann
 */

export default function Label({
  children,
  invert = false,
  isCart = false,
  isGreen = false,
  isCentered = false,
  hidden,
  htmlFor,
  ...props
}) {
  let classes =
    "font-textFontFamily font-label-FontWeight text-label-FontSize tablet:text-tablet-label-FontSize laptop:text-laptop-label-FontSize leading-label tablet:leading-tablet-label laptop:leading-laptop-label"

  classes += invert ? " text-secondary" : " text-primary group-hover:text-secondary"
  classes += isGreen ? " text-ternary" : " text-primary group-hover:text-secondary"

  let classes2 = ""
  classes2 += isCart ? "" : "mb-labelToInput"
  classes2 += isCentered ? " flex items-center" : ""

  return (
    <div className={classes2} hidden={hidden}>
      <label htmlFor={htmlFor} className={classes} {...props}>
        {children}
      </label>
    </div>
  )
}
