export default function Recap() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 449.77 559.85"
      className="fill-current text-primary"
    >
      <path
        d="M495.16,255.65H344.55a19.55,19.55,0,1,1,0-39.09H495.16a19.55,19.55,0,1,1,0,39.09Z"
        transform="translate(-175.12 -120.08)"
      />
      <path
        d="M495.16,336.05H344.55a19.55,19.55,0,1,1,0-39.1H495.16a19.55,19.55,0,1,1,0,39.1Z"
        transform="translate(-175.12 -120.08)"
      />
      <path
        d="M461.07,414.78H344.55a19.55,19.55,0,0,1,0-39.1H461.07a19.55,19.55,0,0,1,0,39.1Z"
        transform="translate(-175.12 -120.08)"
      />
      <path
        d="M559.69,679.92H251.37a76.34,76.34,0,0,1-76.25-76.25V549.2A39.9,39.9,0,0,1,215,509.34h19.14V185.26a65.26,65.26,0,0,1,65.19-65.18H559.69a65.26,65.26,0,0,1,65.19,65.18V614.74A65.26,65.26,0,0,1,559.69,679.92ZM273.2,640.83H559.69a26.13,26.13,0,0,0,26.1-26.09V185.26a26.13,26.13,0,0,0-26.1-26.09H299.3a26.13,26.13,0,0,0-26.1,26.09ZM215,548.44a.76.76,0,0,0-.76.76v54.47a37.2,37.2,0,0,0,19.9,32.9V548.44Z"
        transform="translate(-175.12 -120.08)"
      />
    </svg>
  )
}
