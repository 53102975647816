import { PharmacyDTO } from "./PharmacyDTO"
import { MandataireDTO } from "./MandataireDTO"

export class UserDTO {
  email: String
  idNumber: String
  idNumCard: String
  lastname: String
  name: String
  phone: String
  pharmacy: PharmacyDTO
  status: String
  Mandataire: MandataireDTO

  emailMandat: String;
  nameMandat: String;
  lastnameMandat: String;
  idNumberMandat: String;
  phoneMandat: String ;
  nationalRegisterNumberMandat: String
  isPrescriptionIdCard: String
  isPrescriptionMedecin: String
}
