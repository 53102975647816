import React from "react"

/**
 *
 * @param {{
 * strokeColor: string,
 * iconSize: strin,
 * }} param0
 * @returns
 */

export default function OrderBag({ strokeColor, iconSize }) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 330.37 333.33"
      width={iconSize}
      height={iconSize}
      className="fill-none"
    >
      <g id="ic-ecommerce-basket-default-2">
        <path
          id="Tracé_1755"
          data-name="Tracé 1755"
          className={`stroke-current stroke-edit text-${strokeColor}`}
          d="M303.92,558.16a43.2,43.2,0,0,1-43-39.34L243.33,325.54a4.33,4.33,0,0,1,3.92-4.7l.39,0H552.36a4.32,4.32,0,0,1,4.32,4.32c0,.13,0,.26,0,.4L539,518.82A43.2,43.2,0,0,1,496,558.12Z"
          transform="translate(-234.82 -233.34)"
        />
        <path
          id="Tracé_1756"
          data-name="Tracé 1756"
          className={`stroke-current stroke-edit text-${strokeColor}`}
          d="M318.26,363.21V323.6A81.76,81.76,0,0,1,400,241.84h0a81.77,81.77,0,0,1,81.77,81.76v39.61"
          transform="translate(-234.82 -233.34)"
        />
      </g>
    </svg>
  )
}
