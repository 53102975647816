import { GET, POST, PUT, PUTFile } from "./request"
import { OrderDTO } from "../Models/OrderDTO"
import { formatAddress } from "../Services/AddressService"
import { UserDTO } from "../Models/UserDTO"
import { MandataireDTO } from "../Models/MandataireDTO"
import { AddressDTO } from "../Models/AddressDTO"
import { OrderErrorType, OrderException } from "./Exceptions/OrderException"

/**
 * Convert a Json from backend to OrderDTO
 * @author Malo Misson
 * @param {Object} obj
 */
const formatOrderBody = obj => {
  const order = new OrderDTO()
  order.orderID = obj.orderId
  order.orderHash = obj.orderHash
  order.posology = obj.notice
  order.readPosology = obj.readNotice
  order.paid = obj.paid

  if (obj.prescription) {
    order.prescription = {}
    order.prescription.digital = obj.prescriptionDigital
    order.prescription.medicines = obj.medicines
    order.prescription.prescriptionFiles = obj.prescriptionFiles
  }

  if (obj.payment) {
    order.payment = {}
    order.payment.link = obj.payment.paymentLink
    order.payment.value = obj.payment.value
    order.payment.items = obj.payment.items
    order.payment.status = obj.payment.status
  }

  if (obj.user) {
    order.user = new UserDTO()
    order.user.name = obj.user.name
    order.user.lastname = obj.user.lastname
    order.user.email = obj.user.email
    order.user.idNumCard = obj.user.idNumber
  }

  if (obj.mandataire) {
    order.mandataire = new MandataireDTO()
    order.mandataire.name = obj.mandataire.name
    order.mandataire.lastname = obj.mandataire.lastname
    order.mandataire.email = obj.mandataire.email
    order.mandataire.phone = obj.mandataire.phone
  }

  if (obj.address) {
    order.address = new AddressDTO()
    order.address.streetNumber = obj.address.streetNumber
    order.address.street = obj.address.street
    order.address.city = obj.address.city
    order.address.postalCode = obj.address.postalCode
  }

  order.wantToBeCalled = obj.wantToBeCall
  order.comment = obj.comment

  order.deliverySchedul = obj.deliveryScheduled
  order.deliveryInfo = obj.deliveryInformation

  if (obj.pharmacy) {
    order.pharmacy = {
      address: obj.pharmacy.address,
      id: obj.pharmacy.apbId,
      name: obj.pharmacy.name,
    }
  }

  return order
}

/**
 * Create an order
 * @author Malo Misson
 * @async
 *
 * @param {OrderDTO} order Order
 * @param {boolean} eula has user accepted all terms and conditions
 *
 * @throws {Error} Eula_Not_Signed
 * @throws {Error} Nothing_Ordered
 *
 *
 * @throws {Error} Name_missing
 * @throws {Error} Lastname_missing
 * @throws {Error} Email_missing
 * @throws {Error} Address_missing
 * @throws {Error} Address_NotAcceptable
 * @throws {Error} Order_Empty
 * @throws {Error} Eula_mustBeAccepted
 * @throws {Error} idNumber_required
 * @throws {Error} Phone_missing
 * @returns {Promise<OrderDTO>}
 */
const create = async (order, eula) => {
  if (!eula) throw new Error("Eula_Not_Signed")
  if (
    !order.prescription.digital &&
    !order.prescription.paper &&
    !order.prescription.medicines.length
  )
    throw new Error("Order_Empty")
  if (order.prescription.paper && order.prescription.paper.length && !order.user.idNumber)
    throw new Error("idNumber_required")
  if (order.wantToBeCalled && !order.user.phone) throw new Error("Phone_missing")


  const res = await PUTFile("order/", {
    name: order.user.name,
    lastname: order.user.lastname,
    email: order.user.email,
    phone: order.user.phone,
    idNumCard: order.user.idNumCard,

    // // ajout des données du mandataire => vers le back
    emailMandat: order.mandataire.email,
    phoneMandat: order.mandataire.phone,
    nameMandat: order.mandataire.name,
    lastnameMandat: order.mandataire.lastname,
    idNumberMandat: order.mandataire.idNumber,
    idNumber: order.user.idNumber,
    //mandataire: order.mandataire,
    pharmacyAPBId: order.pharmacy ? order.pharmacy.id : undefined,
    digitalPrescription: order.prescription.digital,
    "prescriptionFiles[]": order.prescription.paper,
    medicines: order.prescription.medicines,
    address: formatAddress(order.address),
    eula: eula,
    wantToBeCall: order.wantToBeCalled,
    comment: order.comment,
    deliveryInformation: order.deliveryInfo,
    hasMandataire: order.hasMandataire,
    isPrescriptionIdCard: order.isPrescriptionIdCard,
    isPrescriptionMedecin: order.isPrescriptionMedecin,
  })

  switch (res.status) {
    case 200: {
      const result = await res.json() 
      return formatOrderBody(result)
    }
    case 400:
    case 406:
    default: {
      const errorValue = await res.text()
      throw new Error(errorValue)
    }
  }
}

/**
 * Create an order from a pharmacy
 * @author Yann Lauwers
 * @async
 *
 * @param {OrderFromPharmacyDTO} order Order
 *
 * @returns {Promise<OrderDTO>}
 */
const createOrderFromPharmacy = async order => {
  const res = await PUT("order/pharma", {
    name: order.user.firstName,
    lastname: order.user.lastName,
    email: order.user.email,
    phone: order.user.phone,
    address: order.address,
    price: order.payment.value,
    pickup: order.deliverySchedul,
    comment: order.deliveryInfo,
    offerDelivery: order.pharmacienPaysForDeliveryFee,
    retrievePrescription: order.deliveryGuyMustGetPaperPrescription,
    notice: order.notice,
  })

  switch (res.status) {
    case 200: {
      const result = await res.json()
      return result
    }
    case 400:
    case 406:
    default: {
      const errorValue = await res.text()
      throw new Error(errorValue)
    }
  }
}

/**
 * Give order related to the hashOrder
 * @param {String} hash
 * @returns {OrderDTO} order
 */
const getOrderByHash = async hash => {
  const res = await GET(`order/${hash}`)
  if (res.status >= 200 && res.status < 300) {
    const result = await res.json()

    return formatOrderBody(result)
  } else {
    switch (res.status) {
      case 404:
        throw new Error(OrderErrorType.Not_found)
      default:
        throw new Error(`Error ${res}`)
    }
  }
}

/**
 *
 * @param {String} hash Order Hash
 * @param {Boolean} read Has read the posology
 * @param {String} url Url to redirect user after payment process
 */
const setReadPosology = async (hash, read, url) => {
  const res = await POST(`order/hasreadNotice/${hash}`, {
    read,
    link: url,
  })
  if (res.status >= 200 && res.status < 300) {
    return formatOrderBody(await res.json())
  } else {
    switch (res.status) {
      case 403:
        switch (res.body) {
          case "Price_missing":
            throw new OrderException(OrderErrorType.Price_missing)
          case "Already_paid":
            throw new OrderException(OrderErrorType.Already_Paid)
          default:
            break
        }
        break
      case 404: {
        throw new OrderException(OrderErrorType.Not_found)
      }
      case 406: {
        throw new Error(OrderErrorType.Notice_not_read)
      }

      default: {
        throw new Error(`Error ${res}`)
      }
    }
  }
}

export const OrderAPI = {
  create,
  getOrderByHash,
  setReadPosology,
  createOrderFromPharmacy,
}
