import React, { useState } from "react"

// import { OrderDTO } from "../../Models/OrderDTO"
import { OrderFromPharmacyDTO } from "../../Models/OrderFromPharmacyDTO"

import { OrderAPI } from "../../Backend/order"

import ClientDetails from "./1ClientDetails"
import OrderSummary from "./2OrderSummary"
import Success from "./3Success"

export default function PharmacyLoggedInForm({ setNav, user }) {
  const [currentStep, setCurrentStep] = useState(1)
  const [order, setOrder] = useState(new OrderFromPharmacyDTO())

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      await OrderAPI.createOrderFromPharmacy(order)
      next()
    } catch (error) {
      console.error(error)
    }
  }

  const next = () => {
    let step = currentStep
    step = step >= 2 ? 3 : step + 1
    setCurrentStep(step)
  }

  const prev = () => {
    let step = currentStep
    step = step <= 1 ? 1 : step - 1
    setCurrentStep(step)
  }

  return (
    <form onSubmit={handleSubmit}>
      <ClientDetails
        setNav={setNav}
        currentStep={currentStep}
        next={next}
        setOrder={setOrder}
        order={order}
        userLoggedIn={user}
      />
      <OrderSummary
        setNav={setNav}
        currentStep={currentStep}
        next={handleSubmit}
        prev={prev}
        order={order}
      />
      <Success setNav={setNav} currentStep={currentStep} />
    </form>
  )
}
