import React, { useRef } from "react"

import { DeliveryDetails, OrderDetails, Posologie } from "./ResumeSections"

/**
 *
 * @param {{
 * order: object,
 * }} param0
 *
 * @author Lauwers Yann
 */

export default function Resume({ order }) {
  const divRef = useRef(null)

  const scrollToDiv = () => {
    window.scrollTo(0, divRef.current.offsetTop)
  }
  return (
    <div className="border-b-formSelect border-t-formSelect tablet:border-l-formSelect tablet:border-r-formSelect border-primary my-20">
      <DeliveryDetails order={order} divRef={divRef} scrollToDiv={scrollToDiv} />
      <OrderDetails order={order} divRef={divRef} scrollToDiv={scrollToDiv} />
      <Posologie order={order} divRef={divRef} scrollToDiv={scrollToDiv} />
    </div>
  )
}
