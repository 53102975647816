import React, { useEffect, useRef, useState } from "react";

import ProfileCard from "../../components/Card/ProfileCard";
import TranslateUp from "../../components/Transitions/TranslateUp";
import FullyRoundedBackBtn from "../../components/Btns/FullyRoundedBackBtn";
import H1 from "../../components/DefineTagClass/H1";
import LoadingSpinner from "../../components/Loading";
import {
  computeDistance,
  formatAddress,
  computeDistanceMandataire,
  formatAddressStreetLine,
  formatAddressCityLine,
} from "../../Services/AddressService";
import { colorMode } from "../../Config/colorMode";
import profile03 from "../../components/Images/profile03.jpg";

// back-end of front
import { MandataireAPI } from "../../Backend/mandataire";
import ImageCard from "../../components/Card/ImageCard";
import Information from "../../components/Information/Information";

/**
 *
 * @param {{
 * setNav: function
 * }} param0
 *
 * @author IDI SOULE
 */
export default function MandataireSelection({
  prevStep,prevStepM,
  setNav,
  mandataires,
  deliveryAddress,
  mandataireChoice,
  setMandataireChoice,
  booleanForMandatSelection,
  showCardBenev,
  displayCardBenev,
  benevoleSummary,
  text = "pour choisir le bénévole qui vous livrera votre commande, veuillez cliquer sur la case de celui-ci et enfin confirmez votre choix. vous serez redirigé vers le formulaire qui sera pré-rempli pour vous.",
}) {
  const [state, setState] = useState(1);
  const [mandatairesReachable, setMandatairesReachable] = useState([]);
  const [hide, setHide] = useState(false);
  const [valMandataire, setValueMandataire] = useState(false);

  const back = () => {
    prevStep();
    //setValueMandataire(false)
    booleanForMandatSelection = false;
    LoadReachableMandataires()
  };

  const backWithMandataire = () => {
    prevStepM();
    //setValueMandataire(true)
    booleanForMandatSelection = true;
    LoadReachableMandataires()
  };
  useEffect(() => {
    setNav(colorMode.blueBg);
    LoadReachableMandataires();
  }, []);

  const status = {
    loading: 1,
    reachableMandataires: 2,
    myMandataireNotReachable: 3,
    noReachableMandataires: 4,
    MandataireChoiceDisplay: 5,
  };

  const km01 = 3;
  const sortMandataireByDistance = (mandataire1, mandataire2) => {
      const distA = computeDistanceMandataire(deliveryAddress, mandataire1?.fields.latitude, mandataire1?.fields.longitude);
      const distB = computeDistanceMandataire(deliveryAddress, mandataire2?.fields.latitude, mandataire2?.fields.longitude);
      if (distA < distB) {
        return -1;
      }
      if (distA > distB) {
        return 1;
      }
      return 0;
  };

  function arrayRemove(arr, value) {
    return arr.filter(function (benevole) {
      return benevole.contract !== value || benevole.id === mandataireChoice.id;
    });
  }

  const handleStatus = (mandataires = mandatairesReachable) => {
    if (hide) {
      setHide(false);
    }
    if (mandataireChoice.name) {
      let result = mandataires.filter(
        (mandat) => mandat.id === mandataireChoice.id
      );
      let indexResult = mandataires.indexOf(result[0]);
      if (!mandataires.length) {
        setState(status.noReachableMandataires);
      } else if (mandataires.length >= 1) {
        if (result.length) {
          mandataireChoice.address = result[0].address;
          mandataires.splice(indexResult, 1);
          setState(status.reachableMandataires);
        } else {
          setHide(true);
          setState(status.myMandataireNotReachable);
        }
      }
    } else {
      if (mandataires.length) {
        setState(status.reachableMandataires);
      } else {
        setState(status.noReachableMandataires);
      }
    }
  };


  const LoadReachableMandataires = async () => {
    setState(status.loading);
    let address = formatAddress(deliveryAddress);
    try {
      mandataires = await MandataireAPI.getMandataires(address);
      const mandataireSortedByDistance = mandataires?.sort(sortMandataireByDistance)
      setMandatairesReachable(mandataireSortedByDistance);
      handleStatus(mandataireSortedByDistance);
    } catch (error) {
      console.error(error);
    }
  };

  const ReachableMandataires = () => {
    return (
      <div className="flex flex-wrap items-center ml-30">
        {/* <LoadReachableMandataires /> */}

        {mandatairesReachable.map((benevole, i) => {
          return (
            <button
              key={i}
              className="focus:outline-none"
              //onClick={showCardBenev}

              onClick={() => {
                actualizeMandataireChoice(benevole);
                showCardBenev();
              }}
            >
              <ProfileCard
                key={i}
                name={benevole?.fields.Nom}
                lastname={benevole?.fields['Prénom']}
                phone={benevole?.phone}
                streetLine={benevole?.fields.Adresse}
                image={{ data: benevole?.fields.Photo[0].thumbnails.large }}
                distance={
                  computeDistanceMandataire(
                    deliveryAddress,
                    parseFloat(benevole?.fields.latitude),
                    parseFloat(benevole?.fields.longitude)
                  ) + "km"
                }


              />
            </button>
          );
        })}
      </div>
    );
  };

  const ShowReachableMandataires = () => {
    switch (state) {
      case status.loading:
        return <LoadingSpinner isBlue />;
      case status.reachableMandataires:
        return <ReachableMandataires />;
      default:
        return <h1>defaut</h1>;
    }
  };

  const actualizeMandataireChoice = (mandataire) => {
    const format = {
      name: mandataire.fields.Nom,
      id: mandataire.id,
      addresse: mandataire.fields.address,
      email: mandataire.fields.email,
      idNumber: mandataire.fields.idNumber,
      lastname: mandataire.fields.Prénom,
      phone: mandataire.fields.téléphone,
      status: mandataire.fields.Description,
      streetLine: mandataire.fields.streetLine,
      cityLine: mandataire.fields.cityLine,
      //distance : String
      image: mandataire.fields.Photo[0].thumbnails.large,
    };
    setMandataireChoice(format);
    booleanForMandatSelection = true;

  };
  return (
    <>
      <TranslateUp in={displayCardBenev} renderedComponent={benevoleSummary} />
      <div className="title-container">
        <H1>Trouver un voisin solidaire</H1>
        <div className="mt-10 mb-20 ml-10 mr-10">
          <Information children={text} />
        </div>
      </div>
      <div className="ml-10">
        <div
          className="flex flex-wrap items-center ml-20"
          style={{ position: "row-flex" }}
        >
          <button
            className=""
            //onClick={() => actualizeMandataireChoice(mandataireChoice) , backWithMandataire}
            //onClick={showCardBenev}
          ></button>
          {}
          {<ShowReachableMandataires />}
        </div>
      </div>
      <div style={{ marginLeft: 750 }}>
        <FullyRoundedBackBtn
          action={back}
          name="retour"
          type="button"
          size={48}
        />{" "}
        &nbsp;
      </div>
    </>
  );
}
