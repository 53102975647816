import { GET } from "./request"

/**
 *
 * @param {String} filter search filter
 * @returns {Object}
 */
export const getMedecines = async medicine => {
  const response = await GET(`medicine?filter=${encodeURI(medicine)}`)
  try {
    const json = await response.json()
    return json
  } catch (error) {
    console.error(error)
  }
}
