import React from "react"

/**
 *
 * @param {{
 * children: string,
 * }} param0
 *
 * @author Lauwers Yann
 */

export default function Badge({ children }) {
  return (
    <div className="px-roundedBtnPaddingX py-roundedBtnPaddingY rounded-roundedBtn font-titleSecondaryFontFamily font-header3-FontWeight text-header3-FontSize leading-header3 shadow-btn text-primary bg-secondary">
      {children}
    </div>
  )
}
