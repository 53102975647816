import React from "react"

export default function Chevron({ size, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 11 6"
      width={size}
      height={size}
      className={`fill-current ${color}`}
    >
      <path
        d="M20.4,23.43a.47.47,0,0,1-.35-.15l-5-5a.5.5,0,0,1,0-.71.5.5,0,0,1,.7,0l4.65,4.65,4.65-4.65a.5.5,0,0,1,.7,0,.5.5,0,0,1,0,.71l-5,5A.47.47,0,0,1,20.4,23.43Z"
        transform="translate(-14.9 -17.43)"
      />
    </svg>
  )
}
