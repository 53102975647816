import React from "react"
import ReactDOM from "react-dom"
import "./index.scss"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import {I18nextProvider} from "react-i18next"
import i18n from "./i18n"
import ReactGA from "react-ga"

ReactGA.initialize("G-J7KYTXSYFC") // add your tracking id here.
ReactGA.pageview(window.location.pathname + window.location.search)

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <App/>
  </I18nextProvider>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
