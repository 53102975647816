import React, { useState, useEffect, useRef } from "react"

// Models
import { OrderDTO } from "../Models/OrderDTO"

// Services
import { formatAddressCityLine, formatAddressStreetLine } from "../Services/AddressService"

// Backend
import { OrderErrorType } from "../Backend/Exceptions/OrderException"
import { OrderAPI } from "../Backend/order"

import RoundedBtn from "../components/Btns/RoundedBtn"
import { PageContainer } from "../components/Container"
import HeavyText from "../components/DefineTagClass/HeavyText"
import PrimaryText from "../components/DefineTagClass/PrimaryText"
import ErrorMessage from "../components/ErrorMessage"
import { Header1, Header2 } from "../components/DefineTagClass/Header"
import CheckBox from "../components/Inputs/CheckBox"
import LoadingSpinner from "../components/Loading"
import Resume from "../components/OrderResume/Resume"

const helpText = (
  <p className="font-textFontFamily text-center font-checkBoxLabel-FontWeight text-checkBoxLabel-FontSize text-primary leading-checkBoxLabel">
    Un problème avec votre commande ?<br></br>
    Vous pouvez nous joindre au{" "}
    <strong>
      <a type="phone" href="tel:+3228089441">
        +32 2 808 94 41
      </a>
    </strong>{" "}
    ou via l’adresse{" "}
    <strong>
      <a type="mail" href="mailto:service@joinsmile.org">
        service@smile.io
      </a>
    </strong>{" "}
  </p>
)

const Posologie = () => {
  const [state, setState] = useState(1)
  const [sendingState, setSendingState] = useState(2)
  const [posologyMustBeRead, setPosologyMustBeRead] = useState(false)
  const errorRef = useRef(null)
  const [error, setError] = useState("")
  const [red, setRed] = useState(false)

  const [order, setOrder] = useState(new OrderDTO())

  const status = {
    loading: 1,
    posology: 2,
    order: 3,
    notFound: 4,
  }

  const statusSending = {
    on: 1,
    off: 2,
  }

  useEffect(() => {
    loadOrder()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getOrderHash = () => {
    return window.location.pathname.split("/")[2]
  }

  const loadOrder = async () => {
    try {
      const orderObj = await OrderAPI.getOrderByHash(getOrderHash())
      setOrder(orderObj)
      if (orderObj.payment.status === "paid") {
        setState(status.order)
      } else {
        if (!orderObj.posology) {
          if (orderObj.payment.link) {
            window.location.href = orderObj.payment.link
          } else {
            sendPosologie(true)
          }
        } else {
          setState(status.posology)
        }
      }
    } catch (err) {
      switch (err.type) {
        case OrderErrorType.Not_found:
          // TODO
          setState(status.notFound)
          break
        default:
          setState(status.notFound)
      }
    }
  }

  const handleChange = () => {
    const posologieAccepted = !posologyMustBeRead
    setPosologyMustBeRead(posologieAccepted)
    setOrder({ ...order, readPosology: posologieAccepted ? new Date() : undefined })
  }

  const posoError = "Vous devez confirmer avoir lu la posologie"

  const submitForm = e => {
    e.preventDefault()
    if (posologyMustBeRead) {
      setError("")
      setRed(false)
      sendPosologie()
    } else {
      setError(posoError)
      setRed(true)
    }
  }

  const sendPosologie = async (sign = order.readPosology) => {
    setSendingState(statusSending.on)
    try {
      const result = await OrderAPI.setReadPosology(
        getOrderHash(),
        sign !== undefined,
        `${window.location.href}`
      )
      window.location.href = result.payment.link
    } catch (err) {
      switch (err.type) {
        case OrderErrorType.Notice_not_read:
          setPosologyMustBeRead(true)
          break
        default:
      }
      setSendingState(statusSending.off)
    }
  }

  const PosologiePage = () => {
    let price = 0
    order.payment.items.map(item => {
      price += item.amount
      return price
    })
    return (
      <>
        <Header1>Paiement de votre commande</Header1>
        <Header2>
          Votre commande {order.orderID} a été traitée par {order.pharmacy.name}
        </Header2>
        <PageContainer>
          <div className="my-20">
            <ErrorMessage text={error} ref={errorRef} />
            <form onSubmit={submitForm} className="flex items-center flex-col">
              <div className="w-full flex flex-col">
                <div className="border-primary">
                  <HeavyText>
                    Voici les produits que vous avez commandés, accompagnés des commentaires
                    éventuels du pharmacien ou de leur posologie:
                  </HeavyText>
                  <br></br>
                  <div className="ml-marginLPosologie">
                    <PrimaryText isItalic>{order.posology}</PrimaryText>
                  </div>
                  <br></br>
                  <br></br>
                </div>
              </div>
              {order.payment.value !== "paid" ? (
                <>
                  <div className="mb-inputToSubmit w-full flex flex-col">
                    <HeavyText
                      text={`Une fois votre paiement de €${price} reçu, vous recevrez ces informations par email.\nVous devrez être présent pour la livraison à l'adresse que vous avez renseigné:`}
                    />
                    <br></br>
                    <div className="ml-marginLPosologie">
                      <PrimaryText>{formatAddressStreetLine(order.address)}</PrimaryText>
                      <PrimaryText>{formatAddressCityLine(order.address)}</PrimaryText>
                    </div>
                  </div>
                  <div className="mb-inputToSubmit w-full flex justify-start">
                    <CheckBox
                      type="checkbox"
                      action={handleChange}
                      checked={posologyMustBeRead}
                      isError={red}
                    >
                      J’ai bien lu les informations renseignées par le pharmacien, et je serai
                      présent à la livraison de ma commande.
                    </CheckBox>
                  </div>
                  <div className="mb-inputToSubmit">
                    <SendingPosologie />
                  </div>
                  {helpText}
                </>
              ) : (
                <></>
              )}
            </form>
          </div>
        </PageContainer>
      </>
    )
  }

  const ResumePage = () => {
    return (
      <>
        <Header1>Commande {order.orderID}</Header1>
        <PageContainer>
          <div className="w-full flex flex-col tablet:mx-auto tablet:w-tabletW laptop:mx-auto laptop:w-laptopW ">
            <Resume order={order} />
          </div>
        </PageContainer>
      </>
    )
  }

  const NotFoundPage = () => {
    return (
      <h1 className="leading-header1 text-center font-sans text-header1 text-primary mt-20">
        Commande non trouvée
      </h1>
    )
  }

  const ShowPage = () => {
    switch (state) {
      case status.loading:
        return (
          <div className="mt-20">
            <LoadingSpinner isBlue />
          </div>
        )
      case status.posology:
        return <PosologiePage />
      case status.notFound:
        return <NotFoundPage />
      case status.order:
        return <ResumePage />
      default:
        return <h1>defaut</h1>
    }
  }

  const SendingPosologie = () => {
    switch (sendingState) {
      case statusSending.on:
        return (
          <div className="mt-mobile-ContainerX">
            <LoadingSpinner isBlue />
          </div>
        )
      case statusSending.off:
        return (
          <RoundedBtn type="submit" name="sendPosologie" action={e => submitForm(e)}>
            Passer au paiement
          </RoundedBtn>
        )
      default:
        return <h1>default</h1>
    }
  }

  return (
    <div className="w-full flex-auto">
      <ShowPage />
    </div>
  )
}

export default Posologie
