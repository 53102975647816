export class AddressDTO {
  streetNumber: String
  street: String
  city: String
  postalCode: String
  country: String
  latitude: Number
  longitude: Number
  canDeliver: Boolean
}
