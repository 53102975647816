import React from "react"

import Label from "../DefineTagClass/Label"

export default function PharmacyCard1({ pharmacyName, streetLine, cityLine, distance,  }) {
  const distanceToFixedTwo = Math.round(distance / 10) / 100
  return (
    <div className="w-full group relative">
      <div className="absolute right-4 top-4 bg-ternary text-secondary rounded-lg py-1.5 px-2.5">
        <p className="font-titleFontFamily font-header2-FontWeight">{distanceToFixedTwo} km</p>
      </div>
      <div
        className="text-left py-cartItemInsidePaddingY px-cartItemInsidePaddingX
          border-t-cartItem border-l-cartItem border-r-cartItem rounded-t-pharmacyCard border-primary border-opacity-10
        "
      >
        <Label
          defaultValue={!pharmacyName.name ? pharmacyName : pharmacyName.name}
          isCart
          className="group-hover:text-ternary font-header2-FontWeight text-header2-FontSize font-titleFontFamily"
        >
          {!pharmacyName.name ? pharmacyName : pharmacyName.name}

        </Label>
      </div>
      <div
        className="pt-cartItemInsidePaddingY px-cartItemInsidePaddingX pb-cartItemInsidePaddingX
          border-b-cartItem border-l-cartItem border-r-cartItem rounded-b-pharmacyCard border-primary border-opacity-10 group-hover:shadow-md
        "
      >
        <p className="font-titleSecondaryFontFamily text-left">{streetLine}</p>

        <p className="font-titleSecondaryFontFamily text-left">{cityLine}</p>


      </div>
    </div>
  )
}
