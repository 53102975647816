import { useState } from "react"
import { FeedbackAPI } from "../../Backend/feedback"
import Label from "../DefineTagClass/Label"
import StarsFeedbackWithResult from "./StarsFeedbackWithResult"

export default function NotationWithStars({
  setIsRatedWithStars,
  feedbackData,
  setFeedbackData,
  endRange,
  feedbackKey,
  feedbackNote,
}) {
  const [feedbackValue, setFeedbackValue] = useState(0)

  const getValueInPercent = (value, end) => {
    const sliceTotal = 100 / end
    return sliceTotal * value
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setIsRatedWithStars(true)
    setFeedbackValue(parseInt(e.target.value))
    if (feedbackValue > 0 && feedbackValue <= endRange && feedbackData?.id) {
      const formatedFeedback = {
        id: feedbackData.id,
        score: getValueInPercent(e.target.value, endRange),
        key: feedbackKey,
        note: feedbackNote,
      }
      const updateFeedback = await FeedbackAPI.update(formatedFeedback)
      setFeedbackData(updateFeedback)
    } else {
      const formatedFeedback = {
        score: getValueInPercent(e.target.value, endRange),
        key: feedbackKey,
        note: feedbackNote,
      }
      const createFeedback = await FeedbackAPI.create(formatedFeedback)
      setFeedbackData(createFeedback)
    }
  }

  return (
    <form className="p-5 pb-7 w-full rounded-2xl bg-quatro space-y-5" onSubmit={handleSubmit}>
      <Label htmlFor={feedbackValue}>Quelle était votre expérience avec Smile ?</Label>
      <StarsFeedbackWithResult
        feedbackValue={feedbackValue}
        handleChange={handleSubmit}
        endRange={endRange}
      />
    </form>
  )
}
