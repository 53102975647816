/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 *   IMPORTANT: This file is used by the internal build
 *   script `extract-intl`, and must use CommonJS module syntax
 *   You CANNOT use import/export in this file.
 */

// CARREFULL IF YOU EDIT THIS FILE

import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"

import { initReactI18next } from "react-i18next"

import translationEN from "./public/locales/en/translation.json"
import translationFR from "./public/locales/fr/translation.json"
// DO NOT EDIT OR REMOVE THIS COMMENT! IT IS USED TO INSERT DATA IMPORT

// the translations
const resources = {
  en: {
    translation: translationEN,
    fullName: "English",
  },
  fr: {
    translation: translationFR,
    fullName: "Français",
  },
  // DO NOT EDIT OR REMOVE THIS COMMENT! IT IS USED TO INSERT NEW RESSOURCES
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    lng: "en",
    fallbackLng: "en", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
