import { PharmacyDTO } from "./PharmacyDTO"
import { UserDTO } from "./UserDTO"
export class OrderFromPharmacyDTO {
  orderID: string
  pharmacy: PharmacyDTO
  payment: {
    link: String,
    value: Number,
    items: ItemDTO[],
    status: "waiting" | "paid",
  }
  user: UserDTO
  address: String
  deliverySchedul: Date
  deliveryInfo: string
  pharmacienPaysForDeliveryFee: Boolean
  deliveryGuyMustGetPaperPrescription: Boolean
  notice: string

  constructor() {
    this.payment = {}
  }
}
