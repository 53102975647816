import React, { useEffect } from "react"

// Config
import { colorMode } from "../../Config/colorMode"

import RoundedBtn from "../../components/Btns/RoundedBtn"
import { Container, PageContainer } from "../../components/Container"
import H1 from "../../components/DefineTagClass/H1"
import H2 from "../../components/DefineTagClass/H2"
import H3 from "../../components/DefineTagClass/H3"
import PrimaryText from "../../components/DefineTagClass/PrimaryText"
import { useHistory } from "react-router"
import { Url } from "../../routes/Routes"

/**
 *
 * @param {{
 * currentStep: number,
 * setNav: function
 * }} param0
 * @returns
 *
 * @author Yann Lauwers
 */

export default function Success({ currentStep, setNav }) {
  let history = useHistory()

  useEffect(() => {
    setNav(colorMode.blueBg)
  }, [])

  if (currentStep !== 3) {
    return null
  }

  const handleReset = async () => {
    return history.push(Url.pharmacyLoggedInForm)
  }

  return (
    <>
      <H1>Merci pour votre commande</H1>
      <PageContainer>
        <div className="mb-20">
          <H2 isCentered green>
            Nous avons bien reçu votre commande !
          </H2>
          <PrimaryText textPosition="text-center">
            Vous devriez avoir reçu un email récapitulatif de la commande.
          </PrimaryText>
          <div className="mt-mobile-Container2YT mb-inputToSubmit">
            <H3 heavy isCentered>
              Nous vous communiquerons par email l'heure à laquelle le livreur viendra récupérer la
              commande.
            </H3>
          </div>
          <div className="flex items-center justify-center">
            <RoundedBtn action={handleReset} name="orderAgain" type="submit">
              Passer une nouvelle commande
            </RoundedBtn>
          </div>
        </div>
      </PageContainer>
    </>
  )
}
