import React, { useState, useEffect, useRef } from "react"
import LocalisationFill from "../components/Images/LocalisationFill"
import LoadingSpinner from "../components/Loading"
import "../style/components/googleSearchOutput.scss"
const fetch = require("node-fetch")

// access google api
const { REACT_APP_GOOGLE_API_KEY } = process?.env

let autoComplete
let address = {
  street: "",
  streetNumber: "",
  city: "",
  country: "",
  province: "",
  postalCode: "",
  latitude: "",
  longitude: "",
}

// wwork with places : https://developers.google.com/maps/documentation/javascript/places
// Nearby search : https://developers.google.com/maps/documentation/javascript/places#place_search_requests

// dynamically load JavaScript files in our html with callback when finished
const loadScript = (url, callback) => {
  let script = document.createElement("script") // create script tag
  script.type = "text/javascript"

  // when script state is ready and loaded or complete we will call callback
  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null
        callback()
      }
    }
  } else {
    script.onload = () => callback()
  }

  script.src = url // load by url
  document.getElementsByTagName("head")[0].appendChild(script) // append to head
}

// handle when the script is loaded we will assign autoCompleteRef with google maps place autocomplete
function handleScriptLoad(updateQuery, autoCompleteRef) {
  // assign autoComplete with Google maps place one time
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    // { types: ["establishment"] , componentRestrictions: { country: "be" } } //uncomment cette lgine pour avoir seuleemnt un business
    // { types: ["address"] , componentRestrictions: { country: "be" } } //uncomment cette ligne pour avoir une adresse
    { componentRestrictions: { country: "be" } } //uncomment cette ligne pour avoir une adresse ET/OU unn business
  )
  autoComplete.setFields(["geometry.location", "address_components", "formatted_address"]) // specify what properties we will get from API
  // add a listener to handle when the place is selected
  autoComplete.addListener("place_changed", () => handlePlaceSelect(updateQuery))
}

async function handlePlaceSelect(updateQuery) {
  const query = autoComplete.getPlace() // get place from google api
  updateQuery(query)
}

function SearchLocationInput({
  setDeliveryAddress,
  deliveryAddress,
  isError,
  autoFocus,
  getLocation,
}) {
  const [state, setState] = useState(2)
  const [query, setQuery] = useState("")
  const [input, setInput] = useState("")
  const autoCompleteRef = useRef(null)

  useEffect(() => {
    setDeliveryAddress(address)
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_API_KEY}&libraries=places`,
      () => handleScriptLoad(handleChange, autoCompleteRef)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = value => {
    if (value.formatted_address) {
      setQuery(value)
      setInput(autoCompleteRef.current.value)
    }
  }

  const loadingBtnStatus = {
    on: 1,
    off: 2,
  }

  const askDataToGoogle = async address => {
    try {
      const url = `https://maps.googleapis.com/maps/api/geocode/json?components=country:BE&address=${encodeURI(
        address
      )}&key=${REACT_APP_GOOGLE_API_KEY}`
      let response = await fetch(url)
      const json = await response.json()
      const data = json["results"][0]
      handleChange(data)
      return data
    } catch (error) {
      console.error(error)
    }
  }

  const showPosition = position => {
    const geolocation = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    }
    const myGeoloc = `${geolocation.lat}, ${geolocation.lng}`
    const data = askDataToGoogle(myGeoloc)
    data.then(function (datas) {
      autoCompleteRef.current.value = datas.formatted_address
    })
    setState(loadingBtnStatus.off)
  }

  const locationError = () => {
    window.alert("Geolocation is not supported by this browser.")
    setState(loadingBtnStatus.off)
  }

  // geolocation
  const geolocate = () => {
    setState(loadingBtnStatus.on)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, locationError)
    } else {
      locationError()
    }
  }

  const GetLocationBtn = () => {
    return (
      <button
        name="geolocate"
        type="button"
        onClick={geolocate}
        className="focus:outline-none bg-ternary cursor-btn rounded-roundedBtn border-btn border-ternary"
      >
        <LocalisationFill />
      </button>
    )
  }

  const GetLocation = () => {
    switch (state) {
      case loadingBtnStatus.off:
        return <GetLocationBtn />
      case loadingBtnStatus.on:
        return <LoadingSpinner />
      default:
        return <h1>default</h1>
    }
  }

  if (query) {
    // Set values back to initial state before getting new state and keep same street number for exemple
    address.street = ""
    address.streetNumber = ""
    address.city = ""
    address.province = ""
    address.country = ""
    address.postalCode = ""
    address.latitude = ""
    address.longitude = ""
    for (let i in query.address_components) {
      for (let j in query.address_components[i].types) {
        if (query.address_components[i].types[j] === "route") {
          address.street = query.address_components[i].long_name
        }
        if (query.address_components[i].types[j] === "street_number") {
          address.streetNumber = ""
          address.streetNumber = query.address_components[i].long_name
        }
        if (query.address_components[i].types[j] === "locality") {
          address.city = query.address_components[i].long_name
        }
        if (query.address_components[i].types[j] === "postal_code") {
          address.postalCode = query.address_components[i].long_name
        }
        if (query.address_components[i].types[j] === "country") {
          address.country = query.address_components[i].long_name
        }
        if (query.address_components[i].types[j] === "administrative_area_level_1") {
          address.province = query.address_components[i].long_name
        }
      }
    }
    if (typeof query.geometry.location.lat === "function") {
      address.latitude = query.geometry.location.lat()
      address.longitude = query.geometry.location.lng()
    } else if (typeof query.geometry.location.lat === "number") {
      address.latitude = query.geometry.location.lat
      address.longitude = query.geometry.location.lng
    }
  }

  const formatAddressForInput = pacItem => {
    if (pacItem[0]) {
      document.getElementsByClassName("pac-item-query")[0].append(" ")
    }
  }

  const resetInputOnEnterKey = event => {
    if (event.key === "Enter") {
      if (input !== autoCompleteRef.current.value) {
        let firstAddressInList
        if (document.getElementsByClassName("pac-item")) event.preventDefault()
        firstAddressInList = document.getElementsByClassName("pac-item")
        if (firstAddressInList.length) {
          formatAddressForInput(firstAddressInList)
          autoCompleteRef.current.value = firstAddressInList[0].innerText
          askDataToGoogle(firstAddressInList[0].innerText)
        }
      }
    }
  }

  const isred = isError ? " border-cinquo" : " border-primary"

  return (
    <div className="search-location-input relative">
      <input
        autoFocus={autoFocus}
        ref={autoCompleteRef}
        onKeyDown={resetInputOnEnterKey}
        placeholder=""
        defaultValue={deliveryAddress?.addressFormatted}
        className={`focus:outline-none pl-inputWithBtnY pr-10 h-inputHeight w-full font-textFontFamily font-text-FontWeight text-primary text-textFontSize leading-input bg-secondary border-input rounded-formSelect ${isred} `}
      />
      {getLocation && (
        <div className="h-full absolute top-0 right-0 flex items-center justify-center">
          <div className="mr-1 py-1 pl-2 pr-1 flex items-center">
            <GetLocation />
          </div>
        </div>
      )}
    </div>
  )
}

export default SearchLocationInput
