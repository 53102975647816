import React from "react"

export default function Pharmacy() {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="22"
      height="22"
      viewBox="0 0 512 512"
    >
      <g>
        <g>
          <path
            className="fill-current text-primary"
            d="M508.1,56.8c-2.4-1.9-5.6-2.6-8.6-1.8L452,67.1c-7.6,1.9-15.5,2.9-23.4,2.9h-69.8c-22.6,0-41.6,16.3-45,38.6
          c-1.9,12.6-12.5,21.7-25.2,21.7H286v-20.9c17.2-10.3,28.8-29.1,28.8-50.6C314.8,26.4,288.4,0,256,0s-58.8,26.4-58.8,58.8
          c0,21.5,11.6,40.3,28.8,50.6v20.9h-2.6c-12.7,0-23.3-9.1-25.2-21.7c-3.4-22.4-22.3-38.6-45-38.6H83.4c-7.9,0-15.7-1-23.4-2.9
          L12.5,55c-3-0.8-6.2-0.1-8.6,1.8C1.4,58.7,0,61.6,0,64.7c0,21.1,8.1,40.3,21.4,54.8c0.3,24.3,11.8,45.9,29.6,59.9l9.4,12.6
          c21.4,28.7,55.6,45.8,91.4,45.8c27.5,0,53.5-9.7,74.2-27.3V246h-9c-35.7,0-64.7,29-64.7,64.7c0,10.9,2.8,21.7,8,31.2
          c1.8,3.3,5.2,5.2,8.8,5.2c1.6,0,3.3-0.4,4.8-1.2c4.8-2.7,6.6-8.7,3.9-13.6c-3.6-6.5-5.5-14-5.5-21.5c0-24.6,20-44.7,44.7-44.7h9
          v89.3h-23.5c-26.4,0-47.8,21.5-47.8,47.8c0,26.4,21.5,47.8,47.8,47.8h27.8c3.5,18.8,9,37.1,16.6,54.9c1.6,3.7,5.2,6.1,9.2,6.1h0
          c4,0,7.6-2.4,9.2-6.1l0.2-0.5c7.5-17.6,12.9-35.8,16.3-54.4h11.4c11.3,0,20.5,9.2,20.5,20.5s-9.2,20.5-20.5,20.5h-0.7
          c-5.5,0-10,4.5-10,10s4.5,10,10,10h0.7c22.3,0,40.5-18.2,40.5-40.5S315.5,431,293.2,431h-8.5c0.9-8.8,1.4-17.6,1.4-26.5v-29.1h6.7
          c35.7,0,64.7-29,64.7-64.7s-29-64.7-64.7-64.7H286v-35.7c20.7,17.7,46.7,27.3,74.2,27.3c35.8,0,70-17.1,91.4-45.8l9.4-12.6
          c17.8-14,29.3-35.6,29.6-59.9C503.9,105,512,85.8,512,64.7C512,61.6,510.6,58.7,508.1,56.8z M218.1,190.5
          c-17.8,17.6-41.3,27.2-66.2,27.2c-22.2,0-43.7-8-60.5-22.1c2.5,0.2,5.1,0.4,7.6,0.4h16.7c5.5,0,10-4.5,10-10s-4.5-10-10-10H98.9
          c-25.3,0-46.8-16.4-54.5-39.2c10.9,5.5,23.3,8.7,36.4,8.7l20.7,0h0c5.5,0,10-4.5,10-10c0-5.5-4.5-10-10-10l-20.7,0
          c-29,0-53.3-20.4-59.4-47.6l33.7,8.6c9.2,2.4,18.8,3.5,28.3,3.5h69.8c12.7,0,23.3,9.1,25.2,21.7c3.4,22.4,22.3,38.6,45,38.6h2.6
          v32.3L218.1,190.5z M202.5,431c-15.3,0-27.8-12.5-27.8-27.8c0-15.3,12.5-27.8,27.8-27.8H226v28.5c0,9.1,0.5,18.2,1.4,27.1
          L202.5,431L202.5,431z M266,404.5c0,23.3-3.3,46.1-9.9,68.2c-6.7-22.3-10.1-45.3-10.1-68.8v-28.5h20L266,404.5L266,404.5z
           M266,355.3h-20V116.8c3.3,0.6,6.6,0.9,10,0.9s6.7-0.3,10-0.9V355.3z M292.7,266c24.6,0,44.7,20,44.7,44.7
          c0,24.6-20,44.7-44.7,44.7H286V266L292.7,266L292.7,266z M256,97.7c-21.4,0-38.8-17.4-38.8-38.8S234.6,20,256,20
          s38.8,17.4,38.8,38.8S277.4,97.7,256,97.7z M431.2,125.5l-20.7,0c-5.5,0-10,4.5-10,10c0,5.5,4.5,10,10,10l20.7,0
          c13.1,0,25.4-3.1,36.4-8.7c-7.7,22.8-29.2,39.2-54.5,39.2h-16.7c-5.5,0-10,4.5-10,10s4.5,10,10,10h16.7c2.6,0,5.1-0.1,7.6-0.4
          c-16.8,14.1-38.3,22.1-60.5,22.1c-25,0-48.5-9.7-66.2-27.2l-7.9-7.8v-32.3h2.6c22.6,0,41.6-16.3,45-38.6
          c1.9-12.6,12.5-21.7,25.2-21.7h69.8c9.5,0,19.1-1.2,28.3-3.5l33.7-8.6C484.5,105.1,460.2,125.5,431.2,125.5z"
          />
        </g>
      </g>
    </svg>
  )
}
