import React from "react"

/**
 *
 * @param {{
 *  children: string,
 *  invert: boolean,
 *  isBold: boolean,
 *  isItalic: boolean,
 *  textPosition: string
 * }} param0
 *
 * @author Lauwers Yann
 */

export default function PrimaryText({
  children,
  textPosition,
  invert = false,
  isBold = false,
  isItalic = false,
}) {
  let classes = `font-textFontFamily font-text-FontWeight text-text-FontSize tablet:text-tablet-text-FontSize laptop:text-laptop-text-FontSize leading-text tablet:leading-tablet-text laptop:leading-laptop-text ${textPosition}`
  classes += invert ? " text-secondary" : " text-primary"
  classes += isBold ? " font-roundedBtn-FontWeight" : ""
  classes += isItalic ? " italic" : ""

  return <div className={classes}>{children}</div>
}
