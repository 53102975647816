import React from "react"

import EditSelection from "./EditSelection"

/**
 *
 * @param {{
 * prevStep: function,
 * itemToEdit: string,
 * setItemToEdit: function,
 * noPrescriptions: string,
 * setNoPrescriptions: function,
 * electronicPrescriptions: string,
 * setElectronicPrescriptions: string,
 * hideEdit: function,
 * }} param0
 *
 * @author Lauwers Yann
 */

export function EditNoPrescriptionItem({
  prevStep,
  itemToEdit,
  setItemToEdit,
  noPrescriptions,
  setNoPrescriptions,
  hideEdit,
}) {
  const [editableItem, setEditableItem] = React.useState("")

  const handleEdit = () => {
    if (editableItem) {
      const index = noPrescriptions.indexOf(itemToEdit)
      noPrescriptions[index] = editableItem
      setNoPrescriptions([...noPrescriptions])
    }
    setTimeout(() => {
      hideEdit()
    }, 200)
  }

  return (
    <EditSelection
      navbarAction={prevStep}
      titleText="Modifiez le contenu de votre produit :"
      btnName="edit no prescription item"
      btnValue="Valider"
      handleEdit={handleEdit}
      inputName="edit no prescription item"
      inputDefaultValue={itemToEdit}
      setItemToEdit={setItemToEdit}
      noPrescriptions={noPrescriptions}
      setEditableItem={setEditableItem}
    />
  )
}

export function EditElectronicPrescriptionItem({
  prevStep,
  itemToEdit,
  setItemToEdit,
  electronicPrescriptions,
  setElectronicPrescriptions,
  hideEdit,
}) {
  const [editableItem2, setEditableItem2] = React.useState("")

  const handleEdit = () => {
    if (editableItem2) {
      const index = electronicPrescriptions.indexOf(itemToEdit)
      electronicPrescriptions[index] = editableItem2
      setElectronicPrescriptions([...electronicPrescriptions])
    }
    setTimeout(() => {
      hideEdit()
    }, 200)
  }
  return (
    <EditSelection
      navbarAction={prevStep}
      titleText="Modifiez le contenu de votre produit :"
      btnName="edit no prescription item"
      btnValue="Valider"
      handleEdit={handleEdit}
      inputName="edit no prescription item"
      inputDefaultValue={itemToEdit}
      setItemToEdit={setItemToEdit}
      electronicPrescriptions={electronicPrescriptions}
      setEditableItem={setEditableItem2}
    />
  )
}

export function EditIdCardPrescriptionItem({
  prevStep,
  itemToEdit,
  setItemToEdit,
  isCardPrescription,
  setEditCartIdCardPresc,
  hideEdit,
}) {
  const [editableItem2, setEditableItem2] = React.useState("")

  const handleEdit = () => {
    if (editableItem2) {
      const index = isCardPrescription.indexOf(itemToEdit)
      isCardPrescription[index] = editableItem2
      setEditCartIdCardPresc([...isCardPrescription])
    }
    setTimeout(() => {
      hideEdit()
    }, 200)
  }
  return (
    <EditSelection
      navbarAction={prevStep}
      titleText="Modifiez le contenu de votre produit :"
      btnName="edit no prescription item"
      btnValue="Valider"
      handleEdit={handleEdit}
      inputName="edit no prescription item"
      inputDefaultValue={itemToEdit}
      setItemToEdit={setItemToEdit}
      isCardPrescription={isCardPrescription}
      setEditableItem={setEditableItem2}
    />
  )
}
// export const EditElectronicPrescriptionItem = ({items, removeItem, invert, canEdit, noBorderOnOrderSum}) => {
//   return (
//     <EditSelection

//     />
//   )
// }
// export const EditAddress = ({items, removeItem, invert, canEdit, noBorderOnOrderSum}) => {
//   return (
//     <EditSelection

//     />
//   )
// }
