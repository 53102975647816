import React from "react"
import Delete from "../Images/Delete"

/**
 *
 * @param {{
 * action: function,
 * children: string,
 * }} param0
 *
 * @author Lauwers Yann
 */

export default function DeleteCartItemBtn({ action, children }) {
  return (
    <button onClick={action} className="flex justify-center items-center">
      <div className="flex justify-center items-center w-iconDelete h-iconDelete border-cinquo bg-cinquo rounded-bl-cartItem rounded-tr-cartItem absolute top-0 right-0">
        {children}
      </div>
    </button>
  )
}
