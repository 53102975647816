import React from "react"

export default function Calendar({ size, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 23.5"
      width={size}
      height={size}
      className={`fill-current ${color}`}
    >
      <path
        d="M31.4,32.18H9.4a1.5,1.5,0,0,1-1.5-1.5v-18a1.5,1.5,0,0,1,1.5-1.5h22a1.5,1.5,0,0,1,1.5,1.5v18A1.5,1.5,0,0,1,31.4,32.18Zm-22-20a.5.5,0,0,0-.5.5v18a.5.5,0,0,0,.5.5h22a.51.51,0,0,0,.5-.5v-18a.5.5,0,0,0-.5-.5Z"
        transform="translate(-7.9 -8.68)"
      />
      <path
        d="M31.9,18.68H8.9a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h23a.5.5,0,0,1,.5.5A.5.5,0,0,1,31.9,18.68Z"
        transform="translate(-7.9 -8.68)"
      />
      <path
        d="M13.9,14.68a.5.5,0,0,1-.5-.5v-5a.5.5,0,0,1,1,0v5A.5.5,0,0,1,13.9,14.68Z"
        transform="translate(-7.9 -8.68)"
      />
      <path
        d="M26.9,14.68a.5.5,0,0,1-.5-.5v-5a.5.5,0,0,1,1,0v5A.5.5,0,0,1,26.9,14.68Z"
        transform="translate(-7.9 -8.68)"
      />
      <path
        d="M18.66,29.18a.49.49,0,0,1-.35-.15l-4.26-4.2a.49.49,0,0,1,0-.7.5.5,0,0,1,.71,0L18.67,28l7.39-6.68a.5.5,0,0,1,.71,0,.5.5,0,0,1,0,.71l-7.73,7A.55.55,0,0,1,18.66,29.18Z"
        transform="translate(-7.9 -8.68)"
      />
    </svg>
  )
}
