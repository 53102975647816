import { useState } from "react"
import StarButton from "./StarButton"
import { range } from "lodash"

export default function StarsFeedback({ handleChange, selectedFeedbackValue, endRange }) {
  const [hoveredValue, setHoveredValue] = useState(0)
  return (
    <div className="flex pr-5">
      {range(1, endRange + 1, 1).map(starNumber => (
        <StarButton
          key={starNumber}
          starValue={starNumber}
          handleChange={handleChange}
          selectedFeedbackValue={selectedFeedbackValue}
          hoveredValue={hoveredValue}
          setHoveredValue={setHoveredValue}
        />
      ))}
    </div>
  )
}
