import React from "react"

export default function Phone({ size, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 311.91 521.65"
      width={size}
      height={size}
      className="fill-current text-ternary"
    >
      <path
        d="M555.93,400.06q0,116,0,231.94c0,5.57-1,10.83-4,15.55-5.46,8.62-13.37,13.23-23.58,13.23q-128.4.09-256.79,0a27.45,27.45,0,0,1-27.5-27.45q0-44.15,0-88.27V167.34c0-13.82,8.88-24.81,22.35-27.57a33.83,33.83,0,0,1,6.67-.57q127,0,253.91,0c10.23,0,18.5,3.84,24.34,12.38a26.35,26.35,0,0,1,4.6,14.65c0,.88,0,1.76,0,2.64ZM538,245.68H262.16V525.33c2.89.54,274,.41,275.81-.13Zm.14,298H262v88.12c0,.64,0,1.28,0,1.92a9.38,9.38,0,0,0,6.88,8.81,17,17,0,0,0,4.28.37q126.81,0,253.63,0c.87,0,1.76,0,2.63-.07a9.44,9.44,0,0,0,8.35-7.45,20.21,20.21,0,0,0,.32-3.81q0-42.57,0-85.13Zm-276-316.31c2.56.55,273.92.47,276-.12v-2.5q0-28.17,0-56.36a19.54,19.54,0,0,0-.19-2.86,9.6,9.6,0,0,0-7.92-8.22,21.37,21.37,0,0,0-3.58-.15H273.48c-.48,0-1,0-1.44,0-5.49-.27-10.14,4.46-10.1,10.14.15,19.26.06,38.53.07,57.79C262,225.83,262.08,226.61,262.12,227.33Z"
        transform="translate(-244.04 -139.18)"
      />
      <path
        d="M400.07,623A30,30,0,1,1,430,592.89,29.92,29.92,0,0,1,400.07,623ZM387.9,592.82a12.1,12.1,0,1,0,12.25-11.92A12.23,12.23,0,0,0,387.9,592.82Z"
        transform="translate(-244.04 -139.18)"
      />
      <path
        d="M400.15,184.14c10.32,0,20.64,0,31,0,5.88,0,9.75,4.3,9.17,10.09a8.64,8.64,0,0,1-7.23,7.57,23,23,0,0,1-3.11.14q-30,0-60,0a14.84,14.84,0,0,1-4.27-.43c-4.2-1.26-6.62-5.43-6-10a8.8,8.8,0,0,1,8.54-7.36C378.88,184.1,389.51,184.14,400.15,184.14Z"
        transform="translate(-244.04 -139.18)"
      />
    </svg>
  )
}
