import React, { useState, useRef } from "react"
import { useHistory } from "react-router"

// Backend
import { login } from "../Backend/user"

// Services
import { addError, isError } from "../Services/errors"

import { PasswordIcon, RevealPasswordIcon, UserIcon } from "../components/Images/Login"
import ErrorMessage from "../components/ErrorMessage"
import InputWithIcon from "../components/Inputs/InputWithIcon"
import RoundedBtn from "../components/Btns/RoundedBtn"
import H1 from "../components/DefineTagClass/H1"
import { ContainerLogin, PageContainer } from "../components/Container"
import H2 from "../components/DefineTagClass/H2"
import { Url } from "../routes/Routes"

export default function SignIn() {
  let history = useHistory()
  const [user, setUser] = useState({})
  const [errors, setErrors] = useState([])
  const [passwordShown, setPasswordShown] = useState(false)

  const errorRef = useRef(null)

  const logUserIn = async () => {
    try {
      const response = await login(user)
      if (response.message) {
        setErrors([
          {
            name: "identifiants_incorrect",
            error: "L'adresse email ou le mot de passe entré(e) est incorrect(e)",
          },
        ])
      } else {
        return history.push(Url.pharmacyLoggedInForm)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const errorDealing = () => {
    const array = []
    const identifiants_incorrect = "L'adresse email ou le mot de passe entré(e) est incorrect(e)"
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (!user.email || !user.password || !re.test(user.email) || !user.password.length > 0) {
      addError("identifiants_incorrect", identifiants_incorrect, array)
    }
    setErrors(array)
    return array.length
  }

  const handleSubmit = e => {
    e.preventDefault()
    errorDealing()
    if (!errorDealing()) {
      logUserIn()
    }
  }

  const handleChange = event => {
    let nam = event.target.name
    let val = event.target.value
    setUser({ ...user, [nam]: val })
  }

  return (
    <>
      <H1 step={1} invert>
        Espace réservé aux pharmaciens
      </H1>
      <PageContainer color="primary">
        <ContainerLogin>
          <H2 green>Identifiez-vous</H2>
          <ErrorMessage errors={errors} ref={errorRef} loginW />
          <form onSubmit={e => handleSubmit(e)} className="flex items-center flex-col">
            <div className="mb-inputToSubmit w-full flex flex-col">
              <InputWithIcon
                type="text"
                name="email"
                action={handleChange}
                placeholder="Adresse email"
                isError={isError("identifiants", errors)}
                emailImg={<UserIcon />}
              />
            </div>
            <div className="mb-inputToSubmit w-full flex flex-col">
              <InputWithIcon
                type={passwordShown ? "text" : "password"}
                name="password"
                action={handleChange}
                action2={() => setPasswordShown(!passwordShown)}
                placeholder="Mot de passe"
                iconRight
                isError={isError("identifiants", errors)}
                emailImg={<PasswordIcon />}
                passwordImg={<RevealPasswordIcon state={passwordShown} />}
              />
            </div>
            <div className="flex justify-center items-center flex-col">
              <RoundedBtn type="submit" action={e => handleSubmit(e)} name="signIn">
                Se connecter
              </RoundedBtn>
            </div>
          </form>
        </ContainerLogin>
      </PageContainer>
    </>
  )
}
