import React from "react"

export default function ArrowBackOutline({ size }) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 343.41 343.21"
    >
      <path
        className="fill-current text-primary"
        d="M571.7,400c0,94.81-76.83,171.6-171.68,171.64-94.09,0-171.69-75.89-171.72-171.59a171.7,171.7,0,0,1,343.4,0ZM400,553.87c85.36-.28,153.66-68.9,153.91-153.32A153.92,153.92,0,0,0,400.61,246.18c-84.35-.3-154.51,67.91-154.52,153.87C246.08,484.25,314.12,553.56,400,553.87Z"
        transform="translate(-228.3 -228.39)"
      />
      <path
        className="fill-current text-primary"
        d="M344.21,391c1.67,0,2.76.09,3.84.09h126.7c1.2,0,2.41,0,3.6.1a8.65,8.65,0,0,1,8,9.08,8.81,8.81,0,0,1-8.35,8.6c-.79.05-1.6,0-2.4,0H344.15c1,1.07,1.55,1.71,2.15,2.31q14.25,14.25,28.51,28.48c2.75,2.74,3.78,5.9,2.58,9.61a8.82,8.82,0,0,1-13.7,4.31,18.54,18.54,0,0,1-2.12-1.94q-22.5-22.46-45-44.92c-4.06-4.05-4.1-9.42-.11-13.4q22.74-22.71,45.48-45.44c2-2,4.24-3.34,7.18-3.26a8.86,8.86,0,0,1,7.17,13.88,16.14,16.14,0,0,1-2.06,2.32l-27.82,27.82C345.81,389.29,345.24,389.92,344.21,391Z"
        transform="translate(-228.3 -228.39)"
      />
    </svg>
  )
}
