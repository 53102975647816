import React from "react"

/**
 *
 * @param {{
 *  children: string,
 *  invert: boolean,
 *  step: number,
 * }} param0
 *
 * @author Lauwers Yann
 */

export default function H1({ children, invert = false }) {
  let classes =
    "text-center font-titleFontFamily font-header1-FontWeight text-header1-FontSize tablet:text-tablet-header1-FontSize laptop:text-laptop-header1-FontSize leading-header1 tablet:leading-tablet-header1 laptop:leading-laptop-header1 w-mobileTitleW tablet:w-tabletTitleW laptop:laptopTitleW mx-auto"
  if (invert) {
    classes += " text-secondary"
  } else {
    classes += " text-primary"
  }

  let divClasses =
    "w-full py-mobile-ContainerYT tablet:py-tablet-Container2YT laptop:py-laptop-Container2YT "
  divClasses += invert ? "bg-primary" : "bg-quatro"

  return (
    <div className={divClasses}>
      <h1 className={classes}>{children}</h1><br/>

    </div>
  )
}
