/**
 * eubfjeb
 */
export const OrderErrorType = {
  Already_Paid: "Already_Paid",
  Notice_not_read: "Notice_not_read",
  Price_missing: "Price_missing",
  Not_found: "Not_found",
}

export class OrderException extends Error {
  type: string

  constructor(type) {
    super()
    this.type = type
  }
}
