import React from "react"

/**
 *
 * @param {{
 *  children: string,
 * }} param0
 *
 * @author Lauwers Yann
 */

export default function RoundedButtonEmptyBgText({ children }) {
  return (
    <div className="text-center font-secondaryFontFamily font-text-FontWeight text-text-FontSize leading-roundedBtn tablet:text-tablet-roundedBtn-FontSize laptop:text-laptop-roundedBtn-FontSize tablet:leading-tablet-roundedBtn laptop:leading-laptop-roundedBtn">
      {children}
    </div>
  )
}
