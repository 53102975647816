import React from "react"

/**
 *
 * @param {{
 *  children: string,
 *  invert: boolean,
 *  green: boolean,
 *  noMargin: boolean,
 *  noPadding: boolean,
 * }} param0
 *
 * @author Lauwers Yann
 */

export default function H2({
  children,
  green = false,
  invert = false,
  noMargin = false,
  noPadding = false,
  isCentered = false,
}) {
  let classes =
    "font-titleFontFamily font-header2-FontWeight text-header2-FontSize tablet:text-tablet-header2-FontSize laptop:text-laptop-header2-FontSize leading-header2 tablet:leading-tablet-header2 laptop:leading-laptop-header2"

  let divClasses = "w-full"

  if (invert) {
    classes += " text-secondary"
  } else if (green) {
    classes += " text-ternary"
  } else {
    classes += " text-primary"
  }

  divClasses += isCentered ? " text-center" : " text-left"

  divClasses += noMargin
    ? ""
    : " mb-mobile-Container2YT tablet:mb-tablet-Container2YT laptop:mb-laptop-Container2YT"

  divClasses += noPadding ? "" : " tablet:pt-tablet-Container2YT laptop:pt-laptop-Container2YT"

  return (
    <div className={divClasses}>
      <h2 className={classes}>{children}</h2>
    </div>
  )
}
