import React from "react"

export default function EditFill() {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 315.79 315.79"
      width="24"
      height="24"
    >
      <circle className="fill-current text-ternary" cx="157.89" cy="157.89" r="157.89" />
      <path
        id="Tracé_662"
        data-name="Tracé 662"
        className="stroke-current fill-none text-secondary stroke-edit"
        d="M392.71,460.88,448,365a13,13,0,0,0-4.69-17.7L426,336.47a13,13,0,0,0-17.7,4.81L352.88,437.2l-2.34,39a6.64,6.64,0,0,0,10.15,5.86Z"
        transform="translate(-242.11 -242.11)"
      />
      <line
        id="Ligne_464"
        data-name="Ligne 464"
        className="stroke-current fill-none text-secondary stroke-edit"
        x1="113.64"
        y1="189.5"
        x2="153.85"
        y2="212.66"
      />
    </svg>
  )
}
