import FullyRoundedBackBtn from "../Btns/FullyRoundedBackBtn"

function H1({ children }) {
  return (
    <h1 className="text-mobile-h1-fontsize leading-mobile-h1-leading font-extra-bold font-nunito text-primary text-center tablet:text-tablet-h1-fontsize tablet:leading-tablet-h1-leading laptop:text-laptop-h1-fontsize laptop:leading-laptop-h1-leading">
      {children}
    </h1>
  )
}

export function Header1({ children }) {
  return (
    <div className="bg-quatro flex justify-center items-center tablet:h-tablet-h1-banner-height px-10 py-8 tablet:py-16">
      <H1>{children}</H1>
    </div>
  )
}

export function Header1WithBackButton({ prevStep, children }) {
  return (
    <div className="bg-quatro flex justify-center items-center h-mobile-h1-banner-height tablet:h-tablet-h1-banner-height laptop:h-laptop-h1-banner-height space-x-3 px-10">
      <FullyRoundedBackBtn size={26} action={prevStep} />
      <H1>{children}</H1>
    </div>
  )
}

export function Header2({ children }) {
  return (
    <div className="bg-primary h-mobile-h2-banner-height flex justify-center items-center tablet:h-tablet-h2-banner-height py-3 tablet:py-8">
      <h2 className="text-secondary font-titleSecondaryFontFamily font-semi-bold text-mobile-h2-fontsize leading-mobile-h2-leading tablet:text-tablet-h2-fontsize tablet:leading-tablet-h2-leading laptop:text-laptop-h2-fontsize laptop:leading-laptop-h2-leading">
        {children}
      </h2>
    </div>
  )
}

export function H3({ children }) {
  return (
    <h3 className="font-nunito font-extra-bold leading-mobile-h3-leading text-mobile-h3-fontsize text-primary tablet:leading-tablet-h3-leading tablet:text-tablet-h3-fontsize laptop:leading-laptop-h3-leading laptop:text-laptop-h3-fontsize">
      {children}
    </h3>
  )
}
