import React, { useState } from "react";

import Label from "../DefineTagClass/Label";
/**
 *
 * @param {{
 * img: string,
 * alt: string,
 * label: string,
 * isGreen: boolean,
 * isCart: boolean,
 * line1: string,
 * line2: string,
 * }} param0
 */

export default function ProfileCard({
  name,
  lastname,
  distance,
  image,
}) {
  const distanceToFixedTwo = Math.round(distance / 10) / 100;

  // console.log("Received : ", image)
  return (
    <div
      className="w-full group relative"
      style={{
        maxWidth: 250, marginBottom: 20, marginLeft: 15, marginTop: 20,
      }}
    >
      <div className="absolute right-4 top-4 bg-ternary text-secondary rounded-blueBg py-9.5 px-2.5">
        <p className="font-titleFontFamily font-header3-FontWeight">
          {distance}
        </p>
      </div>
      <div
        className="text-left py-cartItemInsidePaddingY px-cartItemInsidePaddingX
            border-t-cartItem border-l-cartItem border-r-cartItem rounded-t-pharmacyCard border-primary border-opacity-10
          "
      >
        <Label
          defaultValue={lastname}
          isCart
          className="group-hover:text-ternary font-header2-FontWeight text-header2-FontSize font-titleFontFamily"
        >
          {lastname}
        </Label>
      </div>
      <div
        style={{
          backgroundImage: `url(${image.data?.url})`,
          height: 250,
          width: 250,
          backgroundRepeat: "no-repeat",
          borderRightWidth: 1,
          borderLeftWidth: 1,
          borderColor: "rgba(27, 50, 86, 0.1)",
          padding: 12,
          backgroundSize: "cover",
          backgroundPosition: "50% 50%",
        }}
      ></div>

      {/* <img src={image.data?.url}  /> */}
      <div
        className="pt-cartItemInsidePaddingY px-cartItemInsidePaddingX pb-cartItemInsidePaddingX
            border-b-cartItem border-l-cartItem border-r-cartItem rounded-b-pharmacyCard border-primary border-opacity-10 group-hover:shadow-md
          "
      >
        <p className="font-titleSecondaryFontFamily text-left"></p>
        <p className="font-titleSecondaryFontFamily text-left"></p>

        <p className="font-titleSecondaryFontFamily text-left"></p>
      </div>
    </div>
  );
}
