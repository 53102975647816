import { AddressDTO } from "../Models/AddressDTO"
import { PharmacyDTO } from "../Models/PharmacyDTO"
import { GET, POST } from "./request"

const convertPharma = pharmaFromServer => {
  const pharma = new PharmacyDTO()
  pharma.id = pharmaFromServer.id
  pharma.name = pharmaFromServer.name
  pharma.member = pharmaFromServer.member
  pharma.personnalName = pharmaFromServer.pharmaLink
  pharma.contract = pharmaFromServer.contract
  pharma.status = pharmaFromServer.status

  if (pharmaFromServer.address) {
    const address = new AddressDTO()
    address.streetNumber = pharmaFromServer.address.streetNumber
    address.street = pharmaFromServer.address.street
    address.city = pharmaFromServer.address.city
    address.postalCode = pharmaFromServer.address.postalCode
    address.country = pharmaFromServer.address.country
    address.latitude = pharmaFromServer.address.latitude
    address.longitude = pharmaFromServer.address.longitude

    pharma.address = address
  }
  return pharma
}

/**
 *
 * @param {String} address Delivery address
 * @returns {Promise<PharmacyDTO[]>}
 */
export const getReachable = async address => {
  const response = await POST("pharmacy/nearby", { address })
  const json = await response.json()
  return json.map(pharma => convertPharma(pharma))
}

/**
 *
 * @param {String} pharmaID Pharmacies id
 * @param {String} address Delivery address (can be an object with lat and long)
 * @returns {Promise<boolean | PharmacyDTO[]>}
 */
export const isPharmacyReachable = async (pharmaID, address) => {
  const response = await POST("pharmacy/reachable", { pharmaID, address })
  const json = await response.json()
  if (json.reachable) return json.reachable
  return json.pharmacies.map(pharma => convertPharma(pharma))
}

/**
 *
 * @param {Object} [filter] Search filter
 * @param {String} [filter.name] Name includes in pharmacy name
 * @param {Number} [filter.start] get element from index
 * @param {Number} [filter.stop] get element to index
 * @returns {Promise<PharmacyDTO[]>}
 */
export const getPharmacies = async (filter = {}) => {
  const response = await POST("pharmacy/", filter)
  try {
    const json = await response.json()
    return json.map(pharma => convertPharma(pharma))
  } catch (err) {
    console.error(err)
  }
}

/**
 *
 * @param {String} personnalName Delivery address
 * @returns {Promise<PharmacyDTO[]>}
 */
export const getPharmacy = async pn => {
  const response = await GET(`pharmacy/${pn}`)
  try {
    const json = await response.json()
    return json
  } catch (error) {
    console.error(error)
  }
}

export const PharmacyAPI = {
  getReachable,
  getPharmacies,
  getPharmacy,
  isPharmacyReachable,
}
