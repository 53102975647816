import React from "react"

export default function DropdownIndicatorIcon() {
  return (
    <svg
      className="fill-current text-primary"
      width="11"
      height="11"
      viewBox="0 0 11 11"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.5 11L0.73686 6.5L10.2631 6.5L5.5 11Z" />
      <path d="M5.5 0L10.2631 4.5L0.73686 4.5L5.5 0Z" />
    </svg>
  )
}
