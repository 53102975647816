import { useEffect } from "react"

// Components
import RoundedBtn from "../../components/Btns/RoundedBtn"
import { Container, PageContainer } from "../../components/Container"
import H1 from "../../components/DefineTagClass/H1"
import H2 from "../../components/DefineTagClass/H2"
import PrimaryText from "../../components/DefineTagClass/PrimaryText"
import FeedbackModule from "../../components/Feedback/FeedbackModule"
import Recap from "../../components/Images/Recap"
import Information from "../../components/Information/Information"
import LoadingSpinner from "../../components/Loading"
import { colorMode } from "../../Config/colorMode"

//form
import hasMandataire from "./6PersonnalDetails"

/**
 *
 * @param {{
 * backToMenu: function,
 * setNav: function,
 * orderHash: string
 * }} param0
 *
 * @author Lauwers Yann
 */

export default function Success({ backToMenu, setNav, orderHash,hasMandataire }) {
  /**
   * On button press, it realoads the page.
   * Datas have been sent on the previous page. (step4)
   */

  useEffect(() => {
    setNav(colorMode.blueBg)
  }, [])

  if (orderHash)
    return (
      <>
        <H1 invert>Merci pour votre commande</H1>
        <PageContainer>
          <div className="space-y-12 pb-32">
            <div className="mt-header1ToHeader2 tablet:grid tablet:grid-cols-successWithNotice tablet:gap-10 laptop:gap-16 tablet:w-tabletW laptop:w-laptopW tablet:mx-auto tablet:pt-tablet-Container2YT laptop:pt-laptop-Container2YT pb-14  border-b border-quatro">
              <div className="w-mobileInfoW mx-auto tablet:w-full">
                
                <H2 green noPadding>
                  Votre pharmacien reviendra vers vous dès que possible.
                </H2>
                <div className="mb-inputToSubmit tablet:mb-0  w-full flex space-x-4">
                  <Recap />
                  
        
                  <PrimaryText>
                   <p>Vous ainsi que votre mandataire devriez avoir reçu par <strong> email un récapitulatif</strong> de votre
                    commande. Si vous avez choisis un mandataire, il sera recevra également cet email. </p>
                    
                  </PrimaryText>
                 
                  
                </div>
              </div>
              <Information>
              Dès votre commande validée, vous recevrez un {" "}
              <strong>email </strong>
              vous confirmant l'heure à laquelle votre commande sera prête en pharmacie. Le {" "}
              <strong>paiement </strong> 
               se fait dans l'officine.

               
              </Information>

            </div>
            <Container>
              <div className="flex justify-center">
                <FeedbackModule feedbackNote={orderHash} feedbackKey="after-online-order">
                  Votre avis compte
                </FeedbackModule>
              </div>
              <div className="flex justify-center items-center mt-10">
                <RoundedBtn action={backToMenu} name="retour menu" type="button">
                  Envoyer
                </RoundedBtn>
              </div>
            </Container>
          </div>
        </PageContainer>
      </>
    )

  return (
    <>
      <H1 invert>Votre commande est en cours de traitement</H1>
      <PageContainer>
        <div className="pt-14 pb-32">
          <H2 isCentered>Merci de patienter</H2>
          <LoadingSpinner />
        </div>
      </PageContainer>
    </>
  )
}
