import React from "react"
import { forwardRef } from "react"

/**
 *
 * @param {{
 * errors: string,
 * loginW: boolean,
 * }} param0
 *
 * @author Lauwers Yann
 */
const ErrorMessage = forwardRef(({ errors, loginW = false }, ref) => {
  const getFirstError = () => {
    if (errors?.length) {
      return errors[0].error
    }
  }

  return (
    <div
      ref={ref}
      className={`mx-auto py-insideErrorY border-sixo border-formSelect rounded-formSelect text-center bg-errorBg mb-mobile-Container2YT tablet:mb-tablet-Container2YT laptop:mb-laptop-Container2YT ${
        errors?.length ? "" : "hidden"
      } ${loginW ? "w-loginW" : "w-full"}`}
    >
      <div className="mx-mobile-ContainerX font-secondaryFontFamily font-error-FontWeight text-error-FontSize text-cinquo leading-error">
        {getFirstError()}
      </div>
    </div>
  )
})

export default ErrorMessage
