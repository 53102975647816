import React from "react";

import RoundedBtn from "./RoundedBtn";

/**
 *
 * @param {{
 * children: string,
 * action: function,
 * isBlocked: boolean,
 * isOrderSummary: boolean;
 * }} param0
 *
 * @author Lauwers Yann
 */

export default function BtnFixedBottom({
  children,
  action,
  isBlocked,
  isOrderSummary = false,
}) {
  let classes =
    "h-cartBtnHeight sticky bottom-0 w-screen flex justify-center items-center bg-secondary shadow-fixedDivBottomOnOrder";
  classes += isOrderSummary ? " z-10" : "";

  return (
    <div className={classes}>
      <div className="w-max py-2">
        <RoundedBtn
          type="button"
          name="cart button"
          action={action}
          isBlocked={isBlocked}
        >
          {children}
        </RoundedBtn>
      </div>
    </div>
  );
}
