export function ButtonSm({ children }) {
  return (
    <div className="text-mobile-buttonSm-fontsize leading-mobile-buttonSm-leading tablet:text-tablet-buttonSm-fontsize tablet:leading-tablet-buttonSm-leading laptop:text-laptop-buttonSm-fontsize laptop:leading-laptop-buttonSm-leading font-bold font-nunito">
      {children}
    </div>
  )
}

export function ButtonLg({ children }) {
  return (
    <div className="text-mobile-buttonLg-fontsize leading-mobile-buttonLg-leading tablet:text-tablet-buttonLg-fontsize tablet:leading-tablet-buttonLg-leading laptop:text-laptop-buttonLg-fontsize laptop:leading-laptop-buttonLg-leading font-bold font-nunito">
      {children}
    </div>
  )
}
