import React, { useRef } from "react"

export default function Iframe({ src, scriptSrc, title }) {
  const frame = useRef(null)
  const styles = {
    background: "transparent",
    border: "1px solid #ccc",
  }
  return (
    <div className="flex-grow flex-shrink-0">
      <script src={scriptSrc}></script>
      <iframe
        ref={frame}
        id="container"
        title={title}
        className="h-screen"
        style={styles}
        src={src}
        width="100%"
        scrolling="no"
      ></iframe>
    </div>
  )
}
