import React from "react"

/**
 *
 * @param {{
 *  children: string,
 *  invert: boolean,
 *  hasBigMarginB: boolean,
 *  hasMarginB: boolean,
 *  lowOpacity: boolean,
 * }} param0
 *
 * @author Lauwers Yann
 */

// In the following parameters, MarginB means margin bottom!

export default function H3({
  children,
  invert = false,
  heavy = false,
  hasBigMarginB = false,
  hasMarginB = false,
  isCentered = false,
}) {
  let classes = "font-titleFontFamily text-header3-FontSize leading-header3"
  classes += invert ? " text-secondary" : " text-primary"
  classes += heavy ? " font-inlineBtnHeavy-FontWeight" : " font-header3-FontWeight"
  let classes2 = ""
  classes2 += hasMarginB ? " mb-labelToInput" : ""
  classes2 += hasBigMarginB ? " mb-labelToInput2" : ""
  classes2 += isCentered ? " text-center" : " text-left"

  return (
    <div className={classes2}>
      <span className={classes}>{children}</span>
    </div>
  )
}
