import React from "react"

/**
 *
 * @param {{
 * type: string,
 * name: string,
 * placeholder: string,
 * defaultValueInput: string,
 * action: function,
 * isError: boolean,
 * }} param0
 *
 * @author Lauwers Yann
 */

export default function InputWithIcon({
  type,
  name,
  defaultValueInput,
  action,
  action2,
  placeholder,
  emailImg,
  passwordImg,
  iconRight = false,
  isError = false,
}) {
  return (
    <div
      className={`border-b-2 pb-inputWithIconY flex align-center ${
        isError ? "border-cinquo" : "border-secondary"
      }`}
    >
      <label className="mx-inputWithIconX" htmlFor={name}>
        {emailImg}
      </label>
      <input
        type={type}
        name={name}
        id={name}
        onChange={action}
        placeholder={placeholder}
        defaultValue={defaultValueInput}
        className="placeholder-secondary placeholder-opacity-70 focus:outline-none focus:ring-0 focus:ring-offset-0 w-full font-textFontFamily font-text-FontWeight text-secondary text-textFontSize leading-input bg-transparent border-none p-0"
      />
      <button
        type="button"
        onClick={action2}
        className={`focus:outline-none mx-inputWithIconX ${iconRight ? null : "hidden"}`}
      >
        {passwordImg}
      </button>
    </div>
  )
}
