export function PrimaryText({ children, fontWeight = "normal", color = "primary" }) {
  return (
    <p
      className={`font-barlow font-${fontWeight} text-mobile-text-fontsize text-${color} leading-mobile-text-leading tablet:text-tablet-text-fontsize tablet:leading-tablet-text-leading laptop:text-laptop-text-fontsize laptop:leading-laptop-text-leading align-middle`}
    >
      {children}
    </p>
  )
}

export function SecondaryText({ children, fontWeight = "normal", color = "primary" }) {
  return (
    <div
      className={`font-nunito font-${fontWeight} font-extra-bold leading-mobile-text-secondary-leading text-mobile-text-secondary-fontsize tablet:text-tablet-text-secondary-fontsize tablet:leading-tablet-text-secondary-leading laptop:text-laptop-text-secondary-fontsize laptop:leading-laptop-text-secondary-leading text-${color}`}
    >
      {children}
    </div>
  )
}
