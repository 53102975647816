import { useEffect, useState } from "react"
import { ContainerMain, PageContainer } from "../components/Container"
import { PharmacyAPI } from "../Backend/pharmacy"
import { Link, useHistory } from "react-router-dom"
import { Header1, Header2 } from "../components/DefineTagClass/Header"
import {
  AboutUs,
  AvailableDeliveries,
  CGV,
  PharmacyCardWithDetails,
  AboutUsAndOrderLinkAlignment,
  PageAlignment,
} from "../components/PharmacyDetails/PharmacyDetailsComponents"
import { ButtonLg } from "../components/DefineTagClass/Buttons"

export default function PharmacyPersonnalLink() {
  const [pharmacyChoice, setPharmacyChoice] = useState({})
  const [pharmacyPersonnalName, setPharmacyPersonnalName] = useState("")
  let history = useHistory()

  useEffect(() => {
    if (window.location.pathname.includes("/pharmacy/")) {
      getPharmacyDetails()
    }
  }, [])

  const getPharmacyDetails = () => {
    let personnalName = window.location.pathname.split("/pharmacy/")[1]
    let pathAfterPersonnalName
    if (personnalName.includes("/")) {
      pathAfterPersonnalName = personnalName.split("/")[1]
      personnalName = personnalName.split("/")[0]
    } else {
      getPharmacyDetailsFromPersonnalName(personnalName)
    }
    setPharmacyPersonnalName(personnalName)
    // Redirect home if path after /personnalLink/ is not order
    if (pathAfterPersonnalName && pathAfterPersonnalName !== "order") {
      return history.push("/")
    }
  }

  const getPharmacyDetailsFromPersonnalName = async pn => {
    try {
      let response = await PharmacyAPI.getPharmacy(pn)
      if (response) {
        setPharmacyChoice(response)
      } else if (!response) {
        return history.push("/")
      }
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <>
      <Header1>Pharmacie {pharmacyChoice.name}</Header1>
      <Header2>Bienvenue dans votre pharmacie de quartier!</Header2>
      <PageContainer>
        <ContainerMain>
          <PageAlignment>
            <AboutUsAndOrderLinkAlignment>
              <AboutUs>
                <span className="text-ternary font-extra-bold">{pharmacyChoice.name}</span>{" "}
                {pharmacyChoice.description}
              </AboutUs>
              <Link
                to={{
                  pathname: `/pharmacy/${pharmacyPersonnalName}/order`,
                  state: { pharmacyChoice },
                }}
                className="w-full max-w-mobile-buttonLg tablet:max-w-tablet-buttonLg laptop:max-w-laptop-buttonLg h-roundedBtnLg focus:outline-none rounded-roundedBtn cursor-btn filter drop-shadow-roundedBtn shadow-roundedBtn
             text-secondary bg-cinquo flex justify-center items-center tablet:col-start-1 tablet:col-end-2 laptop:col-start-2 laptop:col-end-3"
              >
                <ButtonLg>Commander ici</ButtonLg>
              </Link>
            </AboutUsAndOrderLinkAlignment>
            <PharmacyCardWithDetails pharmacyDetails={pharmacyChoice} />
            <AvailableDeliveries deliveryMethods={pharmacyChoice?.deliveryMethods} />
            {pharmacyChoice?.cgv && <CGV cgv={pharmacyChoice?.cgv} />}
          </PageAlignment>
        </ContainerMain>
      </PageContainer>
    </>
  )
}
