import React, { useEffect, useRef, useState } from "react"

// Libraries
import PhoneInput from "react-phone-number-input/input"
import Select from "react-select"
import { isPossiblePhoneNumber, isValidPhoneNumber } from "react-phone-number-input"
import { format, isAfter, isValid, addMinutes } from "date-fns"

// Style
import "../../style/components/select.scss"
import ReactQuill, { Quill } from "react-quill"
import "react-quill/dist/quill.snow.css"

// Services
import { addError, isError, scrollToError } from "../../Services/errors"
import { computeDistance, formatAddress } from "../../Services/AddressService"
import { formatter } from "../../Services/money"
import { checkIsConnect } from "../../Services/UserContext"

// Config
import { YesNo } from "../../Config/YesNoSelect"
import { colorMode } from "../../Config/colorMode"
import SearchLocationInput from "../../Config/SearchLocationInput"

// Models
import { UserDTO } from "../../Models/UserDTO"
import { OrderFromPharmacyDTO } from "../../Models/OrderFromPharmacyDTO"
import { AddressDTO } from "../../Models/AddressDTO"
import { Url } from "../../routes/Routes"

// Components
import {
  DropdownIndicator,
  Placeholder,
  NoOptionsMessage,
  IndicatorSeparator,
  customStyles,
} from "../../components/Selects/Select"
import RoundedBtn from "../../components/Btns/RoundedBtn"
import { PageContainer } from "../../components/Container"
import H2 from "../../components/DefineTagClass/H2"
import Label from "../../components/DefineTagClass/Label"
import PrimaryText from "../../components/DefineTagClass/PrimaryText"
import ErrorMessage from "../../components/ErrorMessage"
import { ColSpanFull, ColSpanHalf, Grid6 } from "../../components/Grids/FormGrid"
import CheckBox from "../../components/Inputs/CheckBox"
import Input from "../../components/Inputs/Input"
import { Header1 } from "../../components/DefineTagClass/Header"
import { isLoggedIn, logout } from "../../Backend/user"
import { useHistory } from "react-router-dom"

const PRICE_DELIVERY = 5

/**
 *
 * @param {{
 * setNav: function,
 * currentStep: number,
 * order: {},
 * next: function,
 * setOrder: function
 * }} param0
 * @returns
 *
 * @author Yann Lauwers
 */

export default function ClientDetails({
  setNav,
  currentStep,
  order,
  next,
  setOrder,
  userLoggedIn,
}) {
  const dateNow = new Date()
  const [errors, setErrors] = useState([])
  const [deliveryAddress, setDeliveryAddress] = useState(new AddressDTO())
  const [orderReadyDate, setOrderReadyDate] = useState(addMinutes(dateNow, 30))
  const [deliveryInfo, setDeliveryInfo] = useState("")
  const [posologieInfo, setPosologieInfo] = useState("")
  const [user, setUser] = useState(new UserDTO())
  const [price, setPrice] = useState(0)
  const [lastCheck, setLastCheck] = useState(undefined)
  // eslint-disable-next-line no-unused-vars
  const [orderPaid, setOrderPaid] = useState(false)
  const [pharmacienPaysForDeliveryFee, setPharmacienPaysForDeliveryFee] = useState(false)
  // const [deliveryGuyMustGetPaperPrescription, setDeliveryGuyMustGetPaperPrescription] =
  useState(false)
  const errorRef = useRef(null)

  const formatOrder = () => {
    const order = new OrderFromPharmacyDTO()
    order.payment = {}
    order.payment.status = orderPaid ? "paid" : "waiting"
    order.payment.value = orderPaid ? 0 : price
    if (user) {
      order.user = user
    }
    if (deliveryAddress) {
      order.address = formatAddress(deliveryAddress)
    }
    order.deliverySchedul = orderReadyDate
    if (deliveryInfo) {
      order.deliveryInfo = deliveryInfo
    }
    if (posologieInfo) {
      order.notice = posologieInfo
    }
    order.pharmacienPaysForDeliveryFee = pharmacienPaysForDeliveryFee
    //order.deliveryGuyMustGetPaperPrescription = deliveryGuyMustGetPaperPrescription
    return order
  }

  useEffect(() => {
    setNav(colorMode.blueBg)
    isLoggedIn()
  }, [])

  useEffect(() => {
    scrollToError(errorRef)
  }, [errors.length, errorRef?.current?.offSetHeight])

  if (currentStep !== 1) {
    return null
  }

  const handleUser = e => {
    const value = e.target.value
    setUser({
      ...user,
      [e.target.name]: value,
    })
  }

  const handlePhone = e => {
    setUser({
      ...user,
      phone: e,
    })
  }

  const handleDeliveryInfo = info => {
    setDeliveryInfo(info.target.value)
  }

  const handlePosologieInfo = info => {
    setPosologieInfo(info)
  }

  const handlePrice = e => {
    const { value } = e.target
    let prodPrice = 0
    if (!e.target.value) {
      prodPrice = 0
    } else {
      prodPrice = parseFloat(value)
    }
    return setPrice(prodPrice)
  }

  const handleOrderPaid = e => {
    setOrderPaid(e.value)
    setPharmacienPaysForDeliveryFee(true)
    setPrice(0)
  }

  const orderPrice = () => {
    let finalPrice = 0
    finalPrice += price
    if (!pharmacienPaysForDeliveryFee) finalPrice += PRICE_DELIVERY
    return orderPaid ? 0 : finalPrice
  }

  /**
   * The next function handles the message error you'll get when trying to complete the form
   */
  const errorDealing = () => {
    const array = []
    // eslint-disable-next-line no-useless-escape
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    const firstName_missing = "Le prénom est manquant"
    const lastName_missing = "Le nom de famille est manquant"
    const email_missing = "L'email est manquant"
    const email_incorrect = "L'email est incorrect"
    const phone_missing = "Le numéro de téléphone est manquant"
    const phone_incorrect = "Le numéro de téléphone est incorrect"
    const orderReadyDate_missing = "La date de livraison est manquante"
    const orderReadyDate_pastDate = "La date entrée ne peut pas être dans le passé"
    const price_incorrect = "Le prix ne peut pas être négatif"

    const noAddress = "Veuillez entrer une adresse pour continuer."
    const noStreetNumber = "Veuillez entrer un numéro de rue pour continuer."
    const noStreet = "Veuillez entrer une rue pour continuer."
    const noCity = "Veuillez entrer une ville pour continuer."
    const noPostalCode = "Veuillez entrer un code postal pour continuer."
    const deliveryAddress_too_far =
      "L'adresse que vous avez entrée est malheureusement hors du périmètre de livraison de votre pharmacie."

    if (!user.firstName) addError("firstName_missing", firstName_missing, array)
    if (!user.lastName) addError("lastName_missing", lastName_missing, array)
    if (!user.email) addError("email_missing", email_missing, array)
    if (!re.test(user.email)) addError("email_incorrect", email_incorrect, array)
    if (!user.phone || user.phone === undefined) {
      addError("phone_missing", phone_missing, array)
    } else {
      if (!isPossiblePhoneNumber(user.phone) && !isValidPhoneNumber(user.phone))
        addError("phone_incorrect", phone_incorrect, array)
    }
    if (!Object.keys(deliveryAddress).length) {
      addError("deliveryAddress_missing", noAddress, array)
    } else if (!deliveryAddress.street) {
      addError("deliveryAddress_street_missing", noStreet, array)
    } else if (!deliveryAddress.streetNumber) {
      addError("deliveryAddress_streetNumber_missing", noStreetNumber, array)
    } else if (!deliveryAddress.city) {
      addError("deliveryAddress_city_missing", noCity, array)
    } else if (!deliveryAddress.postalCode) {
      addError("deliveryAddress_postalCode_missing", noPostalCode, array)
    }
    if (deliveryAddress.latitude && deliveryAddress.longitude) {
      const dist = computeDistance(deliveryAddress, userLoggedIn.pharmacy.address) / 1000 // In km
      if (dist > userLoggedIn.pharmacy.perimeter) {
        addError("deliveryAddress_too_far", deliveryAddress_too_far, array)
      }
    }

    if (!orderReadyDate) addError("orderReadyDate_missing", orderReadyDate_missing, array)
    if (!isAfter(orderReadyDate, dateNow))
      addError("orderReadyDate_pastDate", orderReadyDate_pastDate, array)
    if (price < 0) addError("price_incorrect", price_incorrect, array)
    setErrors(array)
    return array.length
  }

  const lastCheckConnect = () => {
    const btwin = Date.now() - lastCheck || 0
    if (!lastCheck || btwin > 60000) checkIsConnect()
    if (!lastCheck || btwin > 1000) setLastCheck(Date.now())
  }

  const handleContinue = async e => {
    lastCheckConnect()
    e.preventDefault()
    errorDealing()
    if (!errorDealing()) {
      setOrder(formatOrder(order))
      return next()
    }
    return null
  }

  //Quill Toolbar module
  const modules = {
    toolbar: [["bold", "italic", "underline"]],
  }
  const formats = ["bold", "italic", "underline"]

  return (
    <>
      <Header1>Commande pour un patient</Header1>
      <PageContainer>
        <div className="flex items-center flex-col mb-20">
          <H2 green>Informations relatives au patient :</H2>
          <ErrorMessage ref={errorRef} errors={errors} />
          <div className="mb-inputToSubmit w-full flex flex-col">
            <div className="mb-mobile-ContainerYT tablet:mb-tablet-Container2YT laptop:mb-laptop-Container2YT">
              <Grid6>
                <ColSpanHalf>
                  <Label htmlFor="firstName">Prénom</Label>
                  <Input
                    autoFocus
                    type="text"
                    action={handleUser}
                    id="firstName"
                    name="firstName"
                    defaultValueInput={user.firstName}
                    isError={isError("firstName", errors)}
                  />
                </ColSpanHalf>
                <ColSpanHalf>
                  <Label htmlFor="lastName">Nom</Label>
                  <Input
                    type="text"
                    action={handleUser}
                    name="lastName"
                    id="lastName"
                    defaultValueInput={user.lastName}
                    isError={isError("lastName", errors)}
                  />
                </ColSpanHalf>
                <ColSpanHalf>
                  <Label htmlFor="email">Adresse email</Label>
                  <Input
                    type="text"
                    action={handleUser}
                    id="email"
                    name="email"
                    defaultValueInput={user.email}
                    isError={isError("email", errors)}
                  />
                </ColSpanHalf>
                <ColSpanHalf>
                  <Label htmlFor="phone">Numéro de téléphone</Label>
                  <PhoneInput
                    defaultCountry="BE"
                    type="tel"
                    onChange={handlePhone}
                    name="phone"
                    id="phone"
                    value={user.phone}
                    className={`focus:ring-0 w-full px-inputX py-inputY font-textFontFamily font-text-FontWeight text-primary text-textFontSize leading-input bg-transparent rounded-formSelect border-input ${
                      isError("phone", errors)
                        ? " border-cinquo focus:border-cinquo"
                        : " border-primary focus:border-primary"
                    }`}
                  />
                </ColSpanHalf>
                <ColSpanFull>
                  <Label htmlFor="deliveryAddress">Adresse de livraison</Label>
                  <SearchLocationInput
                    onChange={() => null}
                    setDeliveryAddress={address => setDeliveryAddress(address)}
                    deliveryAddress={deliveryAddress}
                    isError={isError("deliveryAddress", errors)}
                  />
                </ColSpanFull>
                {/*<ColSpanFull>
                  <Label htmlFor="deliveryInfo">Indications spécifiques destinées au livreur</Label>
                  <Input
                    type="text"
                    action={handleDeliveryInfo}
                    placeholder="Ex: Sonnette du haut/bte 4"
                    name="deliveryInfo"
                    id="deliveryInfo"
                    defaultValueInput={deliveryInfo}
                  />
                </ColSpanFull>*/}
              </Grid6>
              <H2 green>Posologie :</H2>
              <ColSpanFull>
                <Label htmlFor="posologie">Indications spécifiques</Label>
                <ReactQuill
                  value={posologieInfo}
                  placeholder="Ex: Des trucs"
                  onChange={handlePosologieInfo}
                  modules={modules}
                  formats={formats}
                />
              </ColSpanFull>
            </div>

            <H2 green>Informations relatives à la commande :</H2>
            <div className="mb-mobile-ContainerYT tablet:mb-tablet-Container2YT laptop:mb-laptop-Container2YT">
              <Grid6>
                <ColSpanHalf>
                  <Label htmlFor="orderReadyDate">
                    Date et heure à laquelle la commande sera prête
                  </Label>
                  <Input
                    type="datetime-local"
                    action={e => setOrderReadyDate(new Date(e.target.value))}
                    id="orderReadyDate"
                    name="orderReadyDate"
                    defaultValueInput={
                      isValid(orderReadyDate) ? format(orderReadyDate, "yyyy-MM-dd'T'HH:mm") : null
                    }
                    isError={isError("orderReadyDate", errors)}
                  />
                </ColSpanHalf>
                <div className="hidden tablet:contents">
                  <ColSpanHalf></ColSpanHalf>
                </div>
                {/*<ColSpanHalf>
                  <Label htmlFor="orderPaid">La commande a-t-elle déjà été payée ?</Label>
                  <Select
                    type="text"
                    name="orderPaid"
                    id="orderPaid"
                    onChange={e => handleOrderPaid(e)}
                    options={YesNo}
                    isSearchable={false}
                    defaultValue={orderPaid ? YesNo[0] : YesNo[1]}
                    placeholder={YesNo[1]}
                    className="shadow-0 w-full font-textFontFamily font-text-FontWeight text-primary text-textFontSize leading-input bg-transparent rounded-formSelect border-input border-primary"
                    components={{
                      DropdownIndicator,
                      Placeholder,
                      NoOptionsMessage,
                      IndicatorSeparator,
                    }}
                    styles={customStyles}
                  />
                </ColSpanHalf>
                {!orderPaid && (
                  <ColSpanHalf>
                    <Label htmlFor="price">Prix des produits</Label>
                    <Input
                      type="number"
                      min="0"
                      action={e => handlePrice(e)}
                      name="price"
                      id="price"
                      defaultValueInput={price}
                      isError={isError("price", errors)}
                    />
                  </ColSpanHalf>
                )}*/}
              </Grid6>
            </div>
            {/*{!orderPaid && (
              <div className="mb-mobile-Container2YT tablet:mb-tablet-Container2YT flex justify-between items-center">
                <CheckBox
                  type="checkbox"
                  action={() => setPharmacienPaysForDeliveryFee(!pharmacienPaysForDeliveryFee)}
                  value={pharmacienPaysForDeliveryFee}
                >
                  <p className="font-textFontFamily font-checkBoxLabel-FontWeight text-checkBoxLabel-FontSize text-primary leading-checkBoxLabel text-left">
                    Je prends en charge les frais de livraison de {PRICE_DELIVERY}€.
                  </p>
                </CheckBox>
              </div>
            )}*/}
            {/*<div className="mb-mobile-Container2YT tablet:mb-tablet-Container2YT flex justify-between items-center">
               <CheckBox
                type="checkbox"
                action={() =>
                  setDeliveryGuyMustGetPaperPrescription(!deliveryGuyMustGetPaperPrescription)
                }
                value={deliveryGuyMustGetPaperPrescription}
              >
                <p className="font-textFontFamily font-checkBoxLabel-FontWeight text-checkBoxLabel-FontSize text-primary leading-checkBoxLabel text-left">
                  Le livreur doit récupérer la prescription papier.
                </p>
              </CheckBox>
            </div>
            <PrimaryText>
              le patient devra encore payer un montant de{" "}
              <strong>{formatter.format(orderPrice())}</strong> pour sa commande
            </PrimaryText>*/}
          </div>
          <div className="flex items-center">
            <RoundedBtn action={handleContinue} name="confirmer" type="submit">
              Confirmer
            </RoundedBtn>
          </div>
        </div>
      </PageContainer>
    </>
  )
}
