import React, { useEffect, useRef, useState } from "react"

// Routes
import { Link } from "react-router-dom"
import { Url } from "../../routes/Routes"

// Configs
import { colorMode } from "../../Config/colorMode"

//packages
import { createFilter } from "react-select"
import AsyncSelect from "react-select/async"
import { useTranslation } from "react-i18next"

//back-end calls
import { PharmacyAPI } from "../../Backend/pharmacy"

// Services
import {
  formatAddress,
  formatAddressCityLine,
  formatAddressStreetLine,
} from "../../Services/AddressService"
import { addError, isError } from "../../Services/errors"

// Components
import Label from "../../components/DefineTagClass/Label"
import SecondaryText from "../../components/DefineTagClass/SecondaryText"
import RoundedBtn from "../../components/Btns/RoundedBtn"
import ClassicBtn from "../../components/Btns/ClassicBtn"
import PrimaryText from "../../components/DefineTagClass/PrimaryText"
import PharmacyCard3 from "../../components/Card/PharmacyCard3"

// Config
import SearchLocationInput from "../../Config/SearchLocationInput"
import ErrorMessage from "../../components/ErrorMessage"

// About Styles
import {
  DropdownIndicator,
  Placeholder,
  NoPharmaciesMessage,
  IndicatorSeparator,
  customBlueBgStyles,
  handleFocus,
} from "../../components/Selects/AsyncSelect"
import "../../style/components/select.scss"
import LoadingSpinner from "../../components/Loading"
import { Container, PageContainer } from "../../components/Container"
import H1 from "../../components/DefineTagClass/H1"
import H2 from "../../components/DefineTagClass/H2"
import Pharmacy from "../../components/Images/Pharmacy"

/**
 *
 * @param {{
 * nextStep: function,
 * toPrescriptionChoice: function,
 * setDeliveryAddress: function,
 * deliveryAddress: {},
 * setPharmacyChoice: function,
 * pharmacyChoice: {},
 * backToPharmacyNotEncodedYet: function,
 * personnalLink: boolean,
 * setPersonnalLink: function,
 * setNav: function;
 * nextStepIfPersonnalLink: function;
 * }} param0
 *
 * @author Lauwers Yann
 */

export default function WhereToDeliver({
  nextStep,
  setDeliveryAddress,
  deliveryAddress,
  setPharmacyChoice,
  pharmacyChoice,
  backToPharmacyNotEncodedYet,
  personnalLink,
  setNav,
  step,
  nextStepIfPersonnalLink,
}) {
  const { t } = useTranslation()
  const [displayPharmacyChoice, setDisplayPharmacyChoice] = useState(false)
  const [pharmacies, setPharmacies] = useState([])
  const [state, setState] = useState(1)
  const [errors, setErrors] = useState([])
  const pharmacySelectRef = useRef(null)
  const errorRef = useRef(null)

  const status = {
    hasNoPersonnalLink: false,
    hasPersonnalLink: true,
    //
    isNotLoading: 1,
    isLoading: 2,
  }

  // About Async Select of all the pharmacies
  // loads 350 first pharmacies fort the select dropdown
  useEffect(() => {
    loadPharmacies()
    // Uncomment next block of code to preset an address for you
    // setDeliveryAddress({
    //   streetNumber: 31,
    //   street: "rue du commerce",
    //   city: "Bruxelles",
    //   postalCode: 1000,
    //   country: "belgique",
    // })
    // setDeliveryAddress({
    //   streetNumber: 18,
    //   street: "rue des hayettes",
    //   city: "Falaen",
    //   postalCode: 5522,
    //   country: "belgique",
    // })
  }, [])

  useEffect(() => {
    if (displayPharmacyChoice) {
      pharmacySelectRef.current.focus()

    }
  }, [displayPharmacyChoice])

  const filterOptions = inputValue =>
    pharmacies.filter(i => i.name.toLowerCase().includes(inputValue.toLowerCase()))

  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterOptions(inputValue))
    }, 1000)
  }

  /**
   * load of pharmacies has been set to 350 so when you write the name of the biggest company
   * (which counts 350 pharmacies, u get all of them)
   * The best would have been to import 100 pharmacies because the database pages counts 100 pharmacies.
   * And so, the call only fetches the first page and it loads faster
   * */
  const loadPharmacies = async name => {
    try {
      const firstLoadPharmacies = await PharmacyAPI.getPharmacies({
        name,
        start: "",
        stop: 350,
      })
      setPharmacies(firstLoadPharmacies)
    } catch (error) {
      console.error(error)
    }
  }

  const handleInputChange = newValue => {
    const inputValue = newValue.replace(/\W/g, "")
    loadPharmacies(inputValue)
  }
  //

  const toggleButton = e => {
    e.preventDefault()
    setDisplayPharmacyChoice(true)
  }

  const handleError = async () => {
    const array = []

    const noAddress = "Veuillez entrer une adresse pour continuer."
    const noStreetNumber = "Veuillez entrer un numéro de rue pour continuer."
    const noStreet = "Veuillez entrer une rue pour continuer."
    const noCity = "Veuillez entrer une ville pour continuer."
    const noPostalCode = "Veuillez entrer un code postal pour continuer."
    const deliveryAddress_too_far =
      "L'adresse que vous avez entrée est malheureusement hors du périmètre de livraison de votre pharmacie."

    if (!Object.keys(deliveryAddress).length) {
      addError("deliveryAddress_missing", noAddress, array)
    } else if (!deliveryAddress.street) {
      addError("deliveryAddress_street_missing", noStreet, array)
    } else if (!deliveryAddress.streetNumber) {
      addError("deliveryAddress_streetNumber_missing", noStreetNumber, array)
    } else if (!deliveryAddress.city) {
      addError("deliveryAddress_city_missing", noCity, array)
    } else if (!deliveryAddress.postalCode) {
      addError("deliveryAddress_postalCode_missing", noPostalCode, array)
    } else {
      if (personnalLink) {
        const isAddressDeliverable = await PharmacyAPI.isPharmacyReachable(
          `${pharmacyChoice.id}`,
          formatAddress(deliveryAddress)
        )
        if (typeof isAddressDeliverable !== "boolean")
          addError("deliveryAddress_too_far", deliveryAddress_too_far, array)
      }
    }
    setErrors(array)
    return array.length
  }

  const ContinueBtn = () => {
    return (
      <RoundedBtn action={() => submit()} name="continuer" type="button">
        Continuer
      </RoundedBtn>
    )
  }

  const IsSending = () => {
    switch (state) {
      case status.isNotLoading:
        return <ContinueBtn />
      case status.isLoading:
        return <LoadingSpinner isGreen />
      default:
        return <h1>defaut</h1>
    }
  }

  /**
   *  this function is gonna check if the pharmacy chosen is member of smile
   *  It hasn't been done in the back yet. so we can't check yet
   *  */
  const submit = async () => {
    setState(status.isLoading)
    const errs = await handleError()
    setState(status.isNotLoading)
    if (!errs) {
      if (pharmacyChoice.name) {
        if (!pharmacyChoice.member) {
          backToPharmacyNotEncodedYet()
        } else if (personnalLink) {
          setNav(colorMode.blueBg)
          nextStepIfPersonnalLink()
        } else {
          setNav(colorMode.blueBg)
          nextStep()
        }
      } else {
        setNav(colorMode.blueBg)
        nextStep()
      }
    }
  }

  const WithPersonnalLink = () => {
    return (
      <div>
        <div className="mb-cartItemInsidePaddingX">
          <PrimaryText invert textPosition="text-center">
            Vous commandez actuellement dans la pharmacie
          </PrimaryText>
        </div>
        <PharmacyCard3
          img={<Pharmacy />}
          alt="delivery address icon"
          label={pharmacyChoice.name}
          isGreen={true}
          line1={formatAddressStreetLine(pharmacyChoice.address)}
          line2={formatAddressCityLine(pharmacyChoice.address)}
          line3={pharmacyChoice.companyEmail}
          line4={pharmacyChoice.companyPhone}
        />
        <div className="opacity-70 text-center">
          <Link
            to={Url.root}
            className="focus:outline-none font-textFontFamily text-inlineBtn-FontSize text-secondary leading-inlineBtn bg-transparent cursor-btn underline"
          >
            Vous desirez commander dans une autre pharmacie ?
          </Link>
        </div>
      </div>
    )
  }

  const NoPersonnalLink = () => {
    return displayPharmacyChoice ? null : (
      <ClassicBtn
        action={toggleButton}
        name="Vous avez une pharmacie de référence ?"
        type="button"
        invert
      >
        Vous avez une pharmacie de référence ?
      </ClassicBtn>
    )
  }
/**
 *
 * @returns
 */
  const IsItAPersonnalLink = () => {
    switch (personnalLink) {
      case status.hasNoPersonnalLink:
        return <NoPersonnalLink />
      case status.hasPersonnalLink:
        return <WithPersonnalLink />
      default:
        return <h1>Defaut</h1>
    }
  }

  return (
    <PageContainer color="primary">
      <H1 invert>Faites vous livrer <br />vos médicaments<br />par un voisin solidaire</H1>
      <Container>
        <H2 green>A quelle adresse devons-nous vous livrer ?</H2>
        <ErrorMessage ref={errorRef} errors={errors} />
        <form onSubmit={handleError} className="flex items-center flex-col">
          <div className="mb-inputToSubmit w-full flex flex-col">
            <Label invert>Exemple : Rue de la Liberté 26, Bruxelles, Belgique</Label>
            <SearchLocationInput
              onChange={() => null}
              setDeliveryAddress={setDeliveryAddress}
              deliveryAddress={deliveryAddress}
              isError={isError("deliveryAddress", errors)}
              autoFocus
              getLocation
            />
            <div
              className={`flex justify-center align-center flex-col ${
                displayPharmacyChoice ? "mt-inputToInput" : "hidden"
              }`}
            >
              <Label invert>Trouvez votre pharmacie en tapant son nom</Label>
              <AsyncSelect
                inputId="async-select-id"
                onFocus={handleFocus}
                ref={pharmacySelectRef}
                className="select"
                name="PharmacySelection"
                cacheOptions
                defaultOptions={pharmacies}
                value={pharmacyChoice}
                getOptionLabel={e => e.name}
                getOptionValue={e => e.id}
                loadOptions={loadOptions}
                onInputChange={handleInputChange}
                onChange={setPharmacyChoice}
                filterOption={createFilter({ ignoreAccents: false })}
                placeholder=""
                components={{
                  DropdownIndicator,
                  Placeholder,
                  NoOptionsMessage: NoPharmaciesMessage,
                  IndicatorSeparator,
                }}
                styles={customBlueBgStyles}
              />
              <SecondaryText margin invert>
                Si vous ne sélectionnez pas de pharmacie, vous devrez en choisir une parmi les plus
                proches du lieu de livraison.
              </SecondaryText>
            </div>
          </div>
          <div className="flex justify-center items-center flex-col">
            <div className="mb-submitToInstruction space-y-10">
              <IsSending />
              <IsItAPersonnalLink />
            </div>
          </div>
        </form>
      </Container>
    </PageContainer>
  )
}
