import { POST, PUT } from "./request"

/**
 * Create an order from a pharmacy
 * @author Yann Lauwers
 * @async
 *
 * @param {FeedBack} feedback Feedback
 *
 * @returns {Promise<FeedbackDTO>}
 */
const create = async feedback => {
  try {
    const res = await PUT("feedback", feedback)
    switch (res.status) {
      case 200: {
        const result = await res.json()
        return result
      }
      case 400:
      case 406:
      default: {
        const errorValue = await res.text()
        throw new Error(errorValue)
      }
    }
  } catch (err) {
    console.error(err)
  }
}

const update = async feedback => {
  try {
    const res = await POST("feedback", feedback)
    switch (res.status) {
      case 200: {
        const result = await res.json()
        return result
      }
      case 400:
      case 406:
      default: {
        const errorValue = await res.text()

        throw new Error(errorValue)
      }
    }
  } catch (err) {
    console.error(err)
  }
}

export const FeedbackAPI = { create, update }
