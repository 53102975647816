import { AddressDTO } from "./AddressDTO"

export class PharmacyDTO {
  id: String
  name: String
  status: String
  address: AddressDTO
  member: Boolean
  perimeter: Number
  companyName: String
  companyVAT: String
}
