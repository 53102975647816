import React from "react"

function PasswordIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="fill-current text-secondary"
      width="22"
      height="22"
      viewBox="0 0 11.34 11.34"
    >
      <g id="Calque_3" data-name="Calque 3">
        <circle className="cls-1" cx="5.62" cy="7.65" r="0.83" />
        <path
          className="cls-1"
          d="M9.39,4V.8A.81.81,0,0,0,8.58,0H2.66A.81.81,0,0,0,1.85.8V4A1.2,1.2,0,0,0,.72,5.17v4.95a1.21,1.21,0,0,0,1.21,1.21H9.39a1.21,1.21,0,0,0,1.21-1.21V5.17A1.21,1.21,0,0,0,9.39,4ZM2.66.8H8.58V4H2.66Zm7.13,9.32a.4.4,0,0,1-.4.4H1.93a.4.4,0,0,1-.4-.4V5.17a.4.4,0,0,1,.4-.4H9.39a.4.4,0,0,1,.4.4Z"
        />
      </g>
    </svg>
  )
}

function RevealPasswordIcon({ state }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current text-secondary ${state ? "" : "opacity-50"}`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M12.015 7c4.751 0 8.063 3.012 9.504 4.636-1.401 1.837-4.713 5.364-9.504 5.364-4.42 0-7.93-3.536-9.478-5.407 1.493-1.647 4.817-4.593 9.478-4.593zm0-2c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 3c-2.21 0-4 1.791-4 4s1.79 4 4 4c2.209 0 4-1.791 4-4s-1.791-4-4-4zm-.004 3.999c-.564.564-1.479.564-2.044 0s-.565-1.48 0-2.044c.564-.564 1.479-.564 2.044 0s.565 1.479 0 2.044z" />
    </svg>
  )
}

function UserIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="fill-current text-secondary"
      width="22"
      height="22"
      viewBox="0 0 11.34 11.34"
    >
      <g id="Calque_2" data-name="Calque 2">
        <path
          className="cls-1"
          d="M8.08,5.79a3.37,3.37,0,1,0-4.67,0C.56,7.15.89,10.93.9,11a.42.42,0,0,0,.43.38h8.83a.42.42,0,0,0,.43-.38S10.93,7.15,8.08,5.79ZM5.74.88a2.51,2.51,0,0,1,2.51,2.5,2.42,2.42,0,0,1-.09.6l0,.12a3,3,0,0,1-.21.49l0,0a2.53,2.53,0,0,1-.91.9H7a2.44,2.44,0,0,1-1.23.34,2.47,2.47,0,0,1-1.23-.34h0a2.53,2.53,0,0,1-.91-.9l0,0a2.33,2.33,0,0,1-.21-.49s0-.08,0-.12a2.41,2.41,0,0,1-.08-.6A2.5,2.5,0,0,1,5.74.88Zm-4,9.61c0-1,.23-3.39,2.52-4.11a3.37,3.37,0,0,0,1.48.36,3.38,3.38,0,0,0,1.49-.36c2.29.72,2.52,3.15,2.52,4.11Z"
        />
      </g>
    </svg>
  )
}

export { PasswordIcon, RevealPasswordIcon, UserIcon }
