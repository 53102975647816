import React from "react"

import Input from "../Inputs/Input"
import RoundedBtn from "../Btns/RoundedBtn"
import H3 from "../DefineTagClass/H3"
import EditSummaryNavbar from "../navbar/EditSummaryNavbar"

/**
 *
 * @param {{
 * navbarAction: function,
 * titleText: string,
 * btnName: string,
 * inputName: string,
 * inputDefaultValue: string,
 * info: string,
 * setEditableItem: function,
 * handleEdit: function,
 * }} param0
 *
 * @author Lauwers Yann
 */

//info is gonna be use when we will put address edit

export default function EditSelection({
  navbarAction,
  titleText,
  btnName,
  inputName,
  inputDefaultValue,
  info,
  setEditableItem,
  handleEdit,
}) {
  const handleEditInput = e => {
    const input = e.target.value
    if (input !== "") {
      setEditableItem(input)
    }
  }

  return (
    <div className="bg-secondary w-full h-screen absolute top-0">
      <EditSummaryNavbar action={navbarAction} />
      <div className="bg-secondary w-full h-full">
        <div className="pt-mobile-Container2YT mx-mobile-ContainerX tablet:mx-auto tablet:w-tabletW laptop:mx-auto laptop:w-laptopW">
          <div className="mb-inputToSubmit w-full flex flex-col">
            <H3 hasMarginB>{titleText}</H3>
            <Input
              type="text"
              name={inputName}
              action={handleEditInput}
              defaultValueInput={inputDefaultValue}
            />
          </div>
          <div className="flex items-center justify-center">
            <div className="w-max">
              <RoundedBtn type="submit" name={btnName} action={handleEdit}>
                Valider
              </RoundedBtn>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
