import { Link } from "react-router-dom"
import { Container, PageContainer } from "../components/Container"
import FeedbackModule from "../components/Feedback/FeedbackModule"
import { Url } from "../routes/Routes"

export default function Feedback() {
  const feedbackKeyFromUrl = window.location.href.split("feedback/")
  return (
    <PageContainer>
      <div className="mt-10">
        <Container>
          <div className="flex justify-center flex-col items-center space-y-14">
            <FeedbackModule feedbackKey={feedbackKeyFromUrl[1]}>
              Nous avons besoin de vous
            </FeedbackModule>
            <Link to={Url.root}>Envoyer</Link>
          </div>
        </Container>
      </div>
    </PageContainer>
  )
}
