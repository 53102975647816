import React from "react"

export default function Logout() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="fill-none"
      width="24"
      height="24"
      viewBox="0 0 264.85 291.51"
    >
      <g id="ic-user-interface-upload-2">
        <path
          className="stroke-current stroke-edit text-primary"
          d="M347.57,400.28H523.92"
          transform="translate(-267.58 -254.25)"
        />
        <path
          className="stroke-current stroke-edit text-primary"
          d="M468.82,345.18l55.1,55.1-55.1,55.1"
          transform="translate(-267.58 -254.25)"
        />
        <path
          className="stroke-current stroke-edit text-primary"
          d="M367.76,262.75H299.05a22.86,22.86,0,0,0-23,22.75V514.94a22.86,22.86,0,0,0,23,22.31h68.71"
          transform="translate(-267.58 -254.25)"
        />
      </g>
    </svg>
  )
}
