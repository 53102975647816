import { useState } from "react"
import StarsFeedbackModule from "./StarsFeedbackModule"
import WrittenFeedbackModule from "./WrittenFeedbackModule"
import H2 from "../DefineTagClass/H2"

const endRange = 6

export default function FeedbackModule({ children, feedbackNote, feedbackKey = "" }) {
  const [feedbackData, setFeedbackData] = useState({})
  const [isRatedWithStars, setIsRatedWithStars] = useState(false)
  return (
    <div className="w-full max-w-feedbackModule flex flex-col items-center">
      <H2 isCentered noPadding>
        {children}
      </H2>
      <div className="space-y-6 w-full">
        <StarsFeedbackModule
          setIsRatedWithStars={setIsRatedWithStars}
          setFeedbackData={setFeedbackData}
          feedbackData={feedbackData}
          feedbackNote={feedbackNote}
          endRange={endRange}
          feedbackKey={feedbackKey}
        />
        {isRatedWithStars 
        //&& (
          //<WrittenFeedbackModule feedbackData={feedbackData} setFeedbackData={setFeedbackData} />
        //)
        }
      </div>
    </div>
  )
}
