import React from "react"
import Cross from "../Images/Cross"

/**
 *
 * @param {{
 * hideCart: function,
 * }} param0
 *
 * @author Lauwers Yann
 */

export default function EditSummaryNavbar({ action }) {
  return (
    <div className="flex justify-center bg-secondary h-navbarHeight">
      <div className="relative flex items-center justify-left w-computerWidth ml-mobile-ContainerX tablet:mx-auto tablet:w-tabletW laptop:mx-auto laptop:w-laptopW">
        <button onClick={action} className="focus:outline-none">
          <Cross />
        </button>
      </div>
    </div>
  )
}
