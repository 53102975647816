import React from "react"

export function Grid6({ children }) {
  return (
    <div className="grid grid-cols-1 gap-y-6 gap-x-inputToInput tablet:grid-cols-6">{children}</div>
  )
}

export function ColSpanHalf({ children }) {
  return <div className="tablet:col-span-3">{children}</div>
}

export function ColSpanFull({ children }) {
  return <div className="tablet:col-span-6">{children}</div>
}

export function ColSpanThird({ children }) {
  return <div className="tablet:col-span-2">{children}</div>
}

export function ColSpanTwoThird({ children }) {
  return <div className="tablet:col-span-4">{children}</div>
}
