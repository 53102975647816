export const isError = (name, array) => {
  return array.some(err => err.name.split("_")[0] === name)
}

export const addError = (name, error, array) => {
  array.push({ name, error })
}

export const scrollToError = ref => {
  if (!ref.current) return
  let yPosition = ref.current.offsetTop
  yPosition -= 20
  window.scrollTo(0, yPosition)
}
