import React from "react"
import Loading from "./Images/Loading"

/**
 *
 * @param {{
 * isGreen: boolean,
 * isBlue: boolean,
 * isWhite: boolean,
 * }} param0
 *
 * @author Lauwers Yann
 */

export default function LoadingSpinner({ isGreen = false, isBlue = false, isWhite = false }) {
  let className = "animate-spin fill-current"
  className += isGreen ? " text-ternary" : ""
  className += isBlue ? " text-primary" : ""
  className += isWhite ? " text-secondary" : ""

  return (
    <div className="w-full flex justify-center">
      <Loading className={className} />
    </div>
  )
}
