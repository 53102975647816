import {GET, POST} from "./request"

/**
 * This function tells you if a user can log. Else an error is throwed
 *
 * @returns {Promise<UserDTO[]>}
 */
export const login = async obj => {
  const response = await POST(`user/login`, obj)
  const json = await response.json()
  return json
}

/**
 * This function tells you if a user is logged
 *
 * @returns {Promise<UserDTO[]>}
 */
export const isLoggedIn = async () => {
  const response = await GET(`user/`)
  if (response.status !== 401) {
    const json = await response.json();
    return json
  }

  return false;
}

/**
 * This function tells you if a user is logged
 *
 * @returns {Promise<UserDTO[]>}
 */
export const isAuth = async () => {
  const response = await GET(`user/`)
  const json = await response.json()
  if (json) return true
  return false
}

/**
 * This function logs a user out
 *
 * @returns {Promise<UserDTO[]>}
 */
export const logout = async () => {
  const response = await POST(`user/logout`)
  const json = await response.json()
  localStorage.clear()
  return json
}
