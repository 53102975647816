import React, {useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";

// Packages
import Select from "react-select";
import Cleave from "cleave.js/react";

// Back-end
import {OrderAPI} from "../../Backend/order";

// Models
import {OrderDTO} from "../../Models/OrderDTO";

// Select
import {
  DropdownIndicator,
  Placeholder,
  NoOptionsMessage,
  IndicatorSeparator,
  customStyles,
} from "../../components/Selects/Select";
import {YesNo} from "../../Config/YesNoSelect";
import "../../style/components/select.scss";

// Config
import {colorMode} from "../../Config/colorMode";

// Services
import {addError, isError, scrollToError} from "../../Services/errors";

// Phone validation
import PhoneInput from "react-phone-number-input/input";
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";

// Components
import RoundedBtn from "../../components/Btns/RoundedBtn";
import RoundedBtnEmptyBlueBg from "../../components/Btns/RoundedBtnEmptyBlueBg";
import Input from "../../components/Inputs/Input";
import CheckBox from "../../components/Inputs/CheckBox";
import ErrorMessage from "../../components/ErrorMessage";
import SecondaryText from "../../components/DefineTagClass/SecondaryText";
import Label from "../../components/DefineTagClass/Label";
import PrimaryText from "../../components/DefineTagClass/PrimaryText";
import FullyRoundedBackBtn from "../../components/Btns/FullyRoundedBackBtn";
import LinkBtn from "../../components/Btns/LinkBtn";
import H1 from "../../components/DefineTagClass/H1";
import {Container, PageContainer} from "../../components/Container";
import H2 from "../../components/DefineTagClass/H2";
import {isRegExp, isUndefined, replace} from "lodash";


const idNumberLength = 11; // the belgian national register number is 11 digits long
const idNumCardLength = 12; // the belgian national card number is 12 digits long

/**
 *
 * @param {{
 * nextStep: function,
 * prevStep: function,
 * readAndSign: boolean,
 * isPrescriptionIdCard: boolean,
 * isPrescriptionMedecin: boolean,
 * valueMandataire: boolean,
 * setReadAndSign: function,
 * mandataire: boolean,
 * setMandataire: function,
 * setPharmacyCall: function,
 * user: {},
 * MandataireObject: {},
 * setUser: function,
 * setMandataireObject: function,
 * errorRef: string,
 * deliveryAddress: {},
 * pharmacyChoice: {},
 * questionToPharmacien: string,
 * pharmacyCall: boolean,
 * noPrescriptions: [],
 * electronicPrescriptions: [],
 * paperPrescriptions: [],
 * deliveryInfo: string,
 * setDeliveryInfo: function,
 * setNav: function,
 * setOrderHash: function,
 * nameMandat: string,
 * lastNameMandat: string,
 * phoneMandat: string,
 * emailMandat: string,
 *
 * }} param0
 *
 * @author Lauwers Yann
 */

export default function PersonnalDetails({
                                           nextStep,
                                           prevStep,
                                           readAndSign,
                                           setReadAndSign,
                                           mandataire,
                                           mandataireChoice,
                                           pharmacyCall,
                                           setPharmacyCall,
                                           booleanForMandatSelection,
                                           setBooleanForMandatSelection,
                                           user,
                                           setUser,
                                           MandataireObject,
                                           setMandataireObject,
                                           deliveryAddress,
                                           pharmacyChoice,
                                           questionToPharmacien,
                                           noPrescriptions,
                                           isPrescriptionIdCard,
                                           isPrescriptionMedecin,
                                           electronicPrescriptions,
                                           paperPrescriptions,
                                           deliveryInfo,
                                           setDeliveryInfo,
                                           setNav,
                                           setOrderHash,
                                           toChooseMandataire,
                                         }) {
  const [errors, setErrors] = useState([]);
  const errorRef = useRef(null);
  const [valueMandataireYourSelf, setValueMandataireYourSelf] = useState();
  const [confirmPmr, setConfirmPmr] = useState(false);

  useEffect(() => {
    scrollToError(errorRef);
  }, [errors.length, errorRef?.current?.offSetHeight]);

  const handleOrderHash = (hash) => {
    setOrderHash(hash);
  };

  /**
   * Post order to database
   */
  const sendOrderToBackEnd = async () => {
    try {
      const order = new OrderDTO();
      order.pharmacy = {
        id: pharmacyChoice.id,
      };

      order.prescription = {
        digital: electronicPrescriptions,
        paper: paperPrescriptions,
        medicines: noPrescriptions,
      };
      order.user = {
        name: user.name,
        lastname: user.lastname,
        email: user.email,
        phone: user.phone,
        idNumber: user.idNumber,
        idNumCard: user.idNumCard,
      };
      order.address = {
        streetNumber: deliveryAddress.streetNumber,
        street: deliveryAddress.street,
        city: deliveryAddress.city,
        postalCode: deliveryAddress.postalCode,
        country: deliveryAddress.country,
      };
      order.wantToBeCalled = pharmacyCall;
      order.comment = questionToPharmacien;
      order.deliveryInfo = deliveryInfo;
      order.hasMandataire = !!(booleanForMandatSelection && mandataireChoice ? mandataireChoice.name : MandataireObject.name);
      order.isPrescriptionIdCard = isPrescriptionIdCard;
      order.isPrescriptionMedecin = isPrescriptionMedecin;

      order.mandataire = {
        name: mandataireChoice ? mandataireChoice.name : MandataireObject.name,
        lastname: mandataireChoice
          ? mandataireChoice.lastname
          : MandataireObject.lastname,
        email: mandataireChoice
          ? mandataireChoice.email
          : MandataireObject.email,
        phone: mandataireChoice
          ? mandataireChoice.phone
          : MandataireObject.phone,
      };

      const response = await OrderAPI.create(order, readAndSign);
      return handleOrderHash(response.orderHash);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUser = (e) => {
    const value = e.target.value;
    setUser({
      ...user,
      [e.target.name]: value,
    });
  };

  const handleMandataire = (e) => {
    const value = e.target.value;
    setMandataireObject({
      ...MandataireObject,
      [e.target.name]: value,
    });
  };

  const handlePhoneMandataire = (value) => {
    setMandataireObject({
      ...MandataireObject,
      phone: value,
    });
  };
  const handleNameMandataire = (e) => {
    setMandataireObject({
      ...MandataireObject,
      name: e.target.value,
    });
  };
  const handleLastnameMandataire = (e) => {
    setMandataireObject({
      ...MandataireObject,
      lastname: e.target.value,
    });
  };
  const handleEmailMandataire = (e) => {
    setMandataireObject({
      ...MandataireObject,
      email: e.target.value,
    });
  };

  const handlePhone = (value) => {
    setUser({
      ...user,
      phone: value,
    });
  };

  const handleIdNumber = (e) => {
    // raw value
    setUser({
      ...user,
      idNumber: e.target.rawValue,
    });
  };

  const handleIdNumCard = (e) => {
    // raw value
    setUser({
      ...user,
      idNumCard: e.target.rawValue,
    });
  };

  const handleMandataireToggle = (e) => {
    setBooleanForMandatSelection(!booleanForMandatSelection);
    setValueMandataireYourSelf(booleanForMandatSelection);
  }

  const handleMandataireYourSelfToggle = (e) => {
    setValueMandataireYourSelf(!valueMandataireYourSelf);
    setBooleanForMandatSelection(valueMandataireYourSelf);
  }

  /**
   * The next function handles the message error you'll get when trying to complete the form
   */
  const errorDealing = () => {
    const array = [];
    // eslint-disable-next-line no-useless-escape
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const lastname_missing = "Veuillez entrer un prénom";
    const name_missing = "Veuillez entrer un nom de famille";
    const email_missing = "Veuillez entrer une adresse email";
    const email_incorrect = "Veuillez entrer une adresse email valide";
    const phone_missing = "Veuillez entrer un numéro de téléphone";
    const phone_incorrect = "Veuillez entrer un numéro de téléphone valide";

    // errors for mandataire fields
    const phoneMandataire_missing =
      "Veuillez entrer un numéro de téléphone pour votre mandataire";
    const phoneMandataire_incorrect =
      "Veuillez entrer un numéro de téléphone valide pour votre mandataire";
    const nameMandataire_missing =
      "Veuillez entrer un nom de famille pour votre mandataire";
    const lastnameMandataire_missing =
      "Veuillez entrer un prénom  pour votre mandataire";
    const emailMandataire_missing =
      "Veuillez entrer une adresse email pour votre mandataire";
    const emailMandataire_incorrect =
      "Veuillez entrer une adresse email valide pour votre mandataire";

    const idNumber_missing =
      "Veuillez entrer votre numéro de registre national";
    const idNumber_incorrect =
      "Veuillez entrer un numéro de registre national valide";
    const idNumCard_missing = "Veuillez entrer un numéro de carte d'identité";
    const idNumCard_incorrect =
      "Veuillez entrer un numéro de carte d'identité valide";
    let confirmPmr_missing = "Veuillez confirmer que vous êtes bien dans l'incapacité de vous déplacer"
    let readAndSign_missing, mandataire_missing;
    readAndSign_missing = mandataire_missing =
      "Veuillez cocher le(s) champ(s) manquant(s) avant de continuer";

    if (booleanForMandatSelection === true && confirmPmr === false)
      addError("confirmPmr_missing", confirmPmr_missing, array);

    if (!user.name) addError("name_missing", name_missing, array);
    if (!user.lastname) addError("lastname_missing", lastname_missing, array);
    if (!user.email) addError("email_missing", email_missing, array);

    if (booleanForMandatSelection && !MandataireObject.phone) {
      addError("phoneMandataire_missing", phoneMandataire_missing, array);
    }

    if (
      (booleanForMandatSelection && !MandataireObject.name)
    ) {
      addError("nameMandataire_missing", nameMandataire_missing, array);
    }

    if (
      (booleanForMandatSelection && !MandataireObject.lastname)
    )
      addError("lastnameMandataire_missing", lastnameMandataire_missing, array);
    if (
      (booleanForMandatSelection && !MandataireObject.email)
    )
      addError("emailMandataire_missing", emailMandataire_missing, array);
    if (MandataireObject.email && !re.test(MandataireObject.email))
      addError("emailMandataire_incorrect", emailMandataire_incorrect, array);

    if (user.email && !re.test(user.email))
      addError("email_incorrect", email_incorrect, array);

    if (!user.phone)
      addError("phone_missing", phone_missing, array);
    if (
      user.phone &&
      !isPossiblePhoneNumber(user.phone) &&
      !isValidPhoneNumber(user.phone) &&
      !isUndefined(user.phone) &&
      user.phone.length !== 12
    ) {
      addError("phone_incorrect", phone_incorrect, array);
    }
    if (isUndefined(user.phone) || user.phone.length !== 12) {
      addError("phone_incorrect", phone_incorrect, array);
    }

    //if (paperPrescriptions.length) {
    //  if (!user.idNumber) addError("idNumber_missing", idNumber_missing, array);
    //  if (user.idNumber?.length !== idNumberLength)
    //    addError("idNumber_incorrect", idNumber_incorrect, array);
    //}

    //if (isPrescriptionIdCard || isPrescriptionMedecin) {
      // if (!user.idNumber) addError("idNumber_missing", idNumber_missing, array);
      // if (user.idNumber?.length !== idNumberLength)
      //   addError("idNumber_incorrect", idNumber_incorrect, array);
      // if (!user.idNumCard)
      //   addError("idNumCard_missing", idNumCard_missing, array);
      // if (user.idNumCard?.length !== idNumCardLength)
      //   addError("idNumCard_incorrect", idNumCard_incorrect, array);
      // if (!user.idNumber) addError("idNumber_missing", idNumber_missing, array);
      // if (user.idNumber?.length !== idNumberLength)
      //   addError("idNumber_incorrect", idNumber_incorrect, array);
    //}

    if (!readAndSign) {
      addError("readAndSign_missing", readAndSign_missing, array);
    }
    setErrors(array);
    return array.length;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    errorDealing();
    if (!errorDealing()) {
      sendOrderToBackEnd();
      nextStep();
    }
  };

  return (
    <>
      <H1>Validation de la commande</H1>
      <PageContainer>
        <div className="pb-32">
          <Container>
            <H2 green>Informations personnelles du patient:</H2>
            <ErrorMessage ref={errorRef} errors={errors}/>
            <form
              onSubmit={handleSubmit}
              className="flex items-center flex-col"
            >
              <div className="mb-inputToSubmit w-full flex flex-col">
                <div className="mb-inputToInput border-primary">
                  <Label htmlFor="name">Prénom</Label>
                  <Input
                    autoFocus
                    type="text"
                    action={handleUser}
                    name="name"
                    id="name"
                    defaultValueInput={user.name}
                    isError={isError("name", errors)}
                  />
                </div>
                <div className="mb-inputToInput border-primary">
                  <Label htmlFor="lastname">Nom</Label>
                  <Input
                    type="text"
                    action={handleUser}
                    name="lastname"
                    id="lastname"
                    defaultValueInput={user.lastname}
                    isError={isError("lastname", errors)}
                  />
                </div>
                <div className="mb-inputToInput border-primary">
                  <Label htmlFor="email">Adresse email</Label>
                  <Input
                    type="text"
                    action={handleUser}
                    name="email"
                    id="email"
                    defaultValueInput={user.email}
                    isError={isError("email", errors)}
                  />
                </div>
                <div className="mb-inputToInput border-primary">
                  <Label htmlFor="phone">Numéro de téléphone</Label>
                  <PhoneInput
                    defaultCountry="BE"
                    type="tel"
                    onChange={handlePhone}
                    name="phone"
                    id="phone"
                    value={user.phone}
                    className={`focus:ring-0 w-full px-inputX py-inputY font-textFontFamily font-text-FontWeight text-primary text-textFontSize leading-input bg-transparent rounded-formSelect border-input ${
                      isError("phone", errors)
                        ? " border-cinquo focus:border-cinquo"
                        : " border-primary focus:border-primary"
                    }`}
                  />
                  <SecondaryText margin>
                    Si vous avez une numéro de téléphone étranger, veuillez
                    entrer le préfixe de votre pays suivi du numéro
                  </SecondaryText>
                </div>
                <div className="mb-inputToInput border-primary">
                  <Label htmlFor="idNumber">Numéro de registre national</Label>
                  <Cleave
                    id="idNumber"
                    onChange={handleIdNumber}
                    value={user.idNumber}
                    className={`focus:ring-0 w-full px-inputX py-inputY font-textFontFamily font-text-FontWeight text-primary text-textFontSize leading-input bg-transparent rounded-formSelect border-input ${
                      isError("idNumber", errors)
                        ? " border-cinquo focus:border-cinquo"
                        : " border-primary focus:border-primary"
                    }`}
                  />
                  <SecondaryText margin>
                    (facultatif si vous n’avez pas de commande avec
                    prescription)
                  </SecondaryText>
                </div>

                <div className="mb-inputToInput border-primary">
                  <Label htmlFor="idNumCard">Numéro de carte d'identité</Label>
                  <Cleave
                    id="idNumCard"
                    name="idNumCard"
                    options={{
                      blocks: [3, 7, 2],
                      delimiters: ["-", "-"],
                      numericOnly: true,
                    }}
                    onChange={handleIdNumCard}
                    value={user.idNumCard}
                    isError={isError("email", errors)}
                    className={`focus:ring-0 w-full px-inputX py-inputY font-textFontFamily font-text-FontWeight text-primary text-textFontSize leading-input bg-transparent rounded-formSelect border-input ${
                      isError("idNumCard", errors)
                        ? " border-cinquo focus:border-cinquo"
                        : " border-primary focus:border-primary"
                    }`}
                  />
                  <SecondaryText margin>
                    (facultatif si vous n’avez pas de commande avec
                    prescription)
                  </SecondaryText>
                </div>
                <div className="mb-inputToInput border-primary">
                  <Label htmlFor="pharmacienCall">
                    Souhaitez-vous que le pharmacien vous téléphone pour
                    discuter de votre commande ?
                  </Label>
                  <Select
                    type="text"
                    name="PharmacienCall"
                    id="PharmacienCall"
                    onChange={setPharmacyCall}
                    isSearchable={false}
                    options={YesNo}
                    defaultValue={YesNo[1]}
                    placeholder={YesNo[1]}
                    className="select w-full font-textFontFamily font-text-FontWeight text-primary text-textFontSize leading-input bg-transparent rounded-formSelect border-input border-primary"
                    components={{
                      DropdownIndicator,
                      Placeholder,
                      NoOptionsMessage,
                      IndicatorSeparator,
                    }}
                    styles={customStyles}
                  />
                  <SecondaryText margin>
                    Le pharmacien vous téléphonera de toute façon s’il a des
                    questions.
                  </SecondaryText>
                </div>
                {/*<div className="mb-inputToInput border-primary">
                  <Label htmlFor="deliveryInfo">Indication de livraison</Label>
                  <Input
                    type="text"
                    action={e => setDeliveryInfo(e.target.value)}
                    placeholder="Ex: Sonnette du haut/bte 4"
                    name="deliveryInfo"
                    id="deliveryInfo"
                    defaultValueInput={deliveryInfo}
                  />
                </div>
                <div className="mb-inputToInput">
                  <PrimaryText>
                    Lors du traitement de votre commande, votre pharmacie vous transmettra le
                    montant de celle-ci. €5 de frais de livraison sont généralement comptés par le
                    pharmacien, en plus du prix de vos produits.
                  </PrimaryText>
                </div>*/}
                <div className="flex flex-row items-center mb-10 ">
                  <div className="app items-center">
                    <CheckBox
                      type="checkbox"
                      defaultChecked={"checked"}
                      action={handleMandataireToggle}
                      value={booleanForMandatSelection}
                      name="booleanForMandatSelection"
                    >
                      <Label green={true} htmlFor="yourself">
                        Je demande a un voisin de me livrer
                      </Label>
                    </CheckBox>
                  </div>
                </div>

                <div className="flex flex-row items-center mb-10 ">
                  <div className="app items-center">
                    <CheckBox
                      type="checkbox"
                      action={handleMandataireYourSelfToggle}
                      value={valueMandataireYourSelf}
                      name="valueMandataireYourSelf"
                    >
                      <Label green={true} htmlFor="yourself">
                        Je vais chercher la commande à la pharmacie
                      </Label>
                    </CheckBox>
                  </div>
                </div>
                <div>
                  {booleanForMandatSelection ? (
                    <>
                      <RoundedBtnEmptyBlueBg
                        action={toChooseMandataire}
                        name="choisir un mandataire"
                        type="submit"
                      >
                        Choisir un voisin
                      </RoundedBtnEmptyBlueBg>
                      <H2 green>
                        Les informations personnelles du voisin :{" "}
                      </H2>

                      <div className="mb-inputToInput border-primary">
                        <Label htmlFor="nameM">Nom</Label>
                        <Input
                          autoFocus
                          type="text"
                          action={handleNameMandataire}
                          name="nameM"
                          id="nameM"
                          // setDisable={booleanForMandatSelection}
                          defaultValueInput={
                            booleanForMandatSelection
                              ? mandataireChoice.name
                              : ""
                          }
                          //defaultValueInput={MandataireObject.name}
                          isError={isError("nameM", errors)}
                        />
                      </div>

                      <div className="mb-inputToInput border-primary">
                        <Label htmlFor="lastnameM">Prénom</Label>
                        <Input
                          autoFocus
                          type="text"
                          action={handleLastnameMandataire}
                          name="lastnameM"
                          id="lastnameM"
                          // setDisable={booleanForMandatSelection}
                          //value = {mandataireChoiceLastName}
                          defaultValueInput={
                            booleanForMandatSelection
                              ? mandataireChoice.lastname
                              : ""
                          }
                          // defaultValueInput={MandataireObject.lastName}
                          isError={isError("lastnameM", errors)}
                        />
                      </div>
                      <div className="mb-inputToInput border-primary">
                        <Label htmlFor="emailM">Adresse email</Label>
                        <Input
                          type="text"
                          action={handleEmailMandataire}
                          name="emailM"
                          id="emailM"
                          // setDisable={booleanForMandatSelection}
                          defaultValueInput={
                            booleanForMandatSelection
                              ? mandataireChoice.email
                              : ""
                          }
                          //defaultValueInput={MandataireObject.email}
                          isError={isError("emailM", errors)}
                        />
                        <SecondaryText margin>
                          Le mandataire recevra également un mail regroupant les
                          informations de la commande
                        </SecondaryText>
                      </div>

                      <div className="mb-inputToInput border-primary">
                        <Label htmlFor="phoneM">Numéro de téléphone</Label>
                        <PhoneInput
                          defaultCountry="BE"
                          type="tel"
                          onChange={handlePhoneMandataire}
                          name="phoneM"
                          id="phoneM"
                          // setDisable = {booleanForMandatSelection}
                          value={
                            booleanForMandatSelection
                              ? mandataireChoice.phone
                              : ""
                          }
                          // defaultValueInput ={ MandataireObject.phone }
                          className={`focus:ring-0 w-full px-inputX py-inputY font-textFontFamily font-text-FontWeight text-primary text-textFontSize leading-input bg-transparent rounded-formSelect border-input ${
                            isError("phoneM", errors)
                              ? " border-cinquo focus:border-cinquo"
                              : " border-primary focus:border-primary"
                          }`}
                        />
                        <SecondaryText margin>
                          Si vous avez une numéro de téléphone étranger,
                          veuillez entrer le préfixe de votre pays suivi du
                          numéro
                        </SecondaryText>
                      </div>
                      <div className="flex">
                        <CheckBox
                          type="checkbox"
                          action={() => setConfirmPmr(!confirmPmr)}
                          value={confirmPmr}
                          name="confirmPmr"
                        >
                          <p className="pb-10 mb-0 ">Je confirme que je suis dans l’incapacité de me déplacer.</p>
                        </CheckBox>
                      </div>
                    </>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div>
                  {valueMandataireYourSelf ? (
                    <div></div>
                  ) : (
                    <div></div>
                  )}
                </div>

                <div className="flex flex-col items-center">
                  <CheckBox
                    type="checkbox"
                    action={() => setReadAndSign(!readAndSign)}
                    value={readAndSign}
                    name="readAndSign"
                    isError={isError("readAndSign", errors)}
                  >
                    <p>
                      J’ai lu et j’accepte les{" "}
                      <a
                        className="text-conditionsBtn"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.joinsmile.org/conditions-generales"
                      >
                        Conditions Générales d'Utilisation
                      </a>{" "}
                      et la{" "}
                      <a
                        className="text-conditionsBtn"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.joinsmile.org/politique-de-confidentialite"
                      >
                        Politique de Confidentialité
                      </a>{" "}
                      de Smile.
                    </p>
                    <br></br>
                    <p>
                      Je sais que Smile (nom commercial de la société
                      Smile ASBL) collecte et traite mes données personnelles
                      pour m'offrir ses services, et que ces données peuvent
                      être transmises à des pharmacies et livreurs partenaires
                      dans la mesure où elles sont nécessaires à la réalisation
                      et au suivi de ma commande.
                    </p>
                  </CheckBox>
                </div>

                {/*<div className="flex justify-between items-center">
                  <CheckBox
                    type="checkbox"
                    action={() => setMandataire(!mandataire)}
                    value={mandataire}
                    name="mandataire"
                    isError={isError("mandataire", errors)}
                  >
                    J’accepte de désigner le livreur de Smile comme mandataire pour la livraison
                    de ma commande.
                  </CheckBox>
                </div>*/}
              </div>
              <div className="flex items-center justify-center">
                <div className="mr-spaceBetweenBackAndContinue">
                  <FullyRoundedBackBtn
                    action={prevStep}
                    name="retour"
                    type="button"
                    size={48}
                  />
                </div>
                <RoundedBtn
                  action={handleSubmit}
                  name="continuer"
                  type="submit"
                >
                  Envoyer
                </RoundedBtn>
              </div>
            </form>
          </Container>
        </div>
      </PageContainer>
    </>
  );
}
