import React, { useEffect, useRef, useState } from "react"

// Config
import { colorMode } from "../../Config/colorMode"

// Services
import { addError, isError } from "../../Services/errors"

import TranslateUp from "../../components/Transitions/TranslateUp"
import { CardPaperPrescription } from "../../components/Card/DrugCards"
import FileUploader from "../../components/FileUploader/FileUploader"
import ErrorMessage from "../../components/ErrorMessage"
import H3 from "../../components/DefineTagClass/H3"
import RoundedBtn from "../../components/Btns/RoundedBtn"
import BtnFixedBottom from "../../components/Btns/BtnFixedBottom"
import FullyRoundedBackBtn from "../../components/Btns/FullyRoundedBackBtn"
import SecondaryText from "../../components/DefineTagClass/SecondaryText"
import H1 from "../../components/DefineTagClass/H1"
import { ContainerWithButtonFixedBottom, PageContainer } from "../../components/Container"
import H2 from "../../components/DefineTagClass/H2"
import FileUploaderIcon from "../../components/Images/FileUploader"

/**
 *
 * @param {{
 * step: number,
 * paperPrescriptions: [],
 * setPaperPrescriptions: function,
 * removeItem: function,
 * backToPrescriptionChoice: function,
 * backToChooseYourCommandType: function,
 * cartValue: variable,
 * showCart: function,
 * orderSummary: component,
 * I dont know what to say for ordersummary: because it's a component..
 * displayCart: function,
 * setNav: function,
 * }} param0
 * @author Lauwers Yann
 */

export default function HasPaperPrescription({
  step,
  paperPrescriptions,
  setPaperPrescriptions,
  removeItem,
  backToPrescriptionChoice,
  backToChooseYourCommandType,
  cartValue,
  showCart,
  orderSummary,
  displayCart,
  setNav,
}) {
  const [errors, setErrors] = useState([])
  const errorRef = useRef(null)

  useEffect(() => {
    setNav(colorMode.blueBg)
  })

  const handlePaperPrescriptions = file => {
    if (file.name !== "") {
      setPaperPrescriptions([...paperPrescriptions, file])
    }
  }

  const errorDealing = file => {
    const array = []
    const fileSizeInMb = file.size
    const sizeLimit = 1024 * 1024 * 5

    const paperPrescription_fileTooBig = "Votre fichier dépasse la taille autorisée de 5Mo"

    if (file.size && fileSizeInMb > sizeLimit) {
      addError("paperPrescription_fileTooBig", paperPrescription_fileTooBig, array)
    }

    setErrors(array)

    return array.length
  }

  const handleFileInput = e => {
    const file = e.target.files[0]
    e.preventDefault()
    errorDealing(file)
    if (!errorDealing(file)) {
      handlePaperPrescriptions(file)
    }
  }

  return (
    <>
      <TranslateUp in={displayCart} renderedComponent={orderSummary} />
      <H1>Nouvelle commande</H1>
      <PageContainer>
        <ContainerWithButtonFixedBottom>
          <H2 green>Commande avec prescription papier :</H2>
          <ErrorMessage errors={errors} ref={errorRef} />
          <form
            onSubmit={backToChooseYourCommandType}
            className="flex items-center flex-col flex-grow"
          >
            <div className="mb-inputToSubmit w-full flex flex-col z-0">
              <div className={`${paperPrescriptions.length > 0 ? " mb-inputToCartItem" : null}`}>
                <H3 hasBigMarginB>Ajoutez votre prescription</H3>
                <div className="mb-inputToInfo">
                  <SecondaryText>
                    Le pharmacien contrôlera la validité de votre prescription avant d’accepter
                    votre commande. Vous devrez remettre cette prescription au livreur, sans quoi
                    vous ne recevrez pas votre commande.
                  </SecondaryText>
                </div>
                <div className="mt-labelToInput2">
                  <FileUploader
                    img={<FileUploaderIcon />}
                    isError={isError("paperPrescription", errors)}
                    handleFileInput={handleFileInput}
                  />
                </div>
              </div>
              <div className="w-full border-primary mx-auto">
                <ul>
                  <CardPaperPrescription
                    removeItem={removeItem}
                    items={paperPrescriptions}
                    step={step}
                    paperPrescriptions={paperPrescriptions}
                  />
                </ul>
              </div>
            </div>
            <div className="flex items-center justify-center">
              <FullyRoundedBackBtn
                action={() => backToPrescriptionChoice()}
                name="retour"
                type="button"
                size={48}
              />
              <div
                className="ml-spaceBetweenBackAndContinue outline-none"
                style={paperPrescriptions.length > 0 ? null : { display: "none" }}
              >
                <RoundedBtn
                  action={() => showCart()}
                  name="continuer"
                  type="button"
                  isGreen
                >
                  {cartValue}
                </RoundedBtn>
              </div>
            </div>
          </form>
        </ContainerWithButtonFixedBottom>
      </PageContainer>
      {/* <BtnFixedBottom action={() => showCart()}>{cartValue}</BtnFixedBottom> */}
    </>
  )
}
