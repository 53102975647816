export default function Info({ size }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 534.08 558.97" width={size} height={size}>
      <g id="ic-chat-messages-bubble-default-2">
        <path
          id="Path_655"
          data-name="Path 655"
          className="fill-current text-primary"
          d="M403.68,120.54C256.09,118.61,134.89,236.69,133,384.28A272.59,272.59,0,0,0,307.14,635.73a247,247,0,0,0,184.86.86l156,42.69a4.05,4.05,0,0,0,5.12-5.12L617.73,541.6a264.28,264.28,0,0,0,48-179.74A268.74,268.74,0,0,0,429.67,122.15Q416.73,120.71,403.68,120.54Z"
          transform="translate(-132.96 -120.51)"
        />
      </g>
      <path
        className="fill-current text-secondary"
        d="M344.53,451.72h43.36V385.64H347.63v-31h72.78v97.06h37.17v31h-113Zm83.88-135a24.24,24.24,0,0,1-1.93,9.67,23.4,23.4,0,0,1-5.29,7.75,26,26,0,0,1-7.87,5.16,24.3,24.3,0,0,1-19,0,26.27,26.27,0,0,1-7.74-5.16,23.25,23.25,0,0,1-5.29-7.75,25.22,25.22,0,0,1,0-19.35,23.31,23.31,0,0,1,5.29-7.74,26.3,26.3,0,0,1,7.74-5.17,24.3,24.3,0,0,1,19,0,26,26,0,0,1,7.87,5.17,23.46,23.46,0,0,1,5.29,7.74A24.28,24.28,0,0,1,428.41,316.73Z"
        transform="translate(-132.96 -120.51)"
      />
    </svg>
  )
}
