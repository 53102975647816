import React from "react"

/**
 *
 * @param {{
 * type: string,
 * name: string,
 * placeholder: string,
 * defaultValueInput: string,
 * valueInput: string,
 * action: function,
 * isError: boolean,
 * autoFocus: boolean,
 * min: string,
 * }} param0
 *
 * @author Lauwers Yann
 */

export default function Input({
  type,
  name,
  id,
  defaultValueInput,
  valueInput,
  action,
  placeholder,
  isError,
  autoFocus,
  min,
  setDisable
}) {
  return (
    <div>
      <input
        type={type}
        id={id}
        min={min}
        name={name}
        onChange={action}
        placeholder={placeholder}
        defaultValue={defaultValueInput}
        value={valueInput}
        autoFocus={autoFocus}
        disabled = {setDisable}
        className={`focus:ring-0 focus:ring-offset-0 ring-offset-0 w-full font-textFontFamily font-text-FontWeight text-primary text-textFontSize leading-input bg-transparent rounded-formSelect border-input ${
          isError ? " border-cinquo focus:border-cinquo" : " border-primary focus:border-primary"
        }`}
      />
    </div>
  )
}
