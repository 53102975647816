import React from "react"

export default function Delete() {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 143.39 143.38"
      width="8"
      height="8"
    >
      <path
        className="fill-current text-secondary"
        d="M412.61,400l2.4,2.41q26.39,26.37,52.74,52.75a18.34,18.34,0,0,1,2.6,3.11,8.83,8.83,0,0,1-12.9,11.6,22.81,22.81,0,0,1-2.29-2.11q-26.3-26.28-52.58-52.57L400,412.61l-2.14,2.14-53.11,53.12c-.51.51-1,1-1.54,1.51a8.83,8.83,0,0,1-12.17-.17,8.71,8.71,0,0,1-.58-12.12c.67-.79,1.42-1.52,2.15-2.26l52.44-52.44,2.36-2.36L385,397.64l-52.74-52.75a22,22,0,0,1-2.25-2.48,8.85,8.85,0,0,1,11.47-12.89,16.31,16.31,0,0,1,3.14,2.55q26.49,26.43,52.93,52.9l2.35,2.35c1-.88,1.79-1.6,2.56-2.38l52.1-52.09c.79-.79,1.58-1.59,2.43-2.32a8.84,8.84,0,0,1,12.16.48,9,9,0,0,1,.19,12.21c-.47.54-1,1-1.51,1.54l-52.78,52.77Z"
        transform="translate(-328.28 -328.34)"
      />
    </svg>
  )
}
