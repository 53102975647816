import { AddressDTO } from "./AddressDTO"
import { MandataireDTO } from "./MandataireDTO"
import { PharmacyDTO } from "./PharmacyDTO"
import { UserDTO } from "./UserDTO"

export class ItemDTO {
  label: String
  amount: Number
}
export class OrderDTO {
  orderID: String
  orderHash: String
  pharmacy: PharmacyDTO
  prescription: {
    digital: String[],
    paper: File[],
    prescriptionFiles: Number,
    medicines: String[],
  }
  posology: String
  readPosology: Date
  payment: {
    link: String,
    value: Number,
    items: ItemDTO[],
    status: "waiting" | "paid",
  }
  user: UserDTO
  address: AddressDTO
  mandataire: MandataireDTO
  wantToBeCalled: Boolean
  hasMandataire: Boolean
  isPrescriptionIdCard: Boolean
  isPrescriptionMedecin: Boolean
  comment: String
  deliverySchedul: Date
  deliveryPickup: Date
  deliveryInfo: String


  constructor() {
    this.prescription = {}
    this.prescription.medicines = []
    this.payment = {}
  }
}
