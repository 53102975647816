import React from "react"
import ClassicButtonText from "../DefineTagClass/ClassicButtonText"

/**
 *
 * @param {{
 * type: string,
 * action: function,
 * name: string,
 * children: string,
 * invert: boolean,
 * noHighLight: boolean
 * }} param0
 *
 * @author Lauwers Yann
 */

export default function ClassicBtn({
  type,
  action,
  name,
  children,
  invert = false,
  noHighlight = false,
}) {
  let classes = "focus:outline-none bg-transparent cursor-btn"
  if (invert) {
    classes += " text-secondary font-inlineBtn-FontWeight underline "
  } else if (noHighlight) {
    classes += " text-primary font-inlineBtn-FontWeight "
  } else {
    classes += " text-ternary font-inlineBtnHeavy-FontWeight underline "
  }

  return (
    <div>
      <button type={type} className={classes} onClick={action} name={name}>
        <ClassicButtonText>{children}</ClassicButtonText>
      </button>
    </div>
  )
}
