import React from "react"
import Iframe from "../components/iframe"

export default function ClientFormAirtable() {
  return (
    <Iframe
      title="ClientForm"
      scriptSrc="https://static.airtable.com/js/embed/embed_snippet_v1.js"
      src="https://airtable.com/embed/shrx3ThwBaXbgGJN8?backgroundColor=green"
    />
  )
}
