import React from "react"

export default function Email({ size, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28.06 18.98"
      width={size}
      height={size}
      className={`fill-current ${color}`}
    >
      <path
        d="M31.93,29.92H8.87a2.5,2.5,0,0,1-2.5-2.5v-14a2.5,2.5,0,0,1,2.5-2.5H31.93a2.5,2.5,0,0,1,2.5,2.5v14A2.5,2.5,0,0,1,31.93,29.92Zm-23.06-18a1.5,1.5,0,0,0-1.5,1.5v14a1.5,1.5,0,0,0,1.5,1.5H31.93a1.5,1.5,0,0,0,1.5-1.5v-14a1.5,1.5,0,0,0-1.5-1.5Z"
        transform="translate(-6.37 -10.94)"
      />
      <path
        d="M20.4,22.08a2.48,2.48,0,0,1-1.56-.55l-11.21-9a.5.5,0,1,1,.63-.78l11.21,9a1.49,1.49,0,0,0,1.87,0l11.22-8.93a.5.5,0,0,1,.7.08.49.49,0,0,1-.08.7L22,21.53A2.46,2.46,0,0,1,20.4,22.08Z"
        transform="translate(-6.37 -10.94)"
      />
      <path
        d="M7.87,29.14A.48.48,0,0,1,7.49,29a.51.51,0,0,1,.06-.71L17.7,19.84a.5.5,0,0,1,.63.77L8.19,29A.49.49,0,0,1,7.87,29.14Z"
        transform="translate(-6.37 -10.94)"
      />
      <path
        d="M32.92,29.14A.5.5,0,0,1,32.6,29L22.47,20.62a.51.51,0,0,1-.06-.71.49.49,0,0,1,.7-.06l10.13,8.41a.5.5,0,0,1-.32.88Z"
        transform="translate(-6.37 -10.94)"
      />
    </svg>
  )
}
