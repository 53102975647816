import React from "react"
import RoundedButtonEmptyBgText from "../DefineTagClass/RoundedButtonEmptyBgText"

/**
 *
 * @param {{
 * type: string,
 * name: string,
 * action: function,
 * children: string,
 * isBlocked: boolean,
 * invert: boolean,
 * }} param0
 */

export default function RoundedBtnEmptyBlueBg({ 
  type, 
  name, 
  action, 
  children, 
  invert = false,

 }) {
  let classes =
    "focus:outline-none bg-secondary w-full outline-none px-roundedBtnPaddingX py-roundedBtnPaddingY rounded-roundedBtn cursor-btn"
  classes += invert
    ? " border-primary text-primary border-formSelect"
    : " border-primary hover:text-secondary hover:bg-primary text-primary border-formSelect"

  return (
    <button type={type} name={name} onClick={action} className={classes}>
      <RoundedButtonEmptyBgText>{children}</RoundedButtonEmptyBgText>
    </button>
  )
}
