import React, { useEffect, useRef, useState } from "react"

// Packages
import Cleave from "cleave.js/react"

// Config
import { colorMode } from "../../Config/colorMode"

// Transitions
import TranslateUp from "../../components/Transitions/TranslateUp"

// Components
import DrugCard from "../../components/Card/DrugCard"
import ErrorMessage from "../../components/ErrorMessage"
import H3 from "../../components/DefineTagClass/H3"
import SecondaryText from "../../components/DefineTagClass/SecondaryText"
import RoundedBtn from "../../components/Btns/RoundedBtn"
import BtnFixedBottom from "../../components/Btns/BtnFixedBottom"
import FullyRoundedBackBtn from "../../components/Btns/FullyRoundedBackBtn"
import FullyRoundedImgBtn from "../../components/Btns/FullyRoundedImgBtn"
import H1 from "../../components/DefineTagClass/H1"
import { ContainerWithButtonFixedBottom, PageContainer } from "../../components/Container"
import H2 from "../../components/DefineTagClass/H2"
import { addError, isError } from "../../Services/errors"
import AddFill from "../../components/Images/AddFill"
import imageRid from "../../assets/img/RID.png"

/**
 *
 * @param {{
 * step: number,
 * electronicPrescriptions: [],
 * setElectronicPrescriptions: function,
 * removeItem: function,
 * backToPrescriptionChoice: function,
 * backToChooseYourCommandType: function,
 * error: string,
 * setError: function,
 * cartValue: variable,
 * showCart: function,
 * orderSummary: component,
 * I dont know what to say for ordersummary: because it's a component..
 * displayCart: function,
 * red: {},
 * setRed: function,
 * setNav: function
 * }} param0
 * @author Lauwers Yann
 */

export default function HasElectronicPrescription({
  step,
  electronicPrescriptions,
  setElectronicPrescriptions,
  removeItem,
  backToPrescriptionChoice,
  backToChooseYourCommandType,
  cartValue,
  showCart,
  orderSummary,
  displayCart,
  setNav,
  isIdCardPrescription
}) {
  const [electronicPrescription, setElectronicPrescription] = useState("")
  const [errors, setErrors] = useState([])
  const errorRef = useRef(null)

  useEffect(() => {
    setNav(colorMode.blueBg)
  })

  const errorDealing = () => {
    const array = []

    const electronicPrescription_missing = "Le champ ne peut pas être vide"
    const electronicPrescription_notComplete = "Le numéro de prescription n'est pas complet"
    const electronicPrescription_OIsInvalid =
      "Vous avez du confondre le chiffre zero (0) avec la lettre O"

    if (!electronicPrescription) {
      addError("electronicPrescription_missing", electronicPrescription_missing, array)
    }
    if (electronicPrescription.length !== 12) {
      addError("electronicPrescription_notComplete", electronicPrescription_notComplete, array)
    }
    if (electronicPrescription.includes("O")) {
      addError("electronicPrescription_OIsInvalid", electronicPrescription_OIsInvalid, array)
    }

    setErrors(array)

    return array.length
  }

  const handleElectronicPrescriptions = e => {
    e.preventDefault()
    errorDealing()
    if (
      electronicPrescription &&
      electronicPrescription.length === 12 &&
      !electronicPrescription.includes("O")
    ) {
      setElectronicPrescriptions([...electronicPrescriptions, electronicPrescription])
      return setElectronicPrescription("BEP")
    }
  }

  return (
    <>
      <TranslateUp in={displayCart} renderedComponent={orderSummary} />
      <H1>Nouvelle commande</H1>
      <PageContainer>
        <div className="ml-20">
        <ContainerWithButtonFixedBottom>


          
          <H2 green>Commande avec prescription électronique :</H2>
          <ErrorMessage errors={errors} ref={errorRef} />
          <div className="flex items-center flex-row">
          <form
            onSubmit={handleElectronicPrescriptions}
            className="flex items-center flex-col flex-grow"
          >
            <div className="mb-inputToSubmit w-full flex flex-col">
              <div
                className={`${electronicPrescriptions.length > 0 ? "mb-inputToCartItem" : null}`}
              >
                <H3 hasMarginB>Indiquez le code RID de votre prescription</H3>

                <div
                  className={
                    "flex px-inputWithBtnX py-inputWithBtnY rounded-formSelect border-input " +
                    (isError("electronicPrescription", errors) ? "border-cinquo" : "border-primary")
                  }
                >
                  <Cleave
                    autoFocus
                    options={{
                      prefix: "BEP",
                      delimiter: "",
                      blocks: [3, 9],
                      delimiters: [""],
                      numericOnly: false,
                      uppercase: true,
                    }}
                    onChange={e => setElectronicPrescription(e.target.value)}
                    value={electronicPrescription}
                    className="focus:ring-0 focus:ring-offset-0 py-0 px-0 w-full font-textFontFamily font-text-FontWeight text-primary text-textFontSize leading-input bg-transparent border-none"
                  />
                  <div className="flex items-center justify-center">
                    <FullyRoundedImgBtn
                      imgType="submit"
                      imgAction={handleElectronicPrescriptions}
                      imgName="add item"
                      small
                    >
                      <AddFill />
                    </FullyRoundedImgBtn>
                  </div>
                </div>
                <SecondaryText margin>
                  Ce code se trouve sous le code-barre de votre prescription.
                </SecondaryText>
              </div>
              <div className="w-full border-primary mx-auto">
                <ul>
                  <DrugCard
                    titre = "Presciption ajoutée via son code RID"
                    removeItem={removeItem}
                    items={electronicPrescriptions}
                    step={step}
                  />
                </ul>
              </div>
            </div>
            <div className="flex items-center justify-center">
              <FullyRoundedBackBtn
                action={() => backToChooseYourCommandType()}
                name="retour"
                type="button"
                size={48}
              />
              <div
                className="ml-spaceBetweenBackAndContinue outline-none"
                style={electronicPrescriptions.length > 0 ? null : { display: "none" }}
              >
                <RoundedBtn
                  action={() => showCart()}
                  name="continuer"
                  type="button"
                  isGreen
                >
                  {cartValue}
                </RoundedBtn>
              </div>
            </div>
          </form>
          <div className="ml-20">
            <img src={imageRid} alt="Code RID présent sous le code barre de la prescription"></img>
          </div>
          </div>
        </ContainerWithButtonFixedBottom>
        </div>
      </PageContainer>
      {/* <BtnFixedBottom action={() => showCart()}>{cartValue}</BtnFixedBottom> */}
    </>
  )
}
