import React from "react"

/**
 *
 * @param {{
 * state: boolean,
 * }} param0
 * @returns
 */

export default function Arrow({ state }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      className={`fill-current text-primary transition-transform transform ${
        state ? " rotate-90" : " -rotate-90"
      }`}
      viewBox="0 0 24 24"
    >
      <path d="M17.78,24a1.44,1.44,0,0,1-1-.42L6.2,13a1.45,1.45,0,0,1,0-2L16.76.42a1.44,1.44,0,0,1,2,2L9.26,12l9.54,9.54a1.44,1.44,0,0,1-1,2.46Z" />
    </svg>
  )
}
