import React from "react"

import { Transition } from "react-transition-group"

// to handle transitions, follow this : https://alligator.io/react/react-transition-group/

const defaultStyle = {
  transition: `transform 0.8s, opacity 300ms ease`,
  opacity: 1,
}

const transitionStyles = {
  entering: { transform: "translateY(100vh)", opacity: 0 },
  entered: { opacity: 1 },
  exiting: { transform: "translateY(100vh)", opacity: 1 },
  exited: { opacity: 0 },
}

/**
 *
 * @param {{
 * displayCart: boolean,
 * renderedComponent: function,
 * }} param0
 *
 * @author Lauwers Yann
 */

const TranslateUp = ({ in: displayCart, renderedComponent }) => (
  <Transition
    in={displayCart}
    timeout={{
      appear: 500,
      enter: 300,
      exit: 500,
    }}
    appear
    unmountOnExit
  >
    {state => (
      <div
        className="fixed top-0 left-0 w-screen h-screen z-10 overflow-y-auto"
        style={{
          ...defaultStyle,
          ...transitionStyles[state],
        }}
      >
        {renderedComponent}
      </div>
    )}
  </Transition>
)

export default TranslateUp

// {/* I am {state} */}
