import React, {useEffect, useState} from "react"

// Transitions
import TranslateUp from "../../components/Transitions/TranslateUp"

// Components
import FullyRoundedBackBtn from "../../components/Btns/FullyRoundedBackBtn"
import BtnFixedBottom from "../../components/Btns/BtnFixedBottom"
import RoundedBtnEmptyBg from "../../components/Btns/RoundedBtnEmptyBg"
import {AddressDTO} from "../../Models/AddressDTO"
import {colorMode} from "../../Config/colorMode"
import H1 from "../../components/DefineTagClass/H1"
import {PageContainer} from "../../components/Container"
import H2 from "../../components/DefineTagClass/H2"
import Information from "../../components/Information/Information"
import RoundedBtnEmptyBlueBg from "../../components/Btns/RoundedBtnEmptyBlueBg"
import {PharmacyAPI} from "../../Backend/pharmacy"
import ProfileCard from "../../components/Card/ProfileCard";

/**
 *
 * @param {{
 * prevStep: function,
 * toOrderWithoutPrescription: function,
 * toOrderWithElectronicPrescription: function,
 * toOrderWithPaperPrescription: function,
 * toInfoPageCommandWithIdcard: function,
 * toInfoPageCommandByDoctor: function,
 * showCart: function,
 * displayCart: function,
 * cartValue: const,
 * orderSummary: const,
 * clickedOnId: boolean,
 * personnalLink: boolean,
 * backToBeginning: function,
 * setDeliveryAddress: function,
 * setNav: function
 * }} param0
 *
 * @author IDI SOULE
 */

export default function HasAPrescriptionA({
                                            prevStep,
                                            toOrderWithElectronicPrescription,
                                            toOrderWithPaperPrescription,
                                            toChooseMandataire,
                                            showCart,
                                            displayCart,
                                            cartValue,
                                            orderSummary,
                                            personnalLink,
                                            backToBeginning,
                                            setDeliveryAddress,
                                            toOrderWithoutPrescription,
                                            toOrderWithPrescription,
                                            toInfoPageCommandWithIdcard,
                                            toInfoPageCommandByDoctor,
                                            setNav,
                                          }) {
  useEffect(() => {
    setNav(colorMode.blueBg)
  })

  const back = () => {
    if (personnalLink) {
      setDeliveryAddress(new AddressDTO())
      backToBeginning()
    } else {
      prevStep()
    }
  }

  return (
    <>
      <TranslateUp in={displayCart} renderedComponent={orderSummary}/>
      <H1>
        <FullyRoundedBackBtn action={back} name="retour" type="button" size={48}/> &nbsp;
        Nouvelle commande
      </H1>
      <PageContainer>
        <H2 green>Choisissez votre type de commande :</H2>
        <div>
          <Information>
            Depuis le 15 septembre 2021, vos prescriptions médicales sont totalement numérisées. <br />
            Si vous disposez d'une ordonnance papier, demandez à votre médecin de la mettre en ligne et choisissez une
            des options ci-dessous
          </Information>
        </div>
        <div className="mb-inputToSubmit flex items-center flex-row mt-20">
          {/* <div className="outline-none flex items-center flex-row"> */}
          <div className="flex items-center flex-col">
            <div className="mb-btnToBtn  w-full max-w-mobileBtnMaxWidth mr-20">
              <RoundedBtnEmptyBlueBg
                action={toInfoPageCommandWithIdcard}
                name="Commander avec prescription"
                type="submit"
                clickedOnId="true"
                isPrescriptionIdCard="true"
              >
                La prescription est sur ma carte d'identité
              </RoundedBtnEmptyBlueBg>
            </div>
            <div className="mb-btnToBtn w-full max-w-mobileBtnMaxWidth mr-20">
              <RoundedBtnEmptyBlueBg
                //action={toOrderWithElectronicPrescription}
                action={toInfoPageCommandByDoctor}
                name="Commander avec prescription électronique"
                type="submit"
                clickedOnId="false"
                isPrescriptionMedecin="true"
              >
                Le médecin a envoyé la prescription à la pharmacie
              </RoundedBtnEmptyBlueBg>
            </div>
            <div className="mb-btnToBtn w-full max-w-mobileBtnMaxWidth mr-20">
              <RoundedBtnEmptyBlueBg
                action={toOrderWithElectronicPrescription}
                name="Commander avec prescription papier"
                type="submit"
              >
                Envoyer ma prescription grâce à mon code RID
              </RoundedBtnEmptyBlueBg>
            </div>
            <div className="mb-btnToBtn w-full max-w-mobileBtnMaxWidth mr-20">
              <RoundedBtnEmptyBlueBg
                action={toOrderWithoutPrescription}
                name="Commander avec prescription électronique"
                type="submit"
              >
                Sans prescription
              </RoundedBtnEmptyBlueBg>
            </div>
          </div>
        </div>

      </PageContainer>
      <BtnFixedBottom action={showCart}>{cartValue}</BtnFixedBottom>
    </>
  )
}
