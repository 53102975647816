import React, { useContext, useEffect } from "react"
import { Switch, Route, useHistory } from "react-router-dom"
import SignIn from "../pages/SignIn"
import ClientForm from "../pages/ClientForm"
import Posologie from "../pages/Posologie"
import PharmacyForm from "../pages/PharmacyForm"
import ClientFormAirtable from "../pages/ClientFormAirtable"
import PharmacyLoggedInForm from "../pages/PharmacyLoggedInSteps/PharmacyLoggedInForm.jsx"
import { checkIsConnect, UserContext } from "../Services/UserContext.js"
import PharmacyDoesntExistForm from "../pages/PharmacyDoesntExistForm"
import Feedback from "../pages/Feedback"
import PharmacyPersonnalLink from "../pages/PharmacyPersonnalLink"

export const Url = {
  root: "/",
  signin: "/signin",
  signup: "/signup",
  posologie: "/order",
  test: "/test",
  pharmacyForm: "/pharmacy",
  pharmacyPersonnalLink: "/pharmacy/:id",
  pharmacyPersonnalLinkOrder: "/pharmacy/:id/order",
  pharmacyLoggedInForm: "/my-pharmacy/:id",
  pharmacyDoesntExist: "/add-pharmacy",
  feedback: "/feedback/:id",
}

export const PrivateRoute = ({ exact, path, component }) => {
  //const history = useHistory()
  const { isLoggedIn } = useContext(UserContext)

  /*useEffect(() => {
    if (!isLoggedIn) {
      setTimeout(() => {
        return history.push(Url.signin)
      }, 2000)
    }
  }, [isLoggedIn])*/

  if (!isLoggedIn) {
    checkIsConnect()
    return (
      <div className="w-full flex justify-center text-center mt-20">
        <span>
          Vous devez être connecté pour accéder à cette page... <br />
          Vous allez être redirigé dans 2 secondes.
        </span>
      </div>
    )
  }
  return <Route path={path} exact={exact} render={() => component} />
}

/**
 *
 * @param {{
 * setNav: function
 * }} param0
 *
 * @author Yann Lauwers
 */

export default function Routes({ setNav }) {
  const history = useHistory()
  const { user, isLoggedIn } = useContext(UserContext)

  /**
   * This is the original rooting which apparently caused troubles.
   * I keep it in case we need to make it again but differently
   */
  useEffect(() => {
    if (isLoggedIn) {
      const id = user?.pharmacy?.id
      const isAuthorizedPaths = window.location.pathname === "/signin"
      if (isAuthorizedPaths) return history.push(`/my-pharmacy/${id}`)
    }
  }, [isLoggedIn, user])

  return (
    <Switch>
      <Route exact path={Url.root} render={() => <ClientForm setNav={setNav} />} />
      <Route exact path={Url.pharmacyForm} component={PharmacyForm} />
      <Route path={Url.pharmacyPersonnalLinkOrder} render={() => <ClientForm setNav={setNav} />} />
      <Route path={Url.pharmacyPersonnalLink} component={PharmacyPersonnalLink} />
      <Route exact path={Url.signin} component={SignIn} />
      <Route exact path={Url.pharmacyDoesntExist} component={PharmacyDoesntExistForm} />
      <PrivateRoute
        path="/my-pharmacy/"
        component={<PharmacyLoggedInForm setNav={setNav} user={user} />}
      />
      <Route path={Url.feedback} component={Feedback} />
      <Route path={Url.posologie} component={Posologie} />
      <Route path={Url.root} component={ClientFormAirtable} />
    </Switch>
  )
}
