import { components } from "react-select"

import PrimaryText from "../DefineTagClass/PrimaryText"
import DropdownIndicatorIcon from "../Images/DropdownIndicator"

// follow this link to fetch data slowly https://julietonyekaoha.medium.com/customizing-reusable-react-select-menu-options-components-7642190caa73

// Personnalize Select
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownIndicatorIcon />
    </components.DropdownIndicator>
  )
}
const Placeholder = props => {
  return <components.Placeholder {...props} />
}
const NoOptionsMessage = () => {
  return (
    <div className="m-0 pl-4 pt-2 pr-0 pb-2">
      <PrimaryText>Pas d'autre option</PrimaryText>
    </div>
  )
}
const IndicatorSeparator = () => null
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px solid #1B3256",
    color: "#1B3256",
    paddingBottom: 13,
    paddingRight: 25,
    paddingLeft: 25,
    paddingTop: 13,
    margin: 0,
    width: "100%",
    height: "100%",
    textAlign: "left",
    backgroundColor: state.isFocused ? "#E9EBEF" : "transparent",
  }),
  menu: provided => ({
    ...provided,
    width: "100%",
    background: "#ffffff",
    paddingBottom: 0,
  }),
  control: (base, state) => ({
    ...base,
    background: "#ffffff",
    color: "#1B3256",
    paddingRight: "5px",
    minHeight: "unset",
    height: "35px", // This must be 37px according to the other inputs but the width of the border makes if be 2 px bigger
    borderRadius: 5,
    borderColor: "#ffffff",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "white" : "white",
    },
  }),
  input: () => ({
    color: "#1B3256",
  }),
  placeholder: base => ({
    ...base,
    fontSize: "1rem",
    color: "#1B3256", // changer la couleur du placeholder
    background: "transparent",
    fontWeight: 400,
  }),
  singleValue: provided => ({
    ...provided,
    color: "#1B3256",
    zIndex: 0,
  }),
}

export { DropdownIndicator, Placeholder, NoOptionsMessage, IndicatorSeparator, customStyles }
