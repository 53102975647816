import React from "react"

import RoundedButtonEmptyBgText from "../DefineTagClass/RoundedButtonEmptyBgText"


/**
 *
 * @param {{
 * type: string,
 * name: string,
 * action: function,
 * children: string,
 * isBlocked: boolean,
 * invert: boolean,
 * addItem: function,
 * }} param0
 */

export default function RoundedBtnEmptyBg({ type, name, action, children, invert = false, addItem }) {
  let classes =
    "focus:outline-none bg-secondary w-full outline-none px-roundedBtnPaddingX py-roundedBtnPaddingY rounded-roundedBtn cursor-btn"
  classes += invert
    ? " border-primary text-primary border-formSelect"
    : " border-ternary hover:text-secondary hover:bg-ternary text-ternary border-formSelect"



  return (
    <button type={type} name={name} onClick={action} className={classes}>
      <RoundedButtonEmptyBgText>{children}</RoundedButtonEmptyBgText>
    </button>
  )
}
