import { useState } from "react"
import { formatAddress } from "../../Services/AddressService"
import { PrimaryText, SecondaryText } from "../DefineTagClass/Main"
import Chevron from "../Images/Chevron"
import ClickAndCollect from "../Images/ClickAndCollect"
import Easy from "../Images/Easy"
import Express from "../Images/Express"
import Localisation from "../Images/Localisation"
import Phone from "../Images/Phone"
import Calendar from "../Images/Calendar"
import Email from "../Images/Email"
import { H3 } from "../DefineTagClass/Header"
import { ButtonSm } from "../DefineTagClass/Buttons"
import { format } from "date-fns"

export function AboutUs({ children }) {
  return (
    <div className="space-y-6 max-w-full tablet:text-left tablet:col-start-1 tablet:col-end-1 laptop:col-start-2 laptop:col-end-3 laptop:max-w-aboutUs break-words">
      <H3>À propos</H3>
      <PrimaryText fontWeight="semi-bold">{children}</PrimaryText>
    </div>
  )
}

function PharmacyDetail({ icon, children }) {
  return (
    <div className="grid grid-cols-6 mx-8 gap-2 pt-4">
      <div className="col-span-1 flex items-center justify-center">{icon}</div>
      {children}
    </div>
  )
}

function ATagButton({ href, children }) {
  return (
    <a href={href} className="font-normal border-none focus:outline-none p-0">
      <PrimaryText>{children}</PrimaryText>
    </a>
  )
}

export function AboutUsAndOrderLinkAlignment({ children }) {
  return (
    <div className="space-y-10 flex flex-col items-center tablet:items-start laptop:col-start-2 laptop:col-end-3 mb-10 laptop:mb-24">
      {children}
    </div>
  )
}

export function PageAlignment({ children }) {
  return (
    <div className="flex flex-col items-center text-center gap-x-10 tablet:grid tablet:grid-cols-2 tablet:gap-x-10 tablet:items-start laptop:grid-cols-laptop-pharmacyDetails laptop:grid-rows-laptop-aboutUs-and-deliveries laptop:mx-auto laptop:gap-y-0 laptop:gap-16 monitor:gap-x-24">
      {children}
    </div>
  )
}

function GridRightPharmacyDetail({ children }) {
  return <div className="col-start-2 col-span-full text-left flex items-center">{children}</div>
}

export function PharmacyData({ pharmacyDetails, isActive }) {
  return (
    <div
      className={`flex flex-col divide-y divide-dashed divide-quatro space-y-4 ${
        isActive ? "" : "hidden laptop:block"
      }`}
    >
      <PharmacyDetail icon={<Localisation size={23} color="text-ternary" />}>
        <GridRightPharmacyDetail>
          <PrimaryText>{formatAddress(pharmacyDetails.address)}</PrimaryText>
        </GridRightPharmacyDetail>
      </PharmacyDetail>
      <PharmacyDetail icon={<Email size={23} color="text-ternary" />}>
        <GridRightPharmacyDetail>
          <ATagButton href={`mailto:${pharmacyDetails?.companyEmail}`}>
            {pharmacyDetails?.companyEmail}
          </ATagButton>
        </GridRightPharmacyDetail>
      </PharmacyDetail>
      <PharmacyDetail icon={<Phone size={23} color="text-ternary" />}>
        <GridRightPharmacyDetail>
          <ATagButton href={`tel:${pharmacyDetails?.companyPhone}`}>
            {pharmacyDetails?.companyPhone}
          </ATagButton>
        </GridRightPharmacyDetail>
      </PharmacyDetail>
      <div className="grid grid-cols-6 mx-8 gap-2 pt-4">
        <div className="col-span-1 flex items-start justify-center">
          <Calendar size={23} color="text-ternary" />
        </div>
        <GridRightPharmacyDetail>
          {pharmacyDetails?.openingDays && (
            <div className="w-full space-y-4">
              <FormatDayOpeningHours
                dayName="Lundi"
                openingDay={pharmacyDetails?.openingDays["Monday"]}
                originalDayName={Object.keys(pharmacyDetails?.openingDays)[1]}
              />
              <FormatDayOpeningHours
                dayName="Mardi"
                openingDay={pharmacyDetails?.openingDays["Tuesday"]}
                originalDayName={Object.keys(pharmacyDetails?.openingDays)[2]}
              />
              <FormatDayOpeningHours
                dayName="Mercredi"
                openingDay={pharmacyDetails?.openingDays["Wednesday"]}
                originalDayName={Object.keys(pharmacyDetails?.openingDays)[3]}
              />
              <FormatDayOpeningHours
                dayName="Jeudi"
                openingDay={pharmacyDetails?.openingDays["Thursday"]}
                originalDayName={Object.keys(pharmacyDetails?.openingDays)[4]}
              />
              <FormatDayOpeningHours
                dayName="Vendredi"
                openingDay={pharmacyDetails?.openingDays["Friday"]}
                originalDayName={Object.keys(pharmacyDetails?.openingDays)[5]}
              />
              <FormatDayOpeningHours
                dayName="Samedi"
                openingDay={pharmacyDetails?.openingDays["Saturday"]}
                originalDayName={Object.keys(pharmacyDetails?.openingDays)[6]}
              />
              <FormatDayOpeningHours
                dayName="Dimanche"
                openingDay={pharmacyDetails?.openingDays["Sunday"]}
                originalDayName={Object.keys(pharmacyDetails?.openingDays)[0]}
              />
            </div>
          )}
        </GridRightPharmacyDetail>
      </div>
    </div>
  )
}

function FormatDayOpeningHours({ dayName, openingDay, originalDayName }) {
  const isToday = originalDayName === format(new Date(), "eeee")
  return (
    <div className="flex justify-between w-full">
      <PrimaryText fontWeight={isToday ? "header2-FontWeight" : ""}>{dayName}</PrimaryText>
      <div className="flex flex-col items-end">
        {openingDay?.length ? (
          Object.entries(openingDay).map((halfDayHours, index) => (
            <div key={index}>
              <FormatOpeningHours day={halfDayHours[1]} isToday={isToday} />
            </div>
          ))
        ) : (
          <PrimaryText fontWeight={isToday ? "header2-FontWeight" : ""}>Fermé</PrimaryText>
        )}
      </div>
    </div>
  )
}

function FormatOpeningHours({ day, isToday = false }) {
  return (
    <div>
      <PrimaryText fontWeight={isToday ? "header2-FontWeight" : ""}>
        {day.open} - {day.close}
      </PrimaryText>
    </div>
  )
}

function DeliveryMethod({ icon, notice, children }) {
  return (
    <div className="w-max mx-auto flex justify-center pt-6 laptop:pt-0 space-x-4 laptop:px-4">
      <div className="flex justify-center items-center">{icon}</div>
      <div className="space-y-2 flex flex-col items-start flex-grow">
        <div className="text-left w-max">
          <H3>{children}</H3>
        </div>
        <div className="w-32 bg-primary bg-opacity-50 text-center py-1 rounded-6px">
          <SecondaryText fontWeight="extra-bold" color="secondary">
            {notice}
          </SecondaryText>
        </div>
      </div>
    </div>
  )
}

export function AvailableDeliveries({ deliveryMethods }) {
  return (
    <>
      {/*
    <div className="bg-secondary w-full max-w-mobile-and-tablet-deliveryMethod laptop:max-w-none mb-10 rounded-5px flex flex-col items-center space-y-5 divide-y divide-quatro pt-6 pb-8 tablet:mb-0 tablet:col-start-2 tablet:col-end-3 tablet:row-start-1 tablet:row-end-4 laptop:items-start laptop:divide-y-0 laptop:space-y-8 laptop:col-start-2 laptop:col-end-3 laptop:row-start-auto laptop:row-end-3 shadow-card">
      <div className="mx-7">
        <H3>Modes de livraison disponibles</H3>
      </div>
      <div className="w-full px-7 space-y-6 divide-y divide-dashed divide-quatro laptop:flex laptop:items-center laptop:space-y-0 laptop:divide-y-0 laptop:max-w-max laptop:divide-x laptop:px-3">
        {deliveryMethods?.includes("easy") && (
          <DeliveryMethod
            icon={<Easy color="text-primary" size={32} />}
            notice="Livré dans la journée"
          >
            Easy
          </DeliveryMethod>
        )}
        {deliveryMethods?.includes("express") && (
          <DeliveryMethod
            icon={<Express color="text-primary" size={32} />}
            notice="Livré en moins de 3h"
          >
            Express
          </DeliveryMethod>
        )}
        {deliveryMethods?.includes("clickAndCollect") && (
          <DeliveryMethod
            icon={<ClickAndCollect color="text-primary" size={32} />}
            notice="En pharmacie"
          >
            Click & Collect
          </DeliveryMethod>
        )}
      </div>
    </div>
    */}
    </>
  )
}

function CallMe({ phone }) {
  return (
    <a
      href={`tel:${phone}`}
      className="w-full focus:outline-none rounded-roundedBtn cursor-btn filter drop-shadow-roundedBtn bg-ternary text-secondary flex items-center justify-center py-1 px-5 laptop:row-start-3 laptop:row-end-4 laptop:absolute laptop:bottom-6 laptop:left-1/2 laptop:w-9/12 laptop:transform laptop:-translate-x-2/4"
    >
      <ButtonSm>Prendre contact</ButtonSm>
    </a>
  )
}

export function CGV({ cgv }) {
  return (
    <a
      href={cgv}
      target="_blank"
      rel="noreferrer"
      className="text-center font-bolder underline tablet:text-left laptop:text-center"
    >
      <PrimaryText>Conditions générales de vente</PrimaryText>
    </a>
  )
}

export function PharmacyCardWithDetails({ pharmacyDetails }) {
  const [isActive, setIsActive] = useState(false)
  return (
    <div
      className={`relative w-full max-w-pharmacyDetailsCard bg-secondary rounded-5px mb-10 shadow-card tablet:mb-5 tablet:col-start-1 tablet:col-end-2 laptop:col-start-1 laptop:col-end-auto laptop:row-start-1 laptop:row-end-3 laptop:mt-laptop-avatar-container laptop:pb-24 ${
        isActive ? "pb-10" : ""
      }`}
    >
      <div
        className={`flex items-center justify-center space-x-4 laptop:space-x-0 px-5 ${
          isActive
            ? "h-mobile-pharmacy-details-card-open laptop:h-laptop-avatar-container"
            : "h-mobile-pharmacy-details-card-closed laptop:h-laptop-avatar-container"
        }`}
      >
        {pharmacyDetails.image && pharmacyDetails.image.length && (
          <img
            src={pharmacyDetails?.image[0]?.url}
            className="w-20 h-20 rounded-round filter drop-shadow-avatar laptop:w-laptop-avatar laptop:h-laptop-avatar laptop:absolute laptop:-top-laptop-avatar-container"
            alt="avatar pharmacie"
          />
        )}
        <CallMe phone={pharmacyDetails.companyPhone} />
        <button
          type="button"
          onClick={() => setIsActive(!isActive)}
          className={`absolute bottom-3 right-6 focus:outline-none transition duration-250 laptop:hidden ${
            isActive ? "transform rotate-180" : ""
          }`}
        >
          <Chevron color="text-primary" size={20} />
        </button>
      </div>
      {<PharmacyData pharmacyDetails={pharmacyDetails} isActive={isActive} />}
    </div>
  )
}
