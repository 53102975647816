import React from "react"
import { CheckboxesText } from "../DefineTagClass/Checkboxes"

/**
 *
 * @param {{
 * children: string,
 * name: string,
 * action: function,
 * isError: boolean,
 * value: boolean,
 * }} param0
 *
 * @author Lauwers Yann
 */

export default function CheckBox({ children, name, action, isError, value, defaultChecked }) {
  return (
    <label className="flex justify-center self-start bg-red">
      <input
        type="checkbox"
        name={name}
        checked={value}
        defaultChecked={defaultChecked}
        onChange={action}
        className={`h-checkboxHW w-checkboxHW focus:ring-0 focus:ring-offset-0 text-primary border-checkbox rounded-checkbox ${
          isError ? " border-cinquo" : " border-primary"
        }`}
      />
      <div className="mt-labelMT text-left text-primary ml-checkboxMarginText">
        <CheckboxesText>{children}</CheckboxesText>
      </div>
    </label>
  )
}
