import React from "react"
import RoundedButtonText from "../DefineTagClass/RoundedButtonText"

/**
 *
 * @param {{
 * type: string,
 * name: string,
 * action: function,
 * children: string,
 * isBlocked: boolean,
 * canHover: boolean,
 * }} param0
 *
 * @author Lauwers Yann
 */

export default function RoundedBtn({
  type,
  name,
  action,
  children,
  isBlocked = false,
  canHover = false,
  isBlue = false,
  
}) {
  let classes1 =
    "w-full focus:outline-none px-roundedBtnPaddingX py-roundedBtnPaddingY rounded-roundedBtn shadow-btn cursor-btn"

  if (isBlocked) {
    classes1 += " bg-quatro text-opacity-50 text-primary "
  } else if (isBlue) {
    classes1 += " bg-primary text-secondary"
  } else {
    classes1 += " text-secondary bg-ternary z-50"
  }

  classes1 += canHover
    ? " hover:bg-secondary border-btn hover:border-ternary hover:text-ternary "
    : ""

  return (
    <div>
      <button type={type} name={name} onClick={action} className={classes1}>
        <RoundedButtonText>{children}</RoundedButtonText>
      </button>
    </div>
  )
}
