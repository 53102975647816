import React from "react"
import Iframe from "../components/iframe"

export default function PharmacyForm() {
  const formatPathName = window.location.href.split("?")
  return (
    <Iframe
      title="pharmacySelection"
      scriptSrc="https://static.airtable.com/js/embed/embed_snippet_v1.js"
      src={`https://airtable.com/embed/shrwIA7QTwvFhZd0O?backgroundColor=green&${formatPathName[1]}`}
    />
  )
}
