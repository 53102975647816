import React from "react"

export default function AddFill() {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 343.41 343.22"
      width="28"
      height="28"
    >
      <path
        className="fill-current text-secondary"
        d="M571.7,400c0,94.8-76.85,171.58-171.71,171.61C305.9,571.63,228.32,495.7,228.3,400a171.7,171.7,0,0,1,343.4,0ZM399.79,553.87c85.28-.18,153.62-68.57,154.1-152.88.5-86.48-69.44-154.14-152.6-154.81-84.65-.68-155.08,67.63-155.2,153.67C246,484,313.87,553.45,399.79,553.87Z"
        transform="translate(-228.3 -228.39)"
      />
      <path
        className="fill-current text-ternary"
        d="M571.7,400c0,94.8-76.85,171.58-171.71,171.61C305.9,571.63,228.32,495.7,228.3,400a171.7,171.7,0,0,1,343.4,0Z"
        transform="translate(-228.3 -228.39)"
      />
      <path
        className="fill-current text-secondary"
        d="M408.94,408.91v3.4q0,37.29,0,74.59a18.19,18.19,0,0,1-.36,4,8.83,8.83,0,0,1-17.32-.92,23,23,0,0,1-.13-3.11q0-37.18,0-74.35v-3.65H313c-.72,0-1.44,0-2.16,0a8.86,8.86,0,0,1-8.49-8.74,8.72,8.72,0,0,1,8.17-9c1-.09,2.08-.08,3.12-.08h77.5v-3.37q0-37.31,0-74.6a23.28,23.28,0,0,1,.16-3.34,8.85,8.85,0,0,1,17.23-1,16.43,16.43,0,0,1,.42,4q0,37.41,0,74.83V391c1.31,0,2.4.12,3.5.12h73.67c1.12,0,2.25,0,3.36.08a8.84,8.84,0,0,1,8.26,8.93,9,9,0,0,1-8.5,8.77c-.71.05-1.43,0-2.15,0H408.94Z"
        transform="translate(-228.3 -228.39)"
      />
    </svg>
  )
}
