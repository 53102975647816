import React, { useEffect, useRef } from "react"

import Input from "../../components/Inputs/Input"
import RoundedBtn from "../../components/Btns/RoundedBtn"
import ErrorMessage from "../../components/ErrorMessage"
import PrimaryText from "../../components/DefineTagClass/PrimaryText"
import H3 from "../../components/DefineTagClass/H3"
import { colorMode } from "../../Config/colorMode"
import H1 from "../../components/DefineTagClass/H1"
import { Container, PageContainer } from "../../components/Container"
import H2 from "../../components/DefineTagClass/H2"

/**
 *
 * @param {{
 * backHome: function,
 * setUser: function,
 * user: string,
 * red: boolean,
 * error: string,
 * setError: function,
 * setNav: function
 * }} param0
 *
 * @author Lauwers Yann
 */

export default function ContactNewPharmacy({
  backHome,
  user,
  setUser,
  red,
  error,
  setError,
  setNav,
}) {
  const errorRef = useRef(null)

  useEffect(() => {
    setNav(colorMode.blueBg)
  })

  const handleUser = e => {
    const value = e.target.value
    setUser({
      ...user,
      [e.target.name]: value,
    })
  }

  const actualizeError = errorMessage => {
    setError({
      ...error,
      user: errorMessage,
    })
  }

  const handleSend = e => {
    e.preventDefault()
    // eslint-disable-next-line no-useless-escape
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const errEmail = "Veuillez entrer une adresse email valide"
    if (user.email && !re.test(user.email)) {
      actualizeError(errEmail)
    } else {
      // next line is here to go back to menu. It has to be removed when we dealt with the email
      actualizeError("")
      window.location.reload()
    }
  }

  return (
    <PageContainer>
      <H1>Nouvelle commande</H1>
      <Container>
        <H2 green>Nous contacterons de toute façon votre pharmacie de référence.</H2>
        <ErrorMessage text={error.user} ref={errorRef} />
        <form onSubmit={backHome} className="flex items-center flex-col">
          <div className="mb-inputToSubmit w-full flex flex-col">
            <div className="mb-labelToInput2">
              <PrimaryText>
                Si vous souhaitez que nous vous tenions informé lorsque celle-ci aura rejoint le
                réseau Smile, laissez-nous votre adresse email.
              </PrimaryText>
            </div>
            <H3 hasMarginB>Indiquez votre adresse email</H3>
            <Input
              autoFocus
              type="text"
              action={handleUser}
              name="email"
              defaultValueInput={user.email}
              isError={red.user.email}
            />
          </div>
          <div className="mx-auto">
            <RoundedBtn action={handleSend} name="continuer" type="button">
              Continuer
            </RoundedBtn>
          </div>
        </form>
      </Container>
    </PageContainer>
  )
}
