import React from "react"

import Label from "../DefineTagClass/Label"
import PrimaryText from "../DefineTagClass/PrimaryText"

import FullyRoundedImgBtn from "../Btns/FullyRoundedImgBtn"
import DeleteCartItemBtn from "../Btns/deleteCartItemBtn"
import Delete from "../Images/Delete"
import EditFill from "../Images/EditFill"

/**
 *
 * @param {{
 * items: [],
 * removeItem: function,
 * titre: string,
 * canEdit: boolean,
 * noBorder: boolean,
 * edit: boolean,
 * }} param0
 *
 * @author Lauwers Yann
 */

export default function DrugCard({
  items,
  removeItem,
  titre,
  canEdit,
  noBorderOnOrderSum,
  editItem,
  paperPrescriptions,
  isPrescriptionIdCard,
  isPrescriptionMedecin,
}) {
  return (
    <div>
      {items.map((item, i) => {
        return (
          <li key={i} className={`${items.length > 1 ? " mb-cartItemsMargin last:mb-0" : null}`}>
            <div className="relative">
              <div
                className={`w-full flex justify-between items-center py-cartItemInsidePaddingY
                pl-cartItemInsidePaddingX pr-CartItemInsidePaddingRWithEditB rounded-cartItem
                bg-secondary ${
                  noBorderOnOrderSum ? null : "border-cartItem border-primary border-opacity-10"
                }`}
              >
                <div>
                  <Label isCart hidden={!titre}>
                    {titre} {i + 1}
                  </Label>
                  <p>vous avez chargé un(e) {titre} </p>
                  <PrimaryText>{items === paperPrescriptions ? item.name : item}</PrimaryText>
                </div>
                <div
                  className="outline-none rounded-roundedBtn flex align-middle"
                  style={canEdit ? null : { display: "none" }}
                >
                  <FullyRoundedImgBtn
                    type="button"
                    action={() => editItem(item)}
                    name="edit btn"
                    mediumPadding
                  >
                    <EditFill />
                  </FullyRoundedImgBtn>
                </div>
              </div>

              <DeleteCartItemBtn action={e => removeItem(i, e)}>
                <Delete />
              </DeleteCartItemBtn>
            </div>
          </li>
        )
      })}
    </div>
  )
}
