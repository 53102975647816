import React from "react"

import Label from "../DefineTagClass/Label"
import PrimaryText from "../DefineTagClass/PrimaryText"
import SecondaryText from "../DefineTagClass/SecondaryText"

/**
 *
 * @param {{
 * img: string,
 * alt: string,
 * label: string,
 * isGreen: boolean,
 * isCart: boolean,
 * line1: string,
 * line2: string,
 * line3: string,
 * line4: string,
 * line5: string,
 * }} param0
 */

// This card is used in order summary for the address and the pharmacy informations.
// It has no real name so here is card2

export default function PharmacyCard3({
  img,
  label,
  isGreen,
  isCart,
  line1,
  line2,
  line3,
  line4,
  line5,
}) {
  return (
    <div className="mx-auto bg-secondary max-w-max rounded-cartItem mb-submitToInstruction">
      <div className="py-cartItemsMargin pl-cartItemsMargin pr-cardPaddingRightWhenIconLeft">
        <div className="flex items-start">
          {img}
          <div className="ml-cardTitleToText">
            <Label isGreen={isGreen} isCart={isCart}>
              {label}
            </Label>
            <PrimaryText>{line1}</PrimaryText>
            <PrimaryText>{line2}</PrimaryText>
          </div>
        </div>
      </div>
      <div className="py-cartItemInsidePaddingY px-cartItemsMargin bg-quatro rounded-b-cartItem">
        <SecondaryText isCentered>{line3}</SecondaryText>
        <SecondaryText isCentered>{line4}</SecondaryText>
      </div>
    </div>
  )
}
