import React, { useRef } from "react"
import Label from "../DefineTagClass/Label"

/**
 * Please pay attention that this file isn't finished. The button you have to click on to upload a file doesnt math his parents design.
 * You need to work on this
 *
 * @param {{
 * img: string,
 * handleFileInput: function,
 * }} param0
 *
 * @author Lauwers Yann
 */

const FileUploader = ({ img, handleFileInput }) => {
  const fileInput = useRef(null)

  return (
    <div className="flex justify-center">
      <div className="w-max p-iconPadding bg-ternary rounded-roundedBtn relative">
        <div className="flex">
          <div className="flex bg-transparent cursor-btn outline-none h-icon w-full">
            {img}
            <Label isCart invert isCentered>
              Choisir un fichier
            </Label>
          </div>
          <input
            id="files"
            type="file"
            name="prescriptionFiles[]"
            onChange={handleFileInput}
            className="absolute top-0 left-0 w-full h-12 z-10 rounded-full opacity-0"
          />
          <button onClick={e => fileInput.current && fileInput.click()}></button>
        </div>
      </div>
    </div>
  )
}

export default FileUploader
