import React from "react"

/**
 *
 * @param {{
 *  text: string,
 *  invert: boolean,
 *  margin: boolean,
 *  isResumeContent: boolean,
 *  isCentered: boolean,
 * }} param0
 *
 * @author Lauwers Yann
 */

export default function SecondaryText({
  children,
  invert = false,
  margin = false,
  isResumeContent = false,
  isCentered = false,
}) {
  let classes =
    "text-left font-textFontFamily font-textSecondary-FontWeight text-textSecondary-FontSize tablet:text-tablet-textSecondary-FontSize laptop:text-laptop-textSecondary-FontSize leading-textSecondary tablet:leading-tablet-textSecondary laptop:leading-laptop-textSecondary"
  classes += invert ? " text-secondary" : " text-primary"
  classes += isCentered ? " text-center" : ""
  let groupClass = ""
  groupClass += isResumeContent ? " mt-primTextToSecond" : ""
  groupClass += margin ? " mt-inputToInfo" : ""

  return (
    <div className={groupClass}>
      <div className={classes}>{children}</div>
    </div>
  )
}
