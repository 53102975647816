import { AddressDTO } from "../Models/AddressDTO";
import { MandataireDTO } from "../Models/MandataireDTO";
import { PharmacyDTO } from "../Models/PharmacyDTO";
import { GET, POST } from "./request";

const convertMandataire = (mandataireFromServer) => {
  const mandataire = new MandataireDTO();
  mandataire.name = mandataireFromServer.name;
  mandataire.email = mandataireFromServer.email;
  mandataire.lastname = mandataireFromServer.lastname;
  mandataire.idNumber = mandataireFromServer.idNumber;
  mandataire.phone = mandataireFromServer.phone;
  mandataire.address = mandataireFromServer.address;

  if (mandataireFromServer.address) {
    const address = new AddressDTO();
    address.streetNumber = mandataireFromServer.address.streetNumber;
    address.street = mandataireFromServer.address.street;
    address.city = mandataireFromServer.address.city;
    address.postalCode = mandataireFromServer.address.postalCode;
    address.country = mandataireFromServer.address.country;
    address.latitude = mandataireFromServer.address.latitude;
    address.longitude = mandataireFromServer.address.longitude;

    mandataire.address = address;
  }
  return mandataire;
};

/**
 *
 * @param {String} address mandataire address
 * @returns {Promise<MandataireDTO[]>}
 */
export const getReachableMandataire = async (address) => {
  const response = await POST("mandataire/nearby", { address });
  const json = await response.json();
  return json.map((mandataire) => convertMandataire(mandataire));
};

/**
 *
 * @param {Object} [filter] Search filter
 * @param {String} [filter.name] Name includes in pharmacy name
 * @param {Number} [filter.start] get element from index
 * @param {Number} [filter.stop] get element to index
 * @returns {Promise<MandataireDTO[]>}
 */


// export const getMandataires = async (filter = {}) => {
//   const response = await POST("mandataire/", filter)
//   try {
//     console.log("herrrrrrrrrr.........1110 ", response.json())
//     const json = await response.json()
//     console.log("herrrrrrrrrr.........e ", json)
//     return json.map(mandataire => convertMandataire(mandataire))
//   } catch (err) {
//     console.error(err)
//   }
// }

export const getMandataires = async mandataire => {
  const response = await GET(`mandataire?filter=${encodeURI(mandataire)}`)
  try {
    const json = await response.json()
    return json
  } catch (error) {
    console.error(error)
  }
}
export const getMandatairesM = async mandataire => {
  const response = await GET(`mandataire`)
  try {
    const json = await response.json()
    console.log("json", json)
    return json
  } catch (error) {
    console.error(error)
  }
}

/**
 *
 * @param {String} personnalName Delivery address
 * @returns {Promise<MandataireDTO[]>}
 */

export const getMandataire = async (pn) => {
  const response = await GET(`mandataire/${pn}`);
  try {
    const json = await response.json();
    return json;
  } catch (error) {
    console.error(error);
  }
};

export const MandataireAPI = {
    getMandataire,// to find one
    getMandataires,// to find sorted ones
    getMandatairesM,// to find all
    getReachableMandataire,
  }
